import React, { useState, useEffect } from 'react';
import { getFirestore } from 'firebase/firestore';
import { useLoginContext } from '../../context/LoginContext'



export default function Login({ customer }) {
    const db = getFirestore()
    const { contextLogin, loginUsers, rerenderLogin } = useLoginContext()



    const handleSubmit = (event) => {
        event.preventDefault();
    }


    const [username, setUsername] = useState()
    const [password, setPassword] = useState()
    const [errorLog, setErrorLog] = useState(false)

    useEffect(() => {
        contextLogin.push(customer)

    }, [customer])

    useEffect(() => {
        setUsername(customer)
    }, [contextLogin])




    useEffect(() => {
        rerenderLogin()
    }, [])



    const loginF = () => {

        let usernameCheck = loginUsers != undefined ? username === loginUsers.username : <></>
        let passwordCheck = loginUsers != undefined ? password === loginUsers.password : <></>


        if (usernameCheck === true && passwordCheck === true) {

            setTimeout(() => {

                localStorage.setItem(`${username}loginToken`, JSON.stringify(true));
                rerenderLogin()
            }, 200);
        } else {
            setErrorLog(true)
            setTimeout(() => {
                setErrorLog(false)
            }, 1900);
        }


    }



    return (

        <>

            <div className='logincont'>

                <form  className='formPersonalDataCont' action="" onSubmit={handleSubmit}>


                    <div className='center'>
                        <p className='detailtext'>Contraseña</p>
                        <input  required type="password" className='personalDataInput' onChange={(e) => setPassword(e.target.value)} />
                    </div>


                    <button type='submit' className='loginbutton' onClick={loginF}>
                        LOGIN
                    </button>



                    <div className={errorLog === true ? 'errorLogin' : 'displayNone'}>
                        El dato ingresado es incorrecto
                    </div>
                </form>
            </div>

        </>

    )
}

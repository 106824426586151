import React, { useState, useEffect } from 'react'
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { LineWobble } from '@uiball/loaders'
import NavbarHomepage from './NavbarHomepage';
import { Link } from 'react-router-dom';

export default function AboutUs() {

  const [loader, setLoader] = useState(true)
  const [images, setImages] = useState([])
  const db = getFirestore()

  useEffect(() => {
    setLoader(true)

    setTimeout(() => {
      setLoader(false)
    }, 1500);
  }, [images])

  useEffect(() => {

    const QueryCollection = collection(db, `holdear/homepage/app`)

    getDocs(QueryCollection)
      .then(resp => setImages(resp.docs.map(site => ({ id: site.id, ...site.data() }))))
      .catch((err) => console.log(err))


  }, [])



  return (
    <>

      {loader === true ?
        <section>
          <div className='headerHome headergradient'>
            <NavbarHomepage />
            <LineWobble size={80} lineWeight={5} speed={1.75} color="white" />
          </div>
          <Link to="/">
            <p className='logoMicrosite'>HOLDE <span className='logobackMicrosite'>.ar</span></p>

          </Link>
        </section>


        :

        <>
          <Link to="/">
            <p className='logoMicrosite'>HOLDE <span className='logobackMicrosite'>.ar</span></p>

          </Link>
          <div className='headerHome headergradient'>
            <NavbarHomepage />
            <p className='sectionTittle'>HOLDE.ar</p>
            <p className='minitextnavbarHome '>Por Tomás Torres.</p>
          </div>


        </>

      }



    </>
  )
}

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LineWobble } from '@uiball/loaders'
import NewUsersItem from './NewUsersItem';

export default function NewUsers({usersRegistered}) {

  const [loader, setLoader] = useState(true)


  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 1000);
  }, [])



  return (
    <div className='DashboardSectionCont'>
      <div className='row'>
        <p className='DashboardTitles'>New Users</p>
        <Link className='linkArrivals' to={`/dashboard/bookings`}>
          <p className='minitextLink' > Detalles </p>
        </Link>
      </div>


      {loader === true ? <div className='dashboardloader'>
        <LineWobble
          size={80}
          lineWeight={5}
          speed={1.75}
          color="#576F72" />
      </div> : 
      <div className='ArrivalsItemCont'>

        {usersRegistered.length > 0 ? <>
        
            {usersRegistered.map((user) =>
              <NewUsersItem key={user.id} {...user} />
            )}
        </> : <></> }





    </div>}
    </div>
  )
}

import React, { useState, useEffect } from 'react';
import { useCalendarContext } from '../context/CalendarContext'
import { useRoomsContext } from '../context/RoomsContext'


export default function Book({year, month, day, room1Months, room2Months, room3Months, from, to, room1MonthsNY, room2MonthsNY, room3MonthsNY, allDaysBooked, daysLoop, searchIn,  daysInCurrentMonth, room4Months,  room4MonthsNY, room5Months, room5MonthsNY, room6Months, room6MonthsNY, room7Months, room7MonthsNY, room8Months,  room8MonthsNY, room9Months,  room9MonthsNY, room10Months, room10MonthsNY, room11Months, room11MonthsNY, room12Months, room12MonthsNY, room13Months,  room13MonthsNY, room14Months, room14MonthsNY, room15Months, room15MonthsNY}) {
  const { rooms } = useRoomsContext()
  const { addToDates, dateList} = useCalendarContext()


  // THESE STATES SAVES THE DAYS OF THE CURRENTLY MONTH OF EACH ROOM
  const [month1, setMonth1] = useState()
  const [month2, setMonth2] = useState()
  const [month3, setMonth3] = useState()
  const [month4, setMonth4] = useState()
  const [month5, setMonth5] = useState()
  const [month6, setMonth6] = useState()
  const [month7, setMonth7] = useState()
  const [month8, setMonth8] = useState()
  const [month9, setMonth9] = useState()
  const [month10, setMonth10] = useState()
  const [month11, setMonth11] = useState()
  const [month12, setMonth12] = useState()
  const [month13, setMonth13] = useState()
  const [month14, setMonth14] = useState()
  const [month15, setMonth15] = useState()



  const [daysAvail, setDaysAvail] = useState()
  const [dayChosed, setDayChosed] = useState(false)
  const [forbiddenDay, setForbiddenDay] = useState(false)
  const [dayMouseOver, setDayMouseOver] = useState(false)
  // const [availability, setAvailability] = useState([])

  const newdate = dateList.length + 1

  const condition1 = year !== from.year
  const condition2 = dateList.length > 1


  useEffect(() => {

    function setmf(a,b){
      if(a.length != 0 ) {
        b(a.find(e => e.id == month))
      }
    }
    
    function availabilityFunction(a, b, c) {
      if(a != undefined) {
        b.push(a[c])
      }
    }

    const a = "d" + day

    setTimeout(() => {
      if (condition1 === true && condition2 === true) {

      
        const room1 = room1MonthsNY.find(e => e.id == month)
        const room2 =  room2MonthsNY.length != 0 ? room2MonthsNY.find(e => e.id == month) : <></>
        const room3 = room3MonthsNY.length != 0 ? room3MonthsNY.find(e => e.id == month) : <></>
        const room4 = room4MonthsNY.length != 0 ? room4MonthsNY.find(e => e.id == month) : <></>
        const room5 = room5MonthsNY.length != 0 ? room5MonthsNY.find(e => e.id == month) : <></>
        const room6 = room6MonthsNY.length != 0 ? room6MonthsNY.find(e => e.id == month) : <></>
        const room7 = room7MonthsNY.length != 0 ? room7MonthsNY.find(e => e.id == month) : <></>
        const room8 = room8MonthsNY.length != 0 ? room8MonthsNY.find(e => e.id == month) : <></>
        const room9 = room9MonthsNY.length != 0 ? room9MonthsNY.find(e => e.id == month) : <></>
        const room10 = room10MonthsNY.length != 0 ? room10MonthsNY.find(e => e.id == month) : <></>
        const room11 = room11MonthsNY.length != 0 ? room11MonthsNY.find(e => e.id == month) : <></>
        const room12 = room12MonthsNY.length != 0 ? room12MonthsNY.find(e => e.id == month) : <></>
        const room13 = room13MonthsNY.length != 0 ? room13MonthsNY.find(e => e.id == month) : <></>
        const room14 = room14MonthsNY.length != 0 ? room14MonthsNY.find(e => e.id == month) : <></>
        const room15 = room15MonthsNY.length != 0 ? room15MonthsNY.find(e => e.id == month) : <></>




        const availability = [room1[a], room2[a], room3[a], room4[a], room5[a], room6[a],room7[a],room8[a],room9[a],room10[a],room11[a],room12[a],room13[a],room14[a],room15[a]]
        setDaysAvail(availability.filter(e => e != undefined).every(e => e === false || e === 0))

        setMonth1(room1)
        setMonth2(room2)
        setMonth3(room3)
        setMonth4(room4)
        setMonth5(room5)
        setMonth6(room6)
        setMonth7(room7)
        setMonth8(room8)
        setMonth9(room9)
        setMonth10(room10)
        setMonth11(room11)
        setMonth12(room12)
        setMonth13(room13)
        setMonth14(room14)
        setMonth15(room15)
      } else {

        const room1 = room1Months.find(e => e.id == month)
        const room2 =  room2Months.length != 0 ? room2Months.find(e => e.id == month) : <></>
        const room3 = room3Months.length != 0 ? room3Months.find(e => e.id == month) : <></>
        const room4 = room4Months.length != 0 ? room4Months.find(e => e.id == month) : <></>
        const room5 = room5Months.length != 0 ? room5Months.find(e => e.id == month) : <></>
        const room6 = room6Months.length != 0 ? room6Months.find(e => e.id == month) : <></>
        const room7 = room7Months.length != 0 ? room7Months.find(e => e.id == month) : <></>
        const room8 = room8Months.length != 0 ? room8Months.find(e => e.id == month) : <></>
        const room9 = room9Months.length != 0 ? room9Months.find(e => e.id == month) : <></>
        const room10 = room10Months.length != 0 ? room10Months.find(e => e.id == month) : <></>
        const room11 = room11Months.length != 0 ? room11Months.find(e => e.id == month) : <></>
        const room12 = room12Months.length != 0 ? room12Months.find(e => e.id == month) : <></>
        const room13 = room13Months.length != 0 ? room13Months.find(e => e.id == month) : <></>
        const room14 = room14Months.length != 0 ? room14Months.find(e => e.id == month) : <></>
        const room15 = room15Months.length != 0 ? room15Months.find(e => e.id == month) : <></>
   

        
        const availability = [room1[a], room2[a], room3[a], room4[a], room5[a], room6[a],room7[a],room8[a],room9[a],room10[a],room11[a],room12[a],room13[a],room14[a],room15[a]]
  
     
        setDaysAvail(availability.filter(e => e != undefined).every(e => e === false || e === 0))
    
        setMonth1(room1)
        setMonth2(room2)
        setMonth3(room3)
        setMonth4(room4)
        setMonth5(room5)
        setMonth6(room6)
        setMonth7(room7)
        setMonth8(room8)
        setMonth9(room9)
        setMonth10(room10)
        setMonth11(room11)
        setMonth12(room12)
        setMonth13(room13)
        setMonth14(room14)
        setMonth15(room15)
      }


    }, 50);




  }, [room15Months, month, room15MonthsNY])




  const checkDate = async () => {

    var newDay = new Date();
    var today = newDay.getDate();
    var currentMonth = newDay.getMonth() + 1;
    var currentYear = newDay.getFullYear();

    const a = "d" + day

    if (today > day && currentMonth === month && currentYear === year) {
      setForbiddenDay(true)

      setTimeout(() => {
        setForbiddenDay(false)
      }, 2900);
    } else {

      addToDates({ newdate: newdate, day: day, month: month, year: year, room1: month1[a], room2: month2[a], room3: month3[a], room4: month4[a], room5: month5[a], room6: month6[a], room7: month7[a], room8: month8[a], room9: month9[a], room10: month10[a], room11: month11[a], room12: month12[a], room13: month13[a], room14: month14[a], room15: month15[a],    daysInMonth: daysInCurrentMonth });
    }


  }

  useEffect(() => {

    const days = "d" + day

    if (allDaysBooked.length < 2) {
      return
    } if (allDaysBooked.length >= 2) {
      const find = allDaysBooked.find(e => e.day === days && e.month === month && e.year === year)

      if (find !== undefined) {
        return setDayChosed(true)
      } if (find === undefined) {
        return setDayChosed(false)
      }
    }
  }, [allDaysBooked, daysLoop])


  useEffect(() => { // THIS EFFECT REMOVES THE BACKGROUND COLOR OF SELECTED DAYS
    if (searchIn === false && dayChosed === true) {
      return setDayChosed(false)
    }
  }, [searchIn, daysLoop])


  const mouseOver = () => {

    setDayMouseOver(true)

  }


  const mouseLeave = () => {
    setDayMouseOver(false)
  }



  return (

    /// USER CALENDAR

<>
{ daysAvail != undefined ?     <>
    
    <button className={dayChosed === true ? 'daybox dayboxgreen' : 'daybox'} onClick={() => {
      checkDate()
    }} onMouseEnter={mouseOver} onMouseLeave={mouseLeave} > <span className={daysAvail === false ? '' : ' red'} >  {day} </span> </button>

    <p className={forbiddenDay === true ? 'forbiddenDay' : 'displayNone'}> ¡Ese día ya pasó! </p>

  
</>
  
 : <></> }
</>

  )

}


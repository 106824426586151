import React, { useState, useEffect } from 'react'
export default function Gallery({ images, micrositeData, texts }) {

    const [picture, setPicture] = useState(images.gallery1)
    const [changePic, setChangePic] = useState(1)


    const skipPic = () => {
        if(picture === images.gallery1 && images.gallery2 != null) {
            setPicture(images.gallery2)
            setChangePic(changePic + 1)
        } else if(picture === images.gallery2 && images.gallery3 != null) {
            setPicture(images.gallery3)
            setChangePic(changePic + 1)
        } else if(picture === images.gallery3 && images.gallery4 != null) {
            setPicture(images.gallery4 )
            setChangePic(changePic + 1)
        } else if(picture === images.gallery4 && images.gallery5 != null ) {
            setPicture(images.gallery5)
            setChangePic(changePic + 1)
        } else if(picture === images.gallery5 && images.gallery6 != null) {
            setPicture(images.gallery6)
            setChangePic(changePic + 1)
        } else if(picture === images.gallery6 && images.gallery7 != null) {
            setPicture(images.gallery7)
            setChangePic(changePic + 1)
        } else if(picture === images.gallery7 && images.gallery8 != null) {
            setPicture(images.gallery8)
            setChangePic(changePic + 1)
        } else if(picture === images.gallery8 && images.gallery9 != null) {
            setPicture(images.gallery9)
            setChangePic(changePic + 1)
        } else if(picture === images.gallery9 && images.gallery10 != null) {
            setPicture(images.gallery10)
            setChangePic(changePic + 1)
        } else if(picture === images.gallery10) {
            setPicture(images.gallery1)
            setChangePic(changePic + 1)
        } else {
            setPicture(images.gallery1)
            setChangePic(changePic + 1)
        }
    }

    const backPic = () => {
        if(picture === images.gallery1 && images.gallery10 != null) {
            setPicture(images.gallery10)
            setChangePic(changePic + 1)
        } else if(picture === images.gallery2 && images.gallery1 != null) {
            setPicture(images.gallery1)
            setChangePic(changePic + 1)
        } else if(picture === images.gallery3 && images.gallery2 != null) {
            setPicture(images.gallery2 )
            setChangePic(changePic + 1)
        } else if(picture === images.gallery4 && images.gallery3 != null ) {
            setPicture(images.gallery3)
            setChangePic(changePic + 1)
        } else if(picture === images.gallery5 && images.gallery4 != null) {
            setPicture(images.gallery4)
            setChangePic(changePic + 1)
        } else if(picture === images.gallery6 && images.gallery5 != null) {
            setPicture(images.gallery5)
            setChangePic(changePic + 1)
        } else if(picture === images.gallery7 && images.gallery6 != null) {
            setPicture(images.gallery6)
            setChangePic(changePic + 1)
        } else if(picture === images.gallery8 && images.gallery7 != null) {
            setPicture(images.gallery7)
            setChangePic(changePic + 1)
        } else if(picture === images.gallery9 && images.gallery8 != null) {
            setPicture(images.gallery8)
            setChangePic(changePic + 1)
        } else if(picture === images.gallery9) {
            setPicture(images.gallery9)
            setChangePic(changePic + 1)
        } else {
            setPicture(images.gallery1)
            setChangePic(changePic + 1)
        }
    }

   
    return (
        <div className='galleryImgCont' >
              <a className='alignCenter galleryLocation' href={texts.maps}>
                <img className='miniImage' src="https://img.icons8.com/ios-filled/100/FFFFFF/marker.png" alt="" />
                <p className='micrositeminitext'> {texts.city}, {texts.country}</p>
              </a>

          {picture === images.gallery1 ? <></> :
            <button className='galleryButton backButton' onClick={backPic}>〈<span className='galleryButtonText'>prev</span></button>
          }

          
            <button className='galleryButton forButton' onClick={skipPic}><span className='galleryButtonText'>sig</span>〉</button>

            <img  className='galleryImg' src={picture} alt="" />
        </div>
    )
}





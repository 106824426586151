import React, { useState, useEffect } from 'react'
import './RoomCards.css';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import RoomsGallery from './RoomsGallery';

export default function RoomCards({ microsite, id, img, price, type, roomname, description, forbook1, forbook2, forbook3, forbook4, forbook5, forbook6, forbook7, forbook8, forbook9, forbook10, forbook11, forbook12, forbook13, forbook14, forbook15, size, beds, guests, weekCalendar, admin, customerId }) {

  const { customer } = useParams()
  const [open, setOpen] = useState(false)
  const [openDelay, setOpenDelay] = useState(false)
  const [forBook, setForBook] = useState(false)
  const [galleryOpen, setGalleryOpen] = useState(false)

  useEffect(() => {
    if (id === "room1") {
      setForBook(forbook1)
    } if (id === "room2") {
      setForBook(forbook2)
    } if (id === "room3") {
      setForBook(forbook3)
    } if (id === "room4") {
      setForBook(forbook4)
    } if (id === "room5") {
      setForBook(forbook5)
    } if (id === "room6") {
      setForBook(forbook6)
    } if (id === "room7") {
      setForBook(forbook7)
    } if (id === "room8") {
      setForBook(forbook8)
    } if (id === "room9") {
      setForBook(forbook9)
    } if (id === "room10") {
      setForBook(forbook10)
    } if (id === "room11") {
      setForBook(forbook11)
    } if (id === "room12") {
      setForBook(forbook12)
    } if (id === "room13") {
      setForBook(forbook13)
    } if (id === "room14") {
      setForBook(forbook14)
    } if (id === "room15") {
      setForBook(forbook15)
    }
  }, [forbook1, forbook2, forbook3, forbook4, forbook5, forbook6, forbook7, forbook8, forbook9, forbook10, forbook11, forbook12, forbook13, forbook14, forbook15])


  const openCard = () => {

    if (open === false) {
      setOpen(true)
      setTimeout(() => {
        setOpenDelay(true)
      }, 500);

    } if (open === true) {
      setOpen(false)

      setOpenDelay(false)

    }
  }


  const openGallery = () => {
    if (galleryOpen === false) {
      setGalleryOpen(true)

    } else {
      setGalleryOpen(false)
    }
  }


  return (
    <>

      <div className={forBook === true ? "RoomCardsContCont" : "displayNone"}>
        <button onClick={openGallery} className='openGalleryButton'>Galería</button>
        <div className={open === false ? "RoomCardsCont" : "RoomCardsCont ExtraPaddingBottom"} onClick={openCard}>

          <img className={open === false ? "RoomCardImg ImgClosed" : "RoomCardImg"} src={img} alt="" />


          <h2 className={open === false ? "RoomCardsTittle " : "RoomCardsTittle DownMove"} >
            {microsite === true ? <></> :
              <span className={open === false ? "RoomCardSubTittle green" : "displayNone"}> Desde ${price}  </span>} <br />{roomname} </h2>

          <div className={openDelay === true ? "RoomCardsTexts" : "RoomCardsTexts displayNone"}>
            <p className="RoomCardsDescriptionText"> {description} </p>
            <p className="RoomCardsDescription" >Desde <span className='RoomCardSubTittle'>${price} </span> Por noche </p>
            <p className="RoomCardsDescription" >  {type} | {size}m<sup>2</sup> | camas: {beds}  </p>
            {microsite === true ? <></> :
              <Link className='linkRoom' to={`/${customer}/checkout/${id}`}>
                <p className="RoomCardAlert"> RESERVAR </p>
              </Link>
            }

          </div>
        </div>

        {galleryOpen === true ?

          <div className="ModalGallery">
            <RoomsGallery room={id} openClose={openGallery} />
          </div>
          :
          <></>
        }
      </div>

    </>

  )
}



import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import './App.css';
import BookListCont from './components/booking/BookListCont';
import BookOrderPage from './components/booking/BookOrderPage';
import BookingContextProvider from './components/context/BookingContext';
import CalendarContextProvider from './components/context/CalendarContext';
import MessagesContextProvider from './components/context/MessagesContext';
import RoomsContextProvider from './components/context/RoomsContext';
import Contact from './components/mailing/Contact';
import Homepage from './components/homepage/Homepage';
import CalendarCont from './components/panel/calendar/CalendarCont';
import WeekCalendar from './components/panel/calendar/WeekCalendar';
import Checkin from './components/panel/checks/Checkin';
import Dashboard from './components/panel/Dashboard';
import MessagesListCont from './components/panel/messages/MessagesListCont';
import MetricsListCont from './components/panel/metrics/MetricsListCont';
import BookOrderDashboardListCont from './components/panel/notifications/BookOrderDashboardListCont';
import RoomCardsDetailCont from './components/rooms/RoomCardsDetailCont';
import MicrositesConts from './components/microsites/MicrositesConts';
import HomeApp from './components/homepage/HomeApp';
import Clients from './components/homepage/Clients';
import ContactUs from './components/homepage/ContactUs';
import Plans from './components/homepage/Plans';
import AboutUs from './components/homepage/AboutUs';
import RoomsEditCont from './components/panel/roomsEdit/RoomsEditCont';
import RoomsEditDetailCont from './components/panel/roomsEdit/RoomsEditDetailCont';
import RoomsEditAddRoom from './components/panel/roomsEdit/RoomsEditAddRoom';
import PaymentsCont from './components/panel/payments/PaymentsCont';
import SavePaymentData from './components/panel/payments/SavePaymentData';
import NewAccountCont from './components/newaccount/NewAccountCont';
import ProfileCont from './components/panel/profile/ProfileCont';
import MicrositeEditCont from './components/panel/microsite/MicrositeEditCont';
import Demo from './components/homepage/Demo';
import CommentsCont from './components/panel/comments/CommentsCont';
import LoginContextProvider from './components/context/LoginContext';
import AmenitiesEdit from './components/panel/roomsEdit/AmenitiesEdit';
import NewCode from './components/newaccount/NewCode';
import PricingCont from './components/panel/pricing/PricingCont';
import LoginUserCheck from './components/panel/login/LoginUserCheck';
import PlansCont from './components/panel/plans/PlansCont';
import MasterDashboard from './components/master/dashboard/MasterDashboard';
import AgendaCont from './components/panel/agenda/AgendaCont';

function App() {
  return (

    <BrowserRouter>
      <BookingContextProvider>
        <RoomsContextProvider>
          <CalendarContextProvider>
            <MessagesContextProvider>
              <LoginContextProvider>
                <div className="App">
                  <Routes>
                    <Route path='/:customer' element={<MicrositesConts />} />
                    <Route path='/:customer/home' element={<BookListCont />} />
                    <Route path='/:customer/dashboard' element={<Dashboard />} />
                    <Route path='/:customer/dashboard/calendar' element={<CalendarCont />} />
                    <Route path='/:customer/dashboard/bookcalendar' element={<WeekCalendar />} />
                    <Route path='/:customer/checkout/order' element={<BookOrderPage />} />
                    <Route path='/:customer/contact' element={<Contact />} />
                    <Route path='/:customer/dashboard/messages' element={<MessagesListCont />} />
                    <Route path='/:customer/dashboard/rooms' element={<RoomsEditCont />} />
                    <Route path='/:customer/dashboard/payments' element={<PaymentsCont />} />
                    <Route path='/:customer/dashboard/:roomedit' element={<RoomsEditDetailCont />} />
                    <Route path='/:customer/dashboard/rooms/amenities' element={<AmenitiesEdit />} />
                    <Route path='/:customer/dashboard/addroom' element={<RoomsEditAddRoom />} />
                    <Route path='/:customer/dashboard/pricing' element={<PricingCont />} />
                    <Route path='/:customer/checkout/:detailID' element={<RoomCardsDetailCont />} />
                    <Route path='/:customer/dashboard/checkin' element={<Checkin />} />
                    <Route path='/:customer/dashboard/metrics' element={<MetricsListCont />} />
                    <Route path='/:customer/dashboard/bookings' element={<BookOrderDashboardListCont />} />
                    <Route path='/:customer/dashboard/profile' element={<ProfileCont />} />
                    <Route path='/:customer/dashboard/microsite' element={<MicrositeEditCont />} />
                    <Route path='/:customer/dashboard/microsite/comments' element={<CommentsCont />} />
                    <Route path='/:customer/dashboard/plans' element={<PlansCont />} />
                    <Route path='/:customer/dashboard/agenda' element={<AgendaCont />} />

                    <Route path='/payments' element={<SavePaymentData/>} />
                    <Route path='/newcodeS' element={<NewCode/>} />
                    <Route path='/masterDashboard' element={<MasterDashboard/>} />

                    <Route path='/*' element={<Navigate to='/' replace />}></Route>
                    <Route path='/' element={<Homepage />} />
                    <Route path='/app' element={<HomeApp />} />
                    <Route path='/clients' element={<Clients />} />
                    <Route path='/contactus' element={<ContactUs />} />
                    <Route path='/plans' element={<Plans />} />
                    <Route path='/aboutus' element={<AboutUs />} />
                    <Route path='/welcome' element={<NewAccountCont />} />
                    <Route path='/login' element={<LoginUserCheck />} />
                    <Route path='/demo' element={<Demo />} />
                  </Routes>

                </div>
              </LoginContextProvider>
            </MessagesContextProvider>
          </CalendarContextProvider>
        </RoomsContextProvider>
      </BookingContextProvider>

    </BrowserRouter>
  );
}

export default App;


import React, { useState, useEffect } from 'react'
import { addDoc, collection, getFirestore, getDocs } from 'firebase/firestore';
import { useBookingContext } from '../../context/BookingContext'
import NavbarPanel from '../NavbarPanel';
import { useParams } from 'react-router-dom';
import CommentsList from '../../microsites/CommentsList';
import { useLoginContext } from '../../context/LoginContext'
import Login from '../login/Login';
export default function CommentsCont() {
  const db = getFirestore()
  const { customer } = useParams()
  const {  contextBookings, bookings } = useBookingContext()
  const [comments, setComments] = useState([])
const [changed, setChanged] = useState(false)
const {  loginCheck} = useLoginContext()

  useEffect(() => {
    contextBookings.push(customer)
  }, [])
  

 const statusChanged = () => {
   if(changed === true) {
    setChanged(false)
   } else {
    setChanged(true)
   }
 }

  
  useEffect(() => {

    const QueryCollection = collection(db, `clients/${customer}/comments`)

    getDocs(QueryCollection)
      .then(resp => setComments(resp.docs.map(site => ({ id: site.id, ...site.data() }))))
      .catch((err) => console.log(err))


  }, [customer, changed])


  const [loginAnimation, setLoginAnimation] = useState(false)
  const [loginAnimation2, setLoginAnimation2] = useState(false)

  useEffect(() => {
    setLoginAnimation(true)

    setTimeout(() => {
      setLoginAnimation(false)


    }, 2000);
  }, [])


  return (
      <>
            {loginCheck != true ?

<div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>
<p className='logoMicrositeLoading' >HOLDE <span className='logobackMicrosite'>.ar</span></p>

  <Login customer={customer} />
</div>
:  
    <>
      <div className='dashboardCont'>
        <NavbarPanel bookings={bookings} customer={customer} />
        <div className='bookOrderDashboardlistCol margintop'>
        <p className='sectionTittles'>| COMMENTS ROOM</p>
        
        <div>
        </div>

        <div className='commentListCont'>

    
        <CommentsList dashboard={true}  comments={comments}  customer={customer} changed={statusChanged} />
        </div>
        </div>
      </div>
    </>
  }
   </>
  )
}


import React, { useState, useEffect } from 'react';
import NavbarPanelItem from '../../panel/NavbarPanelItem';
import NavbarMasterItem from './NavbarMasterItem';

export default function NavbarMaster({ customer, bookings }) {

    const [noti, setNoti] = useState(false)
    const [notiNumber, setNotiNumber] = useState(Number)
    const [screen, setScreen] = useState()
    const [menuOpen, setMenuOpen] = useState(false)
    useEffect(() => {
        setScreen(window.screen.width)
    }, [])


    const weektimeStamp = 1659571200
    const daytimeStamp = (weektimeStamp / 7)
    const hourtimeStamp = ((weektimeStamp / 7) / 24)

    useEffect(() => {
        if (bookings != undefined) {
           const notiInLastHour = bookings.map((a) => a.timestamp > (Date.now() - (hourtimeStamp))).filter(e => e === true)
            if (bookings.some((a) => a.timestamp > (Date.now() - (hourtimeStamp))) === true) {
                setNoti(true)
                setNotiNumber(notiInLastHour.length)
            }
        }
    }, [])


    const openMenu = () => {
        if (menuOpen === false) {
            setMenuOpen(true)

        } else { setMenuOpen(false) }
    }



    return (
        <>
            {screen > 800 ? <></> :
                <button className='navbarmobilebutton' onClick={openMenu} ><p className={menuOpen === false ? 'leftBar' : 'leftBarOpened'}>|</p> <p className={menuOpen === false ? 'righBar' : 'righBarOpened'}>|</p> <p className={menuOpen === false ? 'leftBar' : 'displayNone'}>|</p>
                </button>
            }

            {menuOpen === false && screen < 800 ? <></> :

                <div className='navbarAllContMaster '>
                    <nav className='navbarPanelContMaster inverted-border-radius'>

                        <p className='logo'>HOLDE <span className='logoback whiteBack'>.ar</span></p>

                        <NavbarMasterItem route={`/masterDashboard`} src="https://img.icons8.com/ios-glyphs/90/000000/buy-upgrade.png" text="USUARIOS"  />

                    </nav>

                </div>
            }


        </>
    )
}



import React, { useState, useEffect } from 'react';
import { getFirestore, } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import RoomsEditItemList from './RoomsEditItemList'
import { useRoomsContext } from '../../context/RoomsContext'
import NavbarPanel from '../NavbarPanel';
import './RoomsEditCont.css';
import { useLoginContext } from '../../context/LoginContext'
import Login from '../login/Login';
export default function RoomsEditCont() {


  const db = getFirestore()
  const { customer } = useParams()
  const { rooms, contextRooms, rerender, allImagesLinks } = useRoomsContext()
  const { loginCheck } = useLoginContext()

  useEffect(() => {
    contextRooms.push(customer)
  }, [])


  const [loginAnimation, setLoginAnimation] = useState(false)


  useEffect(() => {
    setLoginAnimation(true)

    setTimeout(() => {
      setLoginAnimation(false)


    }, 2000);
  }, [])

  const [loadingData, setLoadingData] = useState(false)

  useEffect(() => {
    if(rooms.length === 0) {
      rerender()
    } else {
        setLoadingData(true)

    }
  }, [rooms])
  




  return (
    <>
      {loginCheck != true ?

        <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>
          <p className='logoMicrositeLoading' >HOLDE <span className='logobackMicrosite'>.ar</span></p>

          <Login customer={customer} />
        </div>
        :


        <>
          <div className='dashboardCont'>
            <NavbarPanel rooms={rooms} customer={customer} />
            <p className='sectionTittles'>| EDITING ROOM </p>


            <RoomsEditItemList rooms={rooms} customer={customer}  />
          </div>

          <div>
         
          </div>
        </>}
        </>
  )
}

import React, { useState, useEffect } from 'react'
import {  collection, getFirestore, getDocs } from 'firebase/firestore';
import { useParams } from 'react-router-dom';

import './Booking.css';


export default function BookedOrderModal({ modal, closeModal, from, to }) {
  const db = getFirestore()
  const { customer, roomedit } = useParams()

  const [paymentData, setPaymentData] = useState([])
  const [micrositeData, setMicrositeData] = useState([])


  const [bookOrder, setBookOrder] = useState([])
  const [show, setShow] = useState(false)
  const [name, setName] = useState()
  const [cbu, setCbu] = useState()
  const [alias, setAlias] = useState()
  const [bankname, setBankname] = useState()
  const [email, setEmail] = useState()




  useEffect(() => {
      const queryCol = collection(db, `clients/${customer}/payments`)

      getDocs(queryCol)
        .then(resp => setPaymentData(resp.docs.map(bdata => ({ id: bdata.id, ...bdata.data() }))))
        .catch((err) => console.log(err))
  }, [ customer])

  useEffect(() => {
    const queryCol = collection(db, `clients/${customer}/microsite`)

    getDocs(queryCol)
      .then(resp => setMicrositeData(resp.docs.map(bdata => ({ id: bdata.id, ...bdata.data() }))))
      .catch((err) => console.log(err))
}, [ customer])


const [bankData, setBankData] = useState()

useEffect(() => {
if(paymentData !== []) {
  setBankData(paymentData.find(pay => pay.id == "bank"))
} else {
  setBankData(undefined)
}
}, [paymentData])

useEffect(() => {

  if(bankData !== undefined) {
    setName(bankData.name) 
    setCbu(bankData.cbu)
    setAlias(bankData.alias)
    setBankname(bankData.bankname)
} else {
  setName("") 
  setCbu("")
  setAlias("")
  setBankname("")
}
}, [bankData])



  useEffect(() => {
  
   let siteData = micrositeData.find(site => site.id == "texts")
    
      if(siteData !== undefined) {
        setEmail(siteData.email)
      } else {

      }

  }, [ micrositeData])



 
  useEffect(() => {
    setBookOrder(JSON.parse(sessionStorage.getItem('bookClient')))
    

    if(bookOrder.length !== 0) {
      setTimeout(() => {
        setShow(true)
      }, 100);
    }

  }, [modal])

//   const closeModal = () => {
//     setShow(false)
//     setBookOrder([])
// }

  function printOrSave() {  
    window.print()
    
  }


  return (
    <>
{show === false &&  bookOrder.length === 0 ? <> </> : 

<div className='bookingsModal'>


    <button className='closeModalWeek' onClick={closeModal}> Cerrar </button>

    <div className='dashboardBookOrderWeek'>

    <p className='modalText'>¡Gracias, <b className='marginleft'>{bookOrder.client.name}</b>! </p>


<p className='modalText'> Te enviaremos un email para confirmar tu reserva. </p>

 <p className='modalText'> Por favor, chequea tu información personal. En caso de algún error, por favor notificanos a este email:
{/*   
  Please check your personal data. In case of any error, please notify us at this email:     */}
  </p> <b className='modalText'>{email}</b>

    <div className='bookDataTop'>
        <div>
        <p  className='clientText'>
        {bookOrder.client.name.toUpperCase()}
        </p>
        </div>

        <div className='dateTextCont'>
        <p className='dateText in'>
            CHECK-IN: {bookOrder.from}
        </p>

        <p className='dateText out'>
        CHECK-OUT: {bookOrder.to}
        </p>
        </div>

    </div>

    <div className='dateData'>

            <p className='minitext'>
            <img className='modalItemsImg' src="https://img.icons8.com/material-rounded/96/000000/bedroom.png"/> {bookOrder.room}
            </p>

            <p className='minitext'>
            <img className='modalItemsImg' src="https://img.icons8.com/ios-glyphs/90/1A1A1A/conference-call--v1.png"/> {bookOrder.guests} HUÉSPEDES
            </p>

            <p className='minitext'>
            <img className='modalItemsImg' src="https://img.icons8.com/ios-glyphs/90/000000/money--v1.png"/> ${bookOrder.total.toLocaleString('de-DE')}
            </p>

    </div>

    <div className='dateData'>

     <p className='modalText modalMini'>{bookOrder.client.phone}</p>
      <p className='modalText modalMini'> {bookOrder.client.email}</p>          
    </div>

{bankData !== undefined ?  
<div className='dateData column'>
<p className='modalText modalMini'> <b>TRANSFERENCIAS</b> </p>
 <p className=' modalMini'> CBU: {cbu}</p>      
 <p className=' modalMini'> ALIAS: {alias}</p>        
 <p className=' modalMini'> BANCO: {bankname}</p>  
 <p className=' modalMini'>{name}</p>  
</div>
 : <></>}






    <div>
<button className='printButton' onClick={printOrSave}> <img className='modalImg' src="https://img.icons8.com/ios-filled/100/FFFFFF/print.png"/> </button>

</div>
</div>

   
    
  
  
 </div>
}



</>
  )
}

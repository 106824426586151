import React, { useState, useEffect, useRef } from 'react'
import { addDoc, collection, getFirestore, getDocs, writeBatch, where, query, documentId } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { useCalendarContext } from '../context/CalendarContext'
import { useBookingContext } from '../context/BookingContext'
import { useRoomsContext } from '../context/RoomsContext'
import { LineWobble } from '@uiball/loaders'
import { sendForm } from "emailjs-com";
import InputCheckOut from './InputCheckOut';
import BookedOrderModal from '../booking/BookedOrderModal';
import ProgressBar from '../progressbar/ProgressBar';
import NavbarPanel from '../panel/NavbarPanel';
import AmenitiesButton from '../panel/roomsEdit/buttons/AmenitiesButton';
import RoomsGallery from './RoomsGallery';

export default function RoomCardsDetail({ type, id, size, roomname, img, price, beds, description, step3, guests, roomcode }) {
  const db = getFirestore()
  const { detailID, customer } = useParams()


  const [loading, setLoading] = useState(true)
  const [totalPrice, settotalPrice] = useState(Number)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [dni, setDni] = useState("")

  const [modal, setModal] = useState(false)
  const [checkOut, setCheckOut] = useState(false)
  const [bookOrder, setBookOrder] = useState([])
  const [ordenmp, setOrdenmp] = useState([]);
  const [allDaysBooked, setAllDaysBooked] = useState([]);


  const [booking, setBooking] = useState(false)
  const [booked, setBooked] = useState(false)
  const [step3true, setStep3true] = useState(false)

  const { daysBooked, to, from, daysInCurrentMonth } = useCalendarContext()
  const { guestsTotal } = useBookingContext()
  const { rooms } = useRoomsContext()
  const [admin, setAdmin] = useState(false)

  const [customerData, setCustomerData] = useState()
  const [mercadopagoToken, setMercadopagoToken] = useState("")
  const [paymentData, setPaymentData] = useState([])
  const [conditiondata, setConditiondata] = useState()
  const [reserve, setReserve] = useState(0)

  const [useramenities, setUseramenities] = useState([])
  const [amenities, setAmenities] = useState()
  const [dataAm, setDataAm] = useState([])
  const [amenitiesCont, setAmenitiesCont] = useState([])

  const [days, setDays] = useState([])
  const [priceArray, setPriceArray] = useState([])



  useEffect(() => {
    const QueryCollection = collection(db, `holdear`)

    getDocs(QueryCollection)
      .then(resp => setDataAm(resp.docs.map(book => ({ id: book.id, ...book.data() }))))
      .catch((err) => console.log(err))

  }, [])

  useEffect(() => {
    const QueryCollection = collection(db, `clients/${customer}/microsite`)

    getDocs(QueryCollection)
      .then(resp => setUseramenities(resp.docs.map(amen => ({ id: amen.id, ...amen.data() }))))
      .catch((err) => console.log(err))
  }, [customer])

  useEffect(() => {
    if(useramenities.find(e => e.id == "amenities") !== undefined) {
      let amenities = useramenities.find(e => e.id == "amenities").newamenities
      setAmenitiesCont(amenities)
    }
  }, [useramenities])


  // GET CUSTOMER DATA

  useEffect(() => {
    const QueryCollection = collection(db, `clients`)

    getDocs(QueryCollection)
      .then(resp => setCustomerData(resp.docs.map(customer => ({ id: customer.id, ...customer.data() }))))
      .catch((err) => console.log(err))

  }, [])



  useEffect(() => {
    const queryCol = collection(db, `clients/${customer}/payments`)

    getDocs(queryCol)
      .then(resp => setPaymentData(resp.docs.map(bdata => ({ id: bdata.id, ...bdata.data() }))))
      .catch((err) => console.log(err))
  }, [customer])


  const [profileData, setProfileData] = useState([])

  useEffect(() => {
    const queryCol = collection(db, `clients/${customer}/profile`)

    getDocs(queryCol)
      .then(resp => setProfileData(resp.docs.map(bdata => ({ id: bdata.id, ...bdata.data() }))))
      .catch((err) => console.log(err))
  }, [customer])







  const handleSubmit = (event) => {
    event.preventDefault();
  }



  const fullto = { day: to.day, month: to.month, year: to.year }
  const fullfrom = { day: from.day, month: from.month, year: from.year }
  useEffect(() => {

    // This variable contains the range of days selected.

    // This variable contains the range of days selected.
    const daysBookedArr = Array(daysBooked).fill(fullfrom.day).map((x, y) => x + y)
    const daysBookedArr2 = Array(daysBooked).fill(fullfrom.day + 1).map((x, y) => x + y)
    const daysBookedArr3 = Array(daysBooked).fill(fullfrom.day - 1).map((x, y) => x + y)

    const daysOfNextMonthFilt = from.daysInMonth === 31 && to.daysInMonth === 30 ? daysBookedArr3.filter(e => e > to.daysInMonth) :
      from.daysInMonth === 30 && to.daysInMonth === 31 ? daysBookedArr.filter(e => e > to.daysInMonth)
        : from.daysInMonth === to.daysInMonth ? daysBookedArr.filter(e => e > to.daysInMonth) : daysBookedArr.filter(e => e > to.daysInMonth)


    const daysFirstMonth = daysBookedArr.filter(e => e <= from.daysInMonth)

    const daysSecondMonth = fullto.month === 2 ? daysOfNextMonthFilt.map(e => e - from.daysInMonth) : daysOfNextMonthFilt.map(e => e - to.daysInMonth)

    const daysObj = daysFirstMonth.map((e) => {
      return { id: roomcode, day: "d" + e, month: fullfrom.month, year: fullfrom.year }
    })

    const daysObjSecond = daysSecondMonth.map((e) => {
      return { id: roomcode, day: "d" + e, month: fullto.month, year: fullto.year }
    })

    const concat = daysObj.concat(daysObjSecond)

    setAllDaysBooked(concat.filter(e => e.day != "d0" && e.day != "d-1" && e.day != "d-2"))



  }, [roomcode])

  

  function paymentDataLoaded(a) {
    return new Promise((resolve, reject) => {

      if(a === undefined || a === []) {
        reject("Not ok")
      } else {
        resolve("ok")
      }
    } )
    
  }

  // paymentDataLoaded(paymentData)
  // .then(() => {
  //   if(paymentData.find(e => e.id == "mercadopago") != undefined){
  //     let token = paymentData.find(e => e.id == "mercadopago").code
  //     setMercadopagoToken(token)
  //   }
  // })


  //PRECIO SEGÚN EL INCREMENTO POR DÍAS

  const [monday, setMonday] = useState()
  const [tuesday, setTuesday] = useState()
  const [wednesday, setWednesday] = useState()
  const [thursday, setThursday] = useState()
  const [friday, setFriday] = useState()
  const [saturday, setSaturday] = useState()
  const [sunday, setSunday] = useState()

  const [january, setJanuary] = useState()
  const [february, setFebruary] = useState()
  const [march, setMarch] = useState()
  const [april, setApril] = useState()
  const [may, setMay] = useState()
  const [june, setJune] = useState()
  const [july, setJuly] = useState()
  const [august, setAugust] = useState()
  const [september, setSeptember] = useState()
  const [october, setOctober] = useState()
  const [november, setNovember] = useState()
  const [december, setDecember] = useState()

  const [dayExtra, setDayExtra] = useState()
  const [monthExtra, setMonthExtra] = useState()

  const [bookCondition, setBookCondition] = useState()


useEffect(() => {

  paymentDataLoaded(paymentData)
  .then(() => {
    if (paymentData.find(e => e.id === "extraDays") != undefined) {
      let extraDayData = paymentData.find(e => e.id === "extraDays")
      let extraMonthData = paymentData.find(e => e.id === "extraMonths")
      let extraPricesData = paymentData.find(e => e.id === "extraPrices")
     

      setTimeout(() => {
        setMonday(extraDayData.mon)
        setTuesday(extraDayData.tue)
        setWednesday(extraDayData.wed)
        setThursday(extraDayData.thu)
        setFriday(extraDayData.fri)
        setSaturday(extraDayData.sat)
        setSunday(extraDayData.sun)
  
        setJanuary(extraMonthData.january)
        setFebruary(extraMonthData.february)
        setMarch(extraMonthData.march)
        setApril(extraMonthData.april)
        setMay(extraMonthData.may)
        setJune(extraMonthData.june)
        setJuly(extraMonthData.july)
        setAugust(extraMonthData.august)
        setSeptember(extraMonthData.september)
        setOctober(extraMonthData.october)
        setNovember(extraMonthData.november)
        setDecember(extraMonthData.december)
  
        setDayExtra(extraPricesData.days)
        setMonthExtra(extraPricesData.months)

       
      }, 100);
    }
  })
  .then((
  ) => {
    if(paymentData.find(e => e.id == "mercadopago") != undefined){
      let token = paymentData.find(e => e.id == "mercadopago").code
      setMercadopagoToken(token)
    }
  })
  .then(() => {
    let bookConditionData = paymentData.find(e => e.id === "bookCondition")

    if(bookConditionData === undefined) {
      setBookCondition(true)
    } else {
      setBookCondition(bookConditionData.condition)
    }
  })
  .catch(err => err)
 
}, [paymentData])


  const [done1, setdone1] = useState(false)
  const [done2, setdone2] = useState(false)

  useEffect(() => {


    allDaysBooked.map(e => {
      const date = new Date(e.year, e.month - 1, e.day.slice(1))

      if (days.length < allDaysBooked.length) {
        days.push(
          { "day": date.toString()[0] + date.toString()[1] + date.toString()[2], "month": e.month }

        )
        setTimeout(() => {
          setdone1(true)
        }, 500);
      }
    })


  }, [allDaysBooked])



  useEffect(() => {


    const allDays = [{ day: "Mon", "extra": monday }, { day: "Tue", "extra": tuesday }, { day: "Wed", "extra": wednesday }, { day: "Thu", "extra": thursday }, { day: "Fri", "extra": friday }, { day: "Sat", "extra": saturday }, { day: "Sun", "extra": sunday }]
    const allMonths = [{ month: 1, "extra": january }, { month: 2, "extra": february }, { month: 3, "extra": march }, { month: 4, "extra": april }, { month: 5, "extra": may }, { month: 6, "extra": june }, { month: 7, "extra": july }, { month: 8, "extra": august }, { month: 9, "extra": september }, { month: 10, "extra": october }, { month: 11, "extra": november }, { month: 12, "extra": december }]



    days.forEach(e => {

      let d = e.day
      let f = e.month

      let thisDayValues = allDays.find(dayf => dayf.day === d)
      let thisMonthValues = allMonths.find(monthf => monthf.month === f)

      let priceW = []
      if (thisMonthValues.extra === true) {
        priceW.push(Number(price) + (Number(price) / monthExtra))
      } else if (thisMonthValues.extra === false) {
        priceW.push(Number(price) - (Number(price) / monthExtra))
      } else {
        priceW.push(Number(price))
      }


      setTimeout(() => {
        if (thisDayValues.extra === true) {
          return priceArray.push(Math.round(Number(priceW[0]) + (Number(price) / dayExtra))), setdone2(true) /////// modificar este % a uno brindado por el cliente
        } else if (thisDayValues.extra === false) {
          return priceArray.push(Math.round(Number(priceW[0]) - (Number(price) / dayExtra))), setdone2(true)
        } else {
          return priceArray.push(Math.round(Number(priceW[0]))), setdone2(true)
        }
      }, 50);
    })

  }, [done1])


  useEffect(() => {
    const tot = priceArray.reduce((previousValue, currentValue) => previousValue + currentValue,
      0)
    if (type == "shared") {
      settotalPrice((tot * guestsTotal.slice(-1)[0]).toFixed(2))
      setLoading(false)
    } else {
      settotalPrice(tot)
      setLoading(false)
    }
  }, [done2])


  useEffect(() => {
    const conditionData = paymentData.find(pay => pay.id == "condition")
  
      if (conditionData !== undefined) {
        if(conditionData.condition !== null) {
          setConditiondata(conditionData.condition)
          setReserve((totalPrice / conditionData.condition).toFixed(2))
        }
      }
 
  }, [paymentData, conditiondata, totalPrice])




  const checkOutPayment = async () => {
    const date = new Date();

    setBooking(true)

    setTimeout(() => {
      setBooked(true)
      setBooking(false)

    }, 2000);

    let book = {}

    book.idCode = parseInt(((Math.random() * 10000000) + (Math.random() * 10000000)) / (Math.random(1, 50)));
    book.client = { name, email, phone, dni }
    book.total = totalPrice
    book.paid = 0
    book.guests = guestsTotal.slice(-1)[0]
    book.fullbookdate = { day: date.getDate(), month: (date.getMonth() + 1), year: date.getFullYear() }
    book.hour = date.getHours() + ":" + date.getMinutes()
    book.status = bookCondition === true ? true : null
    book.room = roomname.toUpperCase()
    book.from = from.day + "/" + from.month + "/" + from.year
    book.to = to.day + "/" + to.month + "/" + to.year
    book.fullto = fullto
    book.fullfrom = fullfrom
    book.alldaysbooked = Array(daysBooked).fill(from.day).map((x, y) => x + y)
    book.roomcode = roomcode
    book.timestamp = Date.now()
    book.fromDaysInMonth = from.daysInMonth
    book.toDaysInMonth = to.daysInMonth
    book.daysInCurrentMonth = daysInCurrentMonth
    book.daysBooked = daysBooked
    book.type = type
    sessionStorage.setItem('bookClient', JSON.stringify(book));


    const queryCollection = collection(db, `clients/${customer}/bookings`)
    addDoc(queryCollection, book)
      .then(res => console.log(res))
      .catch(err => console.log(err))



    //THIS FUNCTION DEDUCTS THE RESERVED ROOMS
    const daysOffirstMonth = allDaysBooked.filter((e) => e.month.toString() == from.month)
    const daysOfsecondMonth = allDaysBooked.filter((e) => e.month.toString() == to.month)

    const batch = writeBatch(db)

    const queryColFrom = collection(db, `clients/${customer}/rooms/${roomcode}/${from.year}`)
    const queryActualizarStockFrom = await query(
      queryColFrom,
      where(documentId(), 'in', daysOffirstMonth.map((e) => e.month.toString())))


    const queryColTo = collection(db, `clients/${customer}/rooms/${roomcode}/${to.year}`)
    const queryActualizarStockTo = await query(
      queryColTo,
      where(documentId(), 'in', daysOfsecondMonth.map((e) => e.month.toString())))


    await getDocs(queryActualizarStockFrom)
      .then(resp => resp.docs.forEach(((res) => {
        daysOffirstMonth.forEach((e) => {
          if (type != "shared") {

            return batch.update(res.ref, { [e.day]: false })
          } if (type == "shared" && res.data()[e.day] >= guestsTotal.slice(-1)[0]) {

            return batch.update(res.ref, { [e.day]: res.data()[e.day] - guestsTotal.slice(-1)[0] })
          } if (type == "shared" && res.data()[e.day] <= guestsTotal.slice(-1)[0]) {
            return batch.update(res.ref, { [e.day]: res.data()[e.day] })
          }
        })
      })))

    await getDocs(queryActualizarStockTo)

      .then(resp => resp.docs.forEach(((res) => {
        daysOfsecondMonth.forEach((e) => {
          if (type != "shared") {
            return batch.update(res.ref, { [e.day]: false })
          } if (type == "shared" && res.data()[e.day] >= guestsTotal.slice(-1)[0]) {
            return batch.update(res.ref, { [e.day]: res.data()[e.day] - guestsTotal.slice(-1)[0] })
          } if (type == "shared" && res.data()[e.day] <= guestsTotal.slice(-1)[0]) {
            return batch.update(res.ref, { [e.day]: res.data()[e.day] })
          }
        })
      })))


    await batch.commit()

  }


  const openModal = () => {
    setModal(true)
    setStep3true(true)
    window.scrollTo(0, 0)
  }

  const closeModal = () => {
    setModal(false)
  }

  const [openPriceDetail, setOpenPriceDetail] = useState(false)

  const openPriceDetailF = () => {
    if (openPriceDetail === false) {
      setOpenPriceDetail(true)
    } else {
      setOpenPriceDetail(false)
    }


  }



  const showCheckOut = () => {
    setCheckOut(true)
  }

  useEffect(() => {
    if (booked === true) {
      showCheckOut()

      setTimeout(() => {
        window.scrollTo(0, 1000)
      }, 250);
    }
  }, [booked])



  //// EMAILJS

  const [formData, setFormData] = useState({
    to_name: "",
    email_to: "",
    room: "",
    price: "",
    from_date: "",
    to_date: "",

  });

const [tryAgain, setTryAgain] = useState(true)
  useEffect(() => {

    let custData = profileData.find(e => e.id == "data")

     

    if(customer !== undefined && custData !== undefined && roomname !== undefined && totalPrice !== undefined && fullfrom !== undefined && fullto !== undefined) {
      setFormData({
        to_name: customer,
        email_to: custData.email,
        room: roomname,
        price: totalPrice,
        from_date: fullfrom.day + "/" + fullfrom.month + "/" + fullfrom.year,
        to_date: fullto.day + "/" + fullto.month + "/" + fullto.year,
      }) 
    } else {
        if(tryAgain === true) {
          setTryAgain(false)
        } else {
          setTryAgain(true)
        }
    }
    
 

  }, [tryAgain, totalPrice])




  const formRef = useRef(null);

      
  const handleChange2 = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };


  const handleSubmit3 = (event) => {
    event.preventDefault();

    sendForm(
      "holdearappgmail",
      "template_xv9if9i",
      formRef.current,
      "5HliEyZdhFxxuTp9H"
    ).then(response => {
      if (response.status === 200) {
 
      } else {

      }
    });
  };


  // MERCADOPAGO

  const data = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:

        `Bearer ${mercadopagoToken}`
    },
    body: JSON.stringify({
      items: [
        {
          title: "Reserva" + " " + roomname,
          description: "¡Gracias por su reserva!",
          picture_url:
            "https://firebasestorage.googleapis.com/v0/b/hodltienda-reactcoderhouse.appspot.com/o/gif%2Ffavicon.ico?alt=media&token=1516813f-c9d4-4275-9e07-646f93a29b1a",
          category_id: "holdeBook",
          quantity: 1,
          currency_id: "ARS",
          unit_price: parseInt(reserve),
        }
      ],
      auto_return: "approved",
      back_urls: { success: `http://holde.ar/${customer}/checkout/order` }
    })

  };


  useEffect(() => {
    //getFetch();
    fetch("https://api.mercadopago.com/checkout/preferences", data)
      .then(function (resp) {
        return resp.json();
      })
      .then((resp) => setOrdenmp(resp));

  }, [checkOut]);

  useEffect(() => {
    setBookOrder(JSON.parse(sessionStorage.getItem('bookClient')))
  }, [checkOut])

  useEffect(() => {
    setAdmin(JSON.parse(sessionStorage.getItem('adminToken')))
  }, [])




  return (
    <>
      {

        loading === true ?

          <div className='loader column'>
            <p className='logoMicrositeLoading'>HOLDE <span className='logobackMicrosite'>.ar</span></p>
            <LineWobble
              size={300}
              lineWeight={8}
              speed={2.5}
              color="#576F72" />
          </div>

          :

          <>
            {admin === true ? <></> : <ProgressBar step1={true} step2={true} step3={step3true === true ? true : false} line1={true} line2={true} />}

            <div className='dashboardCont'>
              {admin === true ? <NavbarPanel /> : <></>}


              <div className="RoomCardsDetailCont">
                <h2 className="RoomCardsDetailTittle " > Detalle de la orden </h2>
                <div className='DetailImgCont'>

                  <RoomsGallery room={detailID} detail={true} />

                </div>

                <div className='DetailDescriptionCont'>

                  <div className='priceDetailCont'>
                    <h2 className="RoomCardsDetailTittle lighter" > {roomname} </h2>
                    <p className="RoomCardsDetailDescription"> {description} </p>
                    <div className='pricingCont'>

                      <div className='fromtoCont'>
                        <div className='fromtoTextContPrice'>
                          <p className='fromtoTextPrice'>Total:</p>
                          <p className='fromtoDetPrice'> ${totalPrice} </p>
                        </div>

                      </div>
                      <div className='fromtoCont'>


                        <div className='fromtoTextCont'>
                          <p className='fromtoText'>Check-in</p>
                          <p className='fromtoDet'> {from.day}/{from.month}/{from.year} </p>
                        </div>

                        <div className='fromtoTextCont'>
                          <p className='fromtoText'>Check-out</p>
                          <p className='fromtoDet'> {to.day}/{to.month}/{to.year} </p>
                        </div>
                      </div>


                    </div>
                    {/* <p className='RoomCardsDetailDescription margintop'> <b>≅ ${price}</b> por noche en habitación {type}. </p> */}

                    {priceArray.every(e => e === price) === false ?

                      <>
                        <div onClick={openPriceDetailF} className='disclosureDaysCont'>
                          <p className={openPriceDetail === false ? "RoomCardsDetailDescription" : 'closeButtonDiscl'}>{openPriceDetail === false ? "Ver detalle" : 'Cerrar'}</p>
                          <div className={openPriceDetail === false ? "displayNone" : 'disclosureDaysContPrices'}>
                            <div className='row center'>
                              {allDaysBooked.map(e =>
                                <p key={e.day} className='RoomCardsDetailDescription backgreenclear  w5rem '> {e.day.slice(1)}/<span> {e.month} </span> </p>
                              )}
                            </div>

                            <div className='row center'>


                              {priceArray.map(e =>
                                <p  className='RoomCardsDetailDescription whiteback w5rem'> ${e} </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </>

                      :
                      <></>
                    }

                    {conditiondata === 1 ? <></> :

                      <div className='fromtoTextCont'>


                        <p className='fromtoText'>Anticipo de reserva </p>

                        {conditiondata === 10 ? <p className='fromtoText greenhighlight'> (10%)</p> : <></>}
                        {conditiondata === 2 ? <p className='fromtoText greenhighlight'> (50%)</p> : <></>}
                        {conditiondata === 4 ? <p className='fromtoText greenhighlight'> (25%)</p> : <></>}
                        {reserve === 0 ? <p className='fromtoDet bigtext'>No cobramos reserva</p> : <p className='fromtoDet bigtext'>${reserve}</p>}

                      </div>
                    }

                  </div>

                  <img className='' src="https://img.icons8.com/dotty/80/000000/wavy-line.png" />

                  <div className='amenitiescont'>

                    {amenitiesCont.map(amenitie =>
                      <AmenitiesButton key={amenitie} dashboard={false} text={amenitie} data={dataAm} useramenities={useramenities} />
                    )}

                  </div>
                </div>

                <div className='ContactInfoCont'>
                  <form className='formAgregarProducto' action="" ref={formRef} onSubmit={handleSubmit3}>
                    <p className='RoomCardsDetailTittle white lighter'>Información de contacto</p>
                    <div className='noShow' >

                      <input type="email"
                        name="email_to"
                        value={formData.email_to}
                        onChange={handleChange2} />

                      <input type="text"
                        name="to_name"
                        value={formData.to_name}
                        onChange={handleChange2} />

                      <input type="text"
                        name="room"
                        value={formData.room}
                        onChange={handleChange2} />

                      <input type="text"
                        name="price"
                        value={formData.price}
                        onChange={handleChange2} />

                      <input type="text"
                        name="from_date"
                        value={formData.from_date}
                        onChange={handleChange2} />

                      <input type="text"
                        name="to_date"
                        value={formData.to_date}
                        onChange={handleChange2} />

                    </div>
                    <p className='RoomCardsDetailDescription positionR'>* Requerido</p>
                    <InputCheckOut place="Nombre y apellido *" onchange={(e) => setName(e.target.value)} />
                    <InputCheckOut place="Email *" onchange={(e) => setEmail(e.target.value)} />
                    <InputCheckOut place="Teléfono *" onchange={(e) => setPhone(e.target.value)} />
                    <InputCheckOut place="DNI/ID *" onchange={(e) => setDni(e.target.value)} />
                    <p className='RoomCardsDetailDescription'>Con tu DNI/ID podrás hacer un seguimiento de tu reserva. </p>
                    

                    <button type='submit' className={booked === true ? 'submitButtonBooked margintop' : 'submitButton margintop'} onClick={booked === true ? null : checkOutPayment} > {booking === true ? <div className='checkedoutBook'>
                    <LineWobble
                      size={80}
                      lineWeight={5}
                      speed={1.75}
                      color="white" />
                  </div> : booked === true ? <img className='checkSubmitTick' src="https://img.icons8.com/external-regular-kawalan-studio/96/000000/external-tick-user-interface-regular-kawalan-studio.png" alt="" /> : "RESERVAR"}  </button> 
                  </form>

                </div>

                {admin === true || reserve === 0 ?

                  <>
                    {checkOut === false ? <></> : <div className='CheckOutCont'>

                      <p className='RoomCardsDetailTittle lighter'>Checkout</p>

                      <div className='checkoutPayOpCont'>

                        <p className='RoomCardsDetailDescription padding '> Clickeá debajo para ver los detalles de la orden.
                          {/* Click below to see the book details.  */}
                        </p>
                        <button type='submit' className='ButtonNone' onClick={() => {
                          openModal()
                        }}>
                          <img className='checkOutImg' src="https://img.icons8.com/ios-filled/100/000000/reservation.png" alt="" />
                        </button>
                      </div>
                    </div>
                    }
                  </>

                  :

                  <>
                    {checkOut === false ? <></> : <div className='CheckOutCont'>

                      <p className='RoomCardsDetailTittle lighter'>Checkout</p>
                      <div className='checkoutPaymentCont'>

                        <div className='checkoutPayOpCont'>
                          <img className='checkOutImgFlag' src="https://img.icons8.com/officexs/16/000000/argentina.png" />
                          <p className='RoomCardsDetailDescription padding'> Si estás en Argentina, podés pagar con MercadoPago o transferencia bancaria.</p>

                          <div className='checkoutPaymentContOptions'>

                            <a href={ordenmp.init_point} className="ButtonNone" >
                              <img className='checkOutImgSmall' src="https://www.mgscreativa.com/images/stories/virtuemart/product/logo-mercadopago9.png" alt="" />
                            </a>

                            <button type='submit' className='ButtonNone' onClick={() => {
                              openModal()
                            }}>
                              <img className='checkOutImgSmall' src="https://img.icons8.com/ios/100/000000/initiate-money-transfer.png" alt="" />
                            </button>
                          </div>
                        </div>

                        <div className='checkoutPayOpCont'>
                          <img className='checkOutImgFlag' src="https://img.icons8.com/ios-glyphs/90/000000/world.png" />
                          <p className='RoomCardsDetailDescription padding'> If you are abroad Argentina,<b> please click here</b>. No reservation fee will be charged. </p>

                          <button type='submit' className='ButtonNone' onClick={() => {
                            openModal()
                          }}>
                            <img className='checkOutImg' src="https://img.icons8.com/ios-filled/100/000000/reservation.png" alt="" />
                          </button>
                        </div>
                      </div>
                    </div>}

                  </>}


              </div>

              {modal === false ? <></> : <BookedOrderModal modal={modal} closeModal={closeModal} from={from} to={to} />}
            </div>
          </>


      }


    </>
  )
}

import React, { useState, useEffect } from 'react';
import './Plans.css';
import { Link } from 'react-router-dom';

export default function PlansItem({ image, text, price, customer, suscripcion }) {
  const [ordenmp, setOrdenmp] = useState([]);
  const [checkOut, setCheckOut] = useState(false);

  // MERCADOPAGO

  const data = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        //   TEST-2085148632588929-090716-5c1cba51be7c7e921013b03f9f811741-169291933
        `Bearer APP_USR-2085148632588929-090716-57eb9ccc55965f20750436b86248dd0e-169291933  `


    },
    body: JSON.stringify({
      items: [
        {
          title: "Plan Basic HOLDE",
          description: "¡Gracias por ayudarnos a crecer!",
          picture_url:
            "https://firebasestorage.googleapis.com/v0/b/hodltienda-reactcoderhouse.appspot.com/o/gif%2Ffavicon.ico?alt=media&token=1516813f-c9d4-4275-9e07-646f93a29b1a",
          category_id: "holdeBook",
          quantity: 1,
          currency_id: "ARS",
          unit_price: parseInt(price),
        }
      ],
      auto_return: "approved",
      back_urls: { success: `http://holde.ar/${customer}/dashboard/plans` }
    })

  };


  useEffect(() => {
    //getFetch();
    fetch("https://api.mercadopago.com/checkout/preferences", data)
      .then(function (resp) {
        return resp.json();
      })
      .then((resp) => setOrdenmp(resp));

  }, [checkOut]);


  return (
    <div className='planItemCont'>

      <img className='checkOutImgSmall' src={image} alt="" />
      <p className='planItemText'> {text} </p>
      <p className='planItemPriceText green'> ${price} </p>

<div className='column center'>


      <a href={ordenmp.init_point} className="paybutton2" > PAGO ÚNICO</a>
      {suscripcion === null ? <></> :        
      <a className='paybutton2' href={suscripcion}>
      SUSCRIPCIÓN
      </a>}
      </div>

    </div>

  )
}

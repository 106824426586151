import React from 'react'
import './ProgressBar.css';
export default function ProgressBar({step1, step2, step3, line1, line2}) {




  return (
    <div className='progressBarCont'>
        <div className='stepCont'>
            <p className={step1 === true ? 'stepDone' : "step"}>1</p>

        </div>

        <div className='stepCont'>
        <p className={line1 === true ? 'verde' : ""}>➟</p>
        </div>
        
        <div className='stepCont'>
            <p className={step2 === true ? 'stepDone' : "step"}>2</p>
        </div>

        <div className='stepCont'>
        <p className={line2 === true ? 'verde' : ""}>➟</p>
        </div>
        <div className='stepCont'>
            <p className={step3 === true ? 'stepDone' : "step"}>3</p>
        </div>
   
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { getFirestore, collection, getDocs} from 'firebase/firestore';
import './Homepage.css';
import NavbarHomepage from './NavbarHomepage';
import { Link } from 'react-router-dom';
import HomeAppItem from './HomeAppItem';
import { LineWobble } from '@uiball/loaders'

export default function Homepage() {

  const [loader, setLoader] = useState(true)
  const [images, setImages] = useState([])
  const db = getFirestore()

  useEffect(() => {
    setLoader(true)

    setTimeout(() => {
      setLoader(false)
    }, 2000);
  }, [images])

  useEffect(() => {

    const QueryCollection = collection(db, `holdear/homepage/app`)

    getDocs(QueryCollection)
      .then(resp => setImages(resp.docs.map(site => ({ id: site.id, ...site.data() }))))
      .catch((err) => console.log(err))


  }, [])


  return (
    <>

{loader === true ?
        <section>
          <div className='headerHome headergradient'>
            <NavbarHomepage />
            <LineWobble size={80} lineWeight={5} speed={1.75} color="white" />
          </div>

        </section>


        :

        <>
        
      <header className='headerHome headergradient'>
        <NavbarHomepage />


        <p className='logoHome '>HOLDE <span className='logobackHome'>.ar</span></p>
        <p className='minitextnavbarHome '> Tu emprendimiento hotelero. Más fácil.</p>
        <Link className='homebutton' to="/demo">
          Conocé la App
        </Link>
         
        <div className='row center none-desktop'>
          <Link className='linkHome marginless' to="/welcome">
            <p className='loginNavButText ' > Registrate </p>
          </Link>

          
          <Link className='linkHome marginless' to="/login">
            <p className='loginNavButText greenbackCle' > Ingresar </p>
          </Link>
        </div>
  

        
                  

      </header>

      <HomeAppItem img={images[0].app1} tittle="Optimizate" text="Dejá que HOLDE.ar trabaje por vos. Gracias al micrositio y al calendario actualizado, los huéspedes podrán tener toda la información sobre la disponibilidad de alquiler al instante." orientation="row" />

<HomeAppItem img={images[0].app6} tittle="Plan mensual sin comisiones" text="Sé más competitivo con mejores precios y olvidate de las plataformas que se quedan con un alto porcentaje de cada reserva. En HOLDE.ar abonás la suscripción mensual ¡y listo! " orientation="rowReverse" />

<HomeAppItem className="greenBack" img={images[0].app3} tittle="Llevalo en tu bolsillo" text="Seguí toda la actividad desde tu celular, ingresando desde cualquier navegador. No necesitás descargar nada y la optimización de la App te asegura el funcionamiento aun en condiciones de poca señal." orientation="row" bg="green" />

<HomeAppItem img={images[0].app4} tittle="Cobrá online" text="Ofrecele una mejor experiencia a tus clientes y cobrá online. Con la integración de Mercadopago podrás cobrar por adelantado el total o parte del costo de la reserva." orientation="rowReverse" />

<HomeAppItem img={images[0].app2} tittle="Administrá y monitoreá" text="Accedé desde el panel de control a las estadísticas de tu emprendimiento. Organizate mejor con toda la información de las reservas, consultas y clientes. Tené siempre a mano los datos de tus huéspedes." orientation="row" />

<div className='appDiv greenBack column'>

  <img className='appImg smaller' src={images[0].app7} alt="Llevá tu emprendimiento al próximo nivel." />

  <p className='headerTittle'> Llevá tu emprendimiento al siguiente nivel  </p>

  <Link className='linkHome' to="/contactus">
    <p className='headerDetailText appButton'>Quiero ser HOLDER</p>
  </Link>
  </div>

        </>




}
    </>
  )
}

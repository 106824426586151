import React from 'react'
import { Link } from 'react-router-dom';

export default function ClientsItem({ img, tittle, text, orientation, place, maps, link, bg }) {
    return (
        <section className={orientation === "row" ? 'appDiv row' : 'appDiv rowReverse'}>
            <div className='imgDiv'>
                <img className='clientImg' src={img} alt={tittle} />
            </div>

            <div className='textDiv'>
                <Link className='linkClient' to={link}>
                    <p className='textTittle'> {tittle} </p>
                </Link>
                <a className='clientMaps' href={maps}>
                    <img className='miniImageClients invertFilter' src="https://img.icons8.com/ios-filled/100/FFFFFF/marker.png" alt="" />
                    <p className='clientsminitext'> {place} </p>
                </a>
                <p className='textParagraph'> {text} </p>

                <Link className='websiteLink' to={link}>
                    <img className='webimgClients' src="https://img.icons8.com/material-rounded/48/000000/domain--v2.png" />
                </Link>

            </div>
        </section>
    )
}

import React, { useState, useEffect } from 'react'
import RoomCardsDetail from './RoomCardsDetail'
import { useParams } from 'react-router-dom';
import { useRoomsContext } from '../context/RoomsContext'

export default function RoomCardsDetailCont({admin, weekCalendar, customerId}) {


    const { detailID } = useParams()
    const { rooms } = useRoomsContext()

    const [room, setRoom] = useState([])
  
    useEffect(() => {
       setRoom(rooms.find(e => e.id === detailID))
    }, [])
    


  return (
    <>

    <RoomCardsDetail type={room.type} size={room.size} roomname={room.roomname} img={room.img} price={room.price} beds={room.beds} description={room.description} roomcode={room.id} admin={admin} weekCalendar={weekCalendar} customerId={customerId} />

    </>
  )
}

import React from 'react'

export default function WeekCalendarModal({ closeModal, departuresFromToday, booksFromThisDay, arrivals, departures }) {

    const closeModalF = () => {
        closeModal()
    }


    return (

        <div className='bookingsModal'>
            <button className='closeModalWeek' onClick={closeModalF}> Cerrar </button>

            {arrivals === true ? <> {booksFromThisDay.map(book => {
                return (


                    <div className='dashboardBookOrderWeek'>
                        <div className='bookDataTop'>
                            <div>
                                <p className='clientText'>
                                    {book.client.name.toUpperCase()}
                                </p>
                            </div>

                            <div className='dateTextCont'>
                                <p className='dateText in'>
                                    CHECK-IN: {book.from}
                                </p>

                                <p className='dateText out'>
                                    CHECK-OUT: {book.to}
                                </p>
                            </div>

                        </div>

                        <div className='dateData'>

                            <p className='minitext'>
                                <img className='modalItemsImg' src="https://img.icons8.com/material-rounded/96/000000/bedroom.png" /> {book.room}
                            </p>

                            <p className='minitext'>
                                <img className='modalItemsImg' src="https://img.icons8.com/ios-glyphs/90/1A1A1A/conference-call--v1.png" /> {book.guests}
                            </p>

                            <p className='minitext'>
                                <img className='modalItemsImg' src="https://img.icons8.com/ios-glyphs/90/000000/money--v1.png" /> ${book.total.toLocaleString('de-DE')}
                            </p>

                        </div>

                        <div className='dateData'>

                            <button className='actionButton' onClick={() => window.location = `mailto: ${book.client.email}`}>
                                <img className='actionImg' src="https://img.icons8.com/ios-filled/100/000000/new-post.png" />
                            </button>



                            <button className='actionButton' onClick={() => window.location = `https://wa.me/${Number(book.client.phone)}`}>
                                <img className='actionImg' src="https://img.icons8.com/ios-glyphs/240/000000/whatsapp.png" />
                            </button>

                            <button className='actionButton' onClick={() => window.location = `tel:${Number(book.client.phone)}`}>
                                <img className='actionImg' src="https://img.icons8.com/ios-glyphs/90/000000/phone-disconnected.png" />
                            </button>

                        </div>


                    </div>

                )



            })} </> : <></>}

            {departures === true ? <> {departuresFromToday.map(book => {
                return (


                    <div className='dashboardBookOrderWeek'>
                        <div className='bookDataTop'>
                            <div>
                                <p className='clientText'>
                                    {book.client.name.toUpperCase()}
                                </p>
                            </div>

                            <div className='dateTextCont'>
                                <p className='dateText in'>
                                    CHECK-IN: {book.from}
                                </p>

                                <p className='dateText out'>
                                    CHECK-OUT: {book.to}
                                </p>
                            </div>

                        </div>

                        <div className='dateData'>

                            <p className='minitext'>
                                <img className='modalItemsImg' src="https://img.icons8.com/material-rounded/96/000000/bedroom.png" /> {book.room}
                            </p>

                            <p className='minitext'>
                                <img className='modalItemsImg' src="https://img.icons8.com/ios-glyphs/90/1A1A1A/conference-call--v1.png" /> {book.guests}
                            </p>

                            <p className='minitext'>
                                <img className='modalItemsImg' src="https://img.icons8.com/ios-glyphs/90/000000/money--v1.png" /> ${book.total.toLocaleString('de-DE')}
                            </p>

                        </div>

                        <div className='dateData'>

                            <button className='actionButton' onClick={() => window.location = `mailto: ${book.client.email}`}>
                                <img className='actionImg' src="https://img.icons8.com/ios-filled/100/000000/new-post.png" />
                            </button>



                            <button className='actionButton' onClick={() => window.location = `https://wa.me/${Number(book.client.phone)}`}>
                                <img className='actionImg' src="https://img.icons8.com/ios-glyphs/240/000000/whatsapp.png" />
                            </button>

                            <button className='actionButton' onClick={() => window.location = `tel:${Number(book.client.phone)}`}>
                                <img className='actionImg' src="https://img.icons8.com/ios-glyphs/90/000000/phone-disconnected.png" />
                            </button>

                        </div>


                    </div>

                )



            })} </> : <></>}


        </div>
    )
}

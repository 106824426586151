import React, { useState, createContext, useContext, useEffect } from 'react';


const CalendarContext = createContext([])

export const useCalendarContext = () => useContext(CalendarContext)


const CalendarContextProvider = ({ children }) => {


    const [currentMonth, setCurrentMonth] = useState()
    const [daysInCurrentMonth, setDaysInCurrentMonth] = useState()
    const [currentYear, setCurrentYear] = useState()
    const [dateList, setDateList] = useState([])
    const [to, setTo] = useState({})
    const [from, setFrom] = useState({})
    const [daysBooked, setDaysBooked] = useState(Number)
    const [daysBookedCont, setDaysBookedCont] = useState([])

    function addToDates(newdate) {    // THIS FUNCTION ADD THE NEWDATE TO THE DATELIST
        let i = dateList.findIndex(a => a.day === newdate.day && a.month === newdate.month);


        if (i !== -1) {
              setDateList([...dateList])
        }  else {
            setDateList(
                [...dateList, newdate])
        }
    }

 
       function removeSelection() { // THIS FUNCTION REMOVE THE DAYS SELECTION
        setDateList([])
    }

    useEffect(() => {
        function getDaysInMonth(year, month) {
            return new Date(year, month, 0).getDate();
          }
          
          const date = new Date();
          const currentYearF = setCurrentYear(date.getFullYear());
          const currentMonthF = setCurrentMonth(date.getMonth() + 1); // 👈️ months are 0-based
          
          // 👇️ Current Month
          const daysInCurrentMonthF = setDaysInCurrentMonth(getDaysInMonth(currentYear, currentMonth));
  
    }, [from])
    

  useEffect(() => {
    if (dateList.length === 0) {
      setFrom(dateList)
    } if (dateList.length >= 1) {
      setFrom(dateList[0])
      setTo(dateList[dateList.length - 1])
    }
  }, [dateList])


  useEffect(() => { // This useEffect updates the state daysBooked, that returns the total of days selected by the user.
    const a = to.day - from.day;
    if (a < 0) {
      return setDaysBooked(a + daysInCurrentMonth)
    } if (a > 0 && from.month < to.month && from.year === to.year) {
      return setDaysBooked(a + daysInCurrentMonth)
    } if (a > 0 && from.month > to.month && from.year != to.year) {
      return setDaysBooked(a + daysInCurrentMonth)
    }    else {
      return setDaysBooked(to.day - from.day)
    }
  }, [to])



    return (
        <CalendarContext.Provider value={{
            addToDates,
            removeSelection,
            dateList,
            daysBooked,
            to,
            from,
            daysBookedCont,
            daysInCurrentMonth
        }}>
            {children}

        </CalendarContext.Provider>
    )

}

export default CalendarContextProvider
import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import BookListCont from '../booking/BookListCont';
import { useBookingContext } from '../context/BookingContext'
import { useRoomsContext } from '../context/RoomsContext'
import './Microsites.css';
import { LineWobble } from '@uiball/loaders'
import { Link } from 'react-router-dom';
import Gallery from './Gallery';
import NewComment from './NewComment';
import CommentsList from './CommentsList';
import OurRooms from './OurRooms';
import AmenitiesButton from '../panel/roomsEdit/buttons/AmenitiesButton';
import Contact from '../mailing/Contact';

export default function MicrositesConts() {
  const db = getFirestore()

  const { customer } = useParams()

  const { rooms, contextRooms } = useRoomsContext()
  const [comments, setComments] = useState([])
  const { contextBookings, bookings, rerender } = useBookingContext()
  const [micrositeData, setMicrositeData] = useState([])
  const [images, setImages] = useState()
  const [texts, setTexts] = useState()
  const [social, setSocial] = useState()
  const [commentModal, setCommentModal] = useState(false)
  const [useramenities, setUseramenities] = useState([])
  const [amenities, setAmenities] = useState()
  const [data, setData] = useState([])
  const [amenitiesCont, setAmenitiesCont] = useState([])
  const [loader, setLoader] = useState(true)
  const [showCalendar, setShowCalendar] = useState(false)
  const [holdearImages, setHoldearImages] = useState([])
  const [showTerms, setShowTerms] = useState(false)

  const [clients, setClients] = useState([])

  const [userExists, setuserExists] = useState(true)


  useEffect(() => {

    const QueryCollection = collection(db, `clients`)

    getDocs(QueryCollection)
      .then(resp => setClients(resp.docs.map(site => ({ id: site.id, ...site.data() }))))
      .catch((err) => console.log(err))


  }, [])

  useEffect(() => {

    const clientCheck = clients.find(e => e.id == customer)


    if (clientCheck === undefined) {
      setuserExists(false)
    } else {
      setuserExists(true)
    }


  }, [clients])



  useEffect(() => {

    const QueryCollection = collection(db, `holdear/images/default`)

    getDocs(QueryCollection)
      .then(resp => setHoldearImages(resp.docs.map(site => ({ id: site.id, ...site.data() }))))
      .catch((err) => console.log(err))


  }, [])

  useEffect(() => {
    const QueryCollection = collection(db, `holdear`)

    getDocs(QueryCollection)
      .then(resp => setData(resp.docs.map(book => ({ id: book.id, ...book.data() }))))
      .catch((err) => console.log(err))

  }, [])
  useEffect(() => {
    const QueryCollection = collection(db, `clients/${customer}/microsite`)

    getDocs(QueryCollection)
      .then(resp => setUseramenities(resp.docs.map(amen => ({ id: amen.id, ...amen.data() }))))
      .catch((err) => console.log(err))
  }, [customer])



  useEffect(() => {
    if (useramenities.length != 0) {
      setTimeout(() => {
        const amenities = useramenities.find(e => e.id == "amenities").newamenities
        setAmenitiesCont(amenities)
      }, 500);
    }


  }, [useramenities])

  useEffect(() => {

    const QueryCollection = collection(db, `clients/${customer}/comments`)

    getDocs(QueryCollection)
      .then(resp => setComments(resp.docs.map(site => ({ id: site.id, ...site.data() }))))
      .catch((err) => console.log(err))


  }, [customer])



  const showCommentModal = () => {
    if (commentModal === true) {
      setCommentModal(false)
    } else {
      setCommentModal(true)
    }
  }


  useEffect(() => {
    contextBookings.push(customer)
    contextRooms.push(customer)
  }, [])

  const showCalendarF = () => {
    if (showCalendar === true) {
      setShowCalendar(false)
    } else {
      setShowCalendar(true)
    }
  }


  useEffect(() => {

    const QueryCollection = collection(db, `clients/${customer}/microsite`)

    getDocs(QueryCollection)
      .then(resp => setMicrositeData(resp.docs.map(site => ({ id: site.id, ...site.data() }))))
      .catch((err) => console.log(err))


  }, [customer])


  useEffect(() => {
    setTexts(micrositeData.find(data => data.id == "texts"))
    setImages(micrositeData.find(data => data.id == "images"))
    setSocial(micrositeData.find(data => data.id == "social"))
    setAmenities(micrositeData.find(data => data.id == "amenities"))
  }, [micrositeData])



  setTimeout(() => {
    setLoader(false)
  }, 3000);

  const showTermsF = () => {
    if (showTerms === true) {
      setShowTerms(false)
    } else {
      setShowTerms(true)
    }
  }



  const [bookInput, setBookInput] = useState(false)
  const [dniForSearcher, setDniForSearcher] = useState("")
  const [bookFound, setBookFound] = useState("")
  const [showBooks, setShowBooks] = useState(false)
  const [noBookings, setnoBookings] = useState(false)


  const showBookInput = () => {
    if (bookInput === true) {
      setBookInput(false)
    } else {
      setBookInput(true)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const dataLoad = (a) => {
    return new Promise((resolve, reject) => {
      if (a === undefined || a.length === 0 || a === "") {
        
        reject(setnoBookings(true))
  
      } else {
        resolve()
      }
    })
  }

  const closeSearcher = () => {
    setShowBooks(false)
    setBookInput(false)
  }

  const searchMyBook = async () => {
    let bookSearcher = bookings.filter(e => e.client.dni == dniForSearcher)

    dataLoad(bookSearcher)
      .then(setBookFound(bookSearcher))
      .then(setnoBookings(false))
      .then(setShowBooks(true))
      .catch(err => {console.log(err); if(err === undefined || err.length === 0 || err === "" ){setnoBookings(true)}} )

  }

  return (
    <>

      {loader === true ?
        <div className='loader column'>
          <p className='logoMicrositeLoading'>HOLDE <span className='logobackMicrosite'>.ar</span></p>
          <LineWobble
            size={300}
            lineWeight={8}
            speed={2.5}
            color="#576F72" />
        </div> :

        <>
          {userExists === true ?

            <>

              <Link to="/">
                <p className='logoMicrosite'>HOLDE <span className='logobackMicrosite'>.ar</span></p>

              </Link>

              <div className='BookSearcher'>
                {bookInput === false ?
                  <button onClick={showBookInput} className='BookSearcherTittle'>
                    Mis reservas
                  </button>
                  :

                  <>
                    <form action="" onSubmit={handleSubmit}>
                      <input className='searcherInput' type="text" name="DNI" placeholder="Ingresa tu DNI/ID" onChange=
                        {(e) => setDniForSearcher(e.target.value)} />
                      <button className='searcherButton' type='submit' onClick={searchMyBook}>
                        Buscar
                      </button>
                      {showBooks === true ?  
                       <button onClick={closeSearcher} className='closeButtonSearcher off'>x</button>
                      : <></> }
                    </form>
                    {showBooks === false ? <> </> :
                      <>
                        {bookFound.map((e) => {

                          return <div className='bookFoundedCont' key={e.id}>
                            <p className='bookFoundedText'> <b>{e.client.name}</b> </p>
                            <p className='bookFoundedText'>Check-In: <b>{e.from}</b> </p>
                            <p className='bookFoundedText'>Check-Out: <b>{e.to}</b> </p>
                            <p className='bookFoundedText'>Habitación: <b> {e.room}</b> </p>
                            {e.status !== null ?
                            <>
                              {e.status === true ?
                              <p className='bookFoundedText on'>
                                Estado: <b>OK</b>
                              </p> : <p className='bookFoundedText off'>
                                Estado: <b>CANCELADA</b>
                              </p>}
                            </>
                            :

                           <p className='bookFoundedText pending'>
                            Estado: <b>PENDIENTE</b>
                          </p>

                          
                          }
                          
                          </div>

                        })}
                      </>
                    }

                    {noBookings === true ? <p>No existen reservas con ese DNI/ID</p> : <></>}

                  </>

                }

              </div>

              <section className='sectionBlack greenBack2'>

                {showCalendar === false ?
                  <img src={images == undefined ? "https://firebasestorage.googleapis.com/v0/b/hoteler-4c5d2.appspot.com/o/holdear%2Fimages%2Fdefault%2Fwhitebg.jpg?alt=media&token=d2c4ae4d-9423-43b9-b0a1-89b881501e3d" : images.cover} className="imgCover" alt="" />
                  :
                  <></>
                }

                <div className={showCalendar === false ? "header" : "displayNone"}>
                  <div className='alignCenterTopMap'>
                    <img className='miniImage' src="https://img.icons8.com/ios-filled/100/FFFFFF/marker.png" alt="" />
                    <p className='micrositeminitext'> {texts.city}, {texts.country}</p>
                  </div>

                  <h1 className='micrositeTittle'> {texts.name} </h1>

                  <p className='micrositeDescription'>  {texts.description}</p>

                  <div className="micrositeSocialMediaCont">
                    {social.instagram === undefined || social.instagram === "" ? <></> : <a target="_blank" href={`https://www.instagram.com/${social.instagram}`}><img className='socialMediaIcon' src="https://img.icons8.com/ios-glyphs/120/FFFFFF/instagram-new.png" alt="" /></a>}

                    {social.facebook === undefined || social.facebook === "" ? <></> : <a target="_blank" href={`https://www.facebook.com/${social.facebook}`}><img className='socialMediaIcon' src="https://img.icons8.com/ios-filled/100/FFFFFF/facebook-new.png" alt="" /></a>}


                    {social.twitter === undefined || social.twitter === "" ? <></> : <a target="_blank" href={`https://www.twitter.com/${social.twitter}`}><img className='socialMediaIcon' src="https://img.icons8.com/ios-filled/100/FFFFFF/twitter.png" alt="" /></a>}

                    {social.whatsapp === undefined || social.whatsapp === "" ? <></> :
                      <a target="_blank" href={`https://wa.me/${social.whatsapp}`}><img className='socialMediaIcon' src="https://img.icons8.com/ios-glyphs/120/FFFFFF/whatsapp.png" alt="" /></a>}


                    {social.youtube === undefined || social.youtube === "" ? <></> :
                      <a target="_blank" href={`https://youtube.com/c/${social.youtube}`}><img className='socialMediaIcon' src="https://img.icons8.com/ios-glyphs/240/FFFFFF/youtube--v1.png" alt="" /></a>}

                  </div>
                </div>


                <button onClick={showCalendarF} className='showCalendarButton gradient' to="#listcont">
                  {showCalendar === true ? "Volver atrás" : "Reservá"}
                </button>

                <div className={showCalendar === true ? "calendarMicrosite" : "displayNone"} >
                  <BookListCont customerId={customer} rooms={rooms} />
                </div>


              </section>


              <section className='section rowInv'>

                <div className='roomsandmessCont '>
                  <p className='ourGuests'>Nuestras habitaciones</p>
                  <OurRooms />
                </div>

                <div className='roomsandmessCont '>
                  <Contact customer={customer} />
                </div>
              </section>
              <img className='' src="https://img.icons8.com/dotty/80/000000/wavy-line.png" />

              <section className='section column'>
                <p className='ourGuests'>Nuestros huéspedes</p>
                <div className='commentsCont'>
                  <CommentsList comments={comments} />
                </div>
                <button className='leaveMessage' onClick={showCommentModal}>¡Dejá tu mensaje!</button>
                <NewComment newCommentModal={commentModal} closeModal={showCommentModal} />
              </section>

              {images === undefined ? <></> :
                <section className='section column'>
                  <Gallery images={images} texts={texts} micrositeData={micrositeData} />
                </section>
              }

              <img className='' src="https://img.icons8.com/dotty/80/000000/wavy-line.png" />

              <section className='section column'>
                <p className='ourGuests'>Servicios</p>

                <div className='amenitiesDashBoardCont'>
                  {amenitiesCont !== [] && amenitiesCont !== undefined ?
                    <>
                      {amenitiesCont.map(amenitie =>
                        <AmenitiesButton key={amenitie} dashboard={false} text={amenitie} data={data} useramenities={useramenities} />
                      )}
                    </>

                    : <></>}

                </div>
              </section>
              <footer>
                <div className='footerTexts center none-desktop'>
                  <iframe className='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50767.59117973211!2d-59.2144605867839!3d-37.31943292243646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95911f9b2733946f%3A0xceef2c76899c202d!2sTandil%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1661813499253!5m2!1ses-419!2sar" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>

                <div className='footerTexts '>
                  <p className='footerTextItem'>{texts.name}</p>
                  <p className='footerTextItem'> {texts.phone} </p>
                  <a className='alignLeft footerTextItem' href={texts.maps}>
                    <img className='miniImage' src="https://img.icons8.com/ios-filled/100/FFFFFF/marker.png" alt="" />
                    <p className='micrositeminitext'> {texts.city}, {texts.country}</p>

                  </a>
                  <img className='footerSep' src="https://img.icons8.com/dotty/80/000000/wavy-line.png" />
                  <Link to="/">
                    <p className='footerTextItem'>Sobre nosotros</p>

                  </Link>

                  <Link to="/welcome">
                    <p className='footerTextItem'>Quiero ser holder</p>

                  </Link>


                  <p onClick={showTermsF} className='footerTextItem'>Bases y condiciones</p>
                  <div onClick={showTermsF} className={showTerms === true ? "termsAndCondCont " : ""}>
                    {showTerms === true ?

                      <>
                        <p className='ourGuests'>Bases y condiciones</p>
                        <p className='micrositeminitext'>
                          HOLDE.AR brinda una plataforma para que alojamientos ofrezcan sus servicios.
                          <br /><br />
                          HOLDE.AR no tiene relación comercial con ninguno de los usuarios que puedan registrarse en la plataforma y no se responsabiliza por el accionar de los mismos.
                          <br /><br />
                          HOLDE.AR no se responsabiliza por incumplimientos de los usuarios registrados como proveedores de servicios hoteleros.
                          <br /><br />
                          Los huéspedes deberán recurrir sin excepción al hospedaje en caso de algún inconveniente con su reserva. También podrán escribir vía email a HOLDE.AR para advertirnos y actuar sobre la inhabilitación o no del usuario.
                          <br /><br />
                          En caso de que un usuario incurra en una práctica sospechosa o reciba malos comentarios de huéspedes, podrá ser inhabilitado para continuar en HOLDE.AR.
                          <br /><br />
                          HOLDE.AR no es receptor de ningún pago con excepción de los procesados a través de los links dispuestos en la sección "planes" del administrador de cada cuenta. Por lo tanto, todo reclamo deberá ser dirigido al usuario y no a la plataforma.
                          <br /><br />
                          Cada huésped asume los riesgos que deriven del uso de la plataforma y recae en él verificar la fiabilidad del servicio del hospedaje.
                        </p>

                      </>

                      : <></>}

                  </div>
                </div>

                <div className='footerTexts center none-mobile'>
                  {texts.maps === undefined ? <></> :
                    <iframe className='map' src={texts.maps} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                  }

                </div>
                <div className='footerTexts flexend'>
                  <p className='logoMicrositeFooter'>HOLDE <span className='logobackMicrositeFooter'>.ar</span></p>
                </div>
              </footer>
            </>

            :

            <>
              <Link to="/">
                <p className='logoMicrosite black'>HOLDE <span className='logobackMicrosite'>.ar</span></p>

              </Link>

              <section className='accountDoesntExists'>
                <Link to="/welcome">
                  <p className='nextButton'>Este usuario no existe. Registralo acá.</p>
                </Link>
              </section>
            </>
          }
        </>
      }
    </>
  )
}

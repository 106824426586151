import React, { useState, useEffect } from 'react';
import { useLocation} from 'react-router-dom';
import { addDoc, collection, getFirestore, getDocs} from 'firebase/firestore';
import { LineWobble } from '@uiball/loaders'

export default function SavePaymentData() {
    const db = getFirestore()
    const location = useLocation()

    const [loader, setLoader] = useState(true)
    const [images, setImages] = useState([])

    

    useEffect(() => {

      const QueryCollection = collection(db, `holdear/homepage/app`)
  
      getDocs(QueryCollection)
        .then(resp => setImages(resp.docs.map(site => ({ id: site.id, ...site.data() }))))
        .catch((err) => console.log(err))
  
  
    }, [])
   
    useEffect(() => {
      const queryCollection = collection(db, `tokens`)
      let mercadopagoToken = {}
      mercadopagoToken.timestamp = Date.now()
      mercadopagoToken.code = location.search

      if (location.search.length > 5 && location.search.includes("error") === false) {
          return addDoc(queryCollection, mercadopagoToken)
              .then(res => console.log(res))
              .catch(err => console.log(err))
      }

    
    }, [location])
    


      useEffect(() => {
        setLoader(true)
      
        setTimeout(() => {
          setLoader(false)
        }, 1500);
       }, [images])
  return (
    <div>
{loader === true ? <> <LineWobble  size={80} lineWeight={5} speed={1.75} color="white" /> </> :
   
   <>
       <p className='sectionTittles'>| PAYMENTS ROOM </p>
       <p className='mercadopagoTittle'>¡Listo! Activaremos cuanto antes tu nuevo método de pago.</p>

        <img className='appImg' src={images[0].app7} alt="Llevá tu emprendimiento al próximo nivel." />
   </>
   
   }
 

    </div>
  )
}

import React, { useState, useEffect } from 'react';


export default function MicroCalendarItem({ day, loopFilter, room3Months, year, month, todaysYear, room1MonthsNY, room2MonthsNY, room3MonthsNY, bookings, roomSelected, roomSelectedNY}) {

    const [daysAvail, setDaysAvail] = useState()
    const [firstDay, setFirstDay] = useState("")
    const [thisDayName, setThisDayName] = useState("")
    const [monthSel, setMonthSel] = useState()
    const [arrives, setArrives] = useState(false)
    const [departures, setDepartures] = useState(false)
    const [onlyDepartures, setOnlyDepartures] = useState(false)
    const [onlyArrivals, setOnlyArrivals] = useState(false)
    const [showBookingsModal, setShowBookingsModal] = useState(false)
    const [booksFromThisDay, setBooksFromThisDay] = useState([])
    const [departuresFromToday, setDeparturesFromToday] = useState([])

    const a = "d" + day
    const condition1 = year !== todaysYear
    useEffect(() => {
 
        setTimeout(() => {
            if (condition1 === true) {
                const roomSel = roomSelectedNY.find(e => e.id == month)
    
                const availability = [roomSel[a]]

                setDaysAvail(availability.every(e => e === false || e === 0))
                setMonthSel(roomSel)
    
            } else {

                const roomSel = roomSelected.find(e => e.id == month)

                const availability = [roomSel[a]]

                setDaysAvail(availability.every(e => e === false || e === 0))
                setMonthSel(roomSel)


            }
        }, 10);
    }, [month, room3Months, room3MonthsNY,  year, loopFilter])

    

    useEffect(() => {
        const dayString = day + "/" + month + "/" + year
        const arriveCheck = bookings.map(e => e.from === dayString && e.status === true)
        const bookExists = bookings.filter(e => e.from === dayString && e.status === true)

        if (arriveCheck.some(e => e === true)) {
            setArrives(true)
            setBooksFromThisDay(bookExists)
        } else {
            setArrives(false)
        }
    }, [month, loopFilter,  year])

    useEffect(() => {
        const dayString = day + "/" + month + "/" + year
        const arriveCheck = bookings.map(e => e.to === dayString && e.status === true)
        const bookExists = bookings.filter(e => e.to === dayString && e.status === true)

        if (arriveCheck.some(e => e === true)) {
            setDepartures(true)
            setDeparturesFromToday(bookExists)
        } else {
            setDepartures(false)
        }
    }, [month, loopFilter,  year])

    const showBookings = () => {
        if (arrives === true || departures === true) {
            setShowBookingsModal(true)
        }

    }

    const checkArrivals = () => {
        setOnlyArrivals(true)
    }

    const checkDepartures = () => {
        setOnlyDepartures(true)
    }

    const closeModal = () => {
        setShowBookingsModal(false)
        setOnlyArrivals(false)
        setOnlyDepartures(false)
    }


    useEffect(() => {

        const yearf = year
        const monthf = month
        const firstDayF = new Date(yearf, monthf - 1, 1)
        const thisDay = new Date(yearf, monthf - 1, day)

        setFirstDay(firstDayF.toString()[0] + firstDayF.toString()[1] + firstDayF.toString()[2])

        setThisDayName(thisDay.toString()[0] + thisDay.toString()[1] + thisDay.toString()[2])
    }, [month, year])


    return (
        <>



            <div className='microCalendarDayCont'>

                <p className='MicroCalendardayboxWeek '>
                    {thisDayName}  <b> {day}  </b>
                </p>
                <div>


                    {daysAvail === false || daysAvail === true ?
                        <>
                            {monthSel.id != undefined ? <div className={monthSel[a] === true ? 'MicroCalendarRoomCont avail' : 'MicroCalendarRoomCont notavail'} >
                                <div> {monthSel[a] === true ? <b> Disponible</b> : <b> Vendido </b>} </div>
                            </div>
                                : <></>}

                        </> : <></>
                    }
                </div>









            </div>
        </>



    )
}

import React, { useState, useEffect } from 'react';
import { setDoc, doc, getFirestore, collection, getDocs } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { useRoomsContext } from '../../context/RoomsContext'
import NavbarPanel from '../NavbarPanel';
import './RoomsEditCont.css';
import { useLoginContext } from '../../context/LoginContext'
import Login from '../login/Login';
import AmenitiesButton from './buttons/AmenitiesButton';
import { Link } from 'react-router-dom';
import Backwardsbutton from '../../buttons/Backwardsbutton';
export default function AmenitiesEdit() {


    const db = getFirestore()
    const { customer } = useParams()
    const { rooms, contextRooms } = useRoomsContext()
    const { loginCheck } = useLoginContext()


    const [data, setData] = useState([])

    const [amenitiesCont, setAmenitiesCont] = useState([])
    const [useramenities, setUseramenities] = useState([])
    const [newamenities, setNewamenities] = useState([])
    const [amenitiesImages, setAmenitiesImages] = useState([])
    const [render, setRender] = useState(false)


    useEffect(() => {
        contextRooms.push(customer)
    }, [])

    useEffect(() => {
        const QueryCollection = collection(db, `holdear`)

        getDocs(QueryCollection)
            .then(resp => setData(resp.docs.map(book => ({ id: book.id, ...book.data() }))))
            .catch((err) => console.log(err))

    }, [])

    useEffect(() => {
        setTimeout(() => {
            const amenities = data.find(e => e.id == "amenities")
            const amenitiesFilter = Object.keys(amenities).filter(e => e !== "id")


            setAmenitiesCont(amenitiesFilter)
            setAmenitiesImages(Object.values(amenities))

        }, 100);

    }, [data])


    useEffect(() => {
        const QueryCollection = collection(db, `clients/${customer}/microsite`)

        getDocs(QueryCollection)
            .then(resp => setUseramenities(resp.docs.map(amen => ({ id: amen.id, ...amen.data() }))))
            .catch((err) => console.log(err))
    }, [customer, render])

    useEffect(() => {

        setTimeout(() => {
            if (useramenities.find(e => e.id === "amenities") != undefined) {
                setNewamenities(useramenities.find(e => e.id === "amenities").newamenities)
            }

        }, 200);

    }, [useramenities, render])



    const [loginAnimation, setLoginAnimation] = useState(false)

    useEffect(() => {
        setLoginAnimation(true)

        setTimeout(() => {
            setLoginAnimation(false)


        }, 2000);
    }, [])



    const setNewData = () => {
        setDoc(doc(db, `clients/${customer}/microsite`, "amenities"), {
            newamenities
        })
    }

    const pushAmenitie = (a) => {
        const arr = useramenities.find(e => e.id === "amenities") != undefined ? useramenities.find(e => e.id === "amenities").newamenities : []

        if (arr.includes(a) === false) {
            newamenities.push(a)

            setTimeout(() => {
                setNewData()
                if (render === true) {
                    setRender(false)
                } else { setRender(true) }


            }, 100);
        } else {

            let filter = newamenities.filter(e => e != a)

            setTimeout(() => {

                setDoc(doc(db, `clients/${customer}/microsite`, "amenities"), {
                    "newamenities": filter
                })

                if (render === true) {
                    setRender(false)
                } else { setRender(true) }


            }, 200);
        }

    }





    return (
        <>
            {loginCheck != true ?

                <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>
                    <p className='logoMicrositeLoading' >HOLDE <span className='logobackMicrosite'>.ar</span></p>

                    <Login customer={customer} />
                </div>
                :


                <>
                    <div className='dashboardCont'>
                        <NavbarPanel rooms={rooms} customer={customer} />
                        <p className='sectionTittles'>| AMENITIES ROOM </p>



                        <div className='margintop column'>
                            <p className='roomsedittitle'>Elegí los amenities</p>
                            <div className='amenitiesDashBoardCont'>

                                {amenitiesCont.map(amenitie =>
                                    <AmenitiesButton key={amenitie} dashboard={true} text={amenitie} data={data} useramenities={useramenities} push={pushAmenitie} render={render} />
                                )}

                            </div>
                            <Link className='link navbarList' to={`/${customer}/dashboard/rooms`}>
                                <Backwardsbutton text="⬸ Regresar" />
                            </Link>
                        </div>
                    </div>
                </>
            }

        </>
    )
}


import React, { useState, useEffect } from 'react';
import { collection, getFirestore, getDocs, writeBatch, where, query, documentId } from 'firebase/firestore';

export default function BookOrderDashboard({ id, bookdate, hour, fullbookdate, from, to, client, status, total, room, guests, fullto, fullfrom, timestamp, type, year, roomcode, daysInCurrentMonth, daysBooked, bookings, rerender, customer, toDaysInMonth, fromDaysInMonth }) {
    const db = getFirestore()

    // const date = bookdate.day + "/" + bookdate.month + "/" + bookdate.year

    const [switchApprove, setSwitchApprove] = useState(true)
    const [switchText, setSwitchText] = useState(false)
    const [allDaysBooked, setAllDaysBooked] = useState([]);

    const [cancelled, setCancelled] = useState(false)
    const [cancelling, setCancelling] = useState(false)
    const date = new Date();

    const [dayspassed, setDayspassed] = useState(Number)



    const monthspassed = (date.getMonth() + 1) - fullbookdate.month
    const yearspassed = (date.getFullYear()) - fullbookdate.year


    const hourspassed = (date.getHours() - Number(hour.substring(0, 2))) + ((dayspassed + (monthspassed * 30.5) + (yearspassed * 365)) * 24)


    const weekf = parseInt(hourspassed / (7 * 24))
    const monthf = parseInt(hourspassed / (30 * 24))
    const yearf = parseInt(hourspassed / (365 * 24))

    useEffect(() => {
        const days = date.getDate() - fullbookdate.day

        if (days >= 0) {
            setDayspassed(days)
        } else {
            setDayspassed(days + 30)
        }


    }, [])

    const onSwitch = () => {
        if (switchApprove === false) {
            setSwitchApprove(true)
        } if (switchApprove === true) {
            setSwitchApprove(false)
        }
    }

    const hoverSwitchText = () => {
        if (switchText === false) {
            setSwitchText(true)
        } if (switchText === true) {
            setSwitchText(false)
        }
    }


    useEffect(() => {
        // This variable contains the range of days selected.
        const daysBookedArr = Array(daysBooked).fill(fullfrom.day).map((x, y) => x + y)
        const daysBookedArr2 = Array(daysBooked).fill(fullfrom.day + 1).map((x, y) => x + y)


        const daysOfNextMonthFilt = fromDaysInMonth === 31 && toDaysInMonth === 30 ? daysBookedArr.filter(e => e > toDaysInMonth) :
        fromDaysInMonth === 30 && toDaysInMonth === 31 ?  daysBookedArr2.filter(e => e > toDaysInMonth)
        : daysBookedArr.filter(e => e > toDaysInMonth)
        
        


        const daysFirstMonth = daysBookedArr.filter(e => e <= fromDaysInMonth)
        const daysSecondMonth = fullto.month === 2 ? daysOfNextMonthFilt.map(e => e - fromDaysInMonth)  : daysOfNextMonthFilt.map(e => e - toDaysInMonth)

        const daysObj = daysFirstMonth.map((e) => {
            return { id: roomcode, day: "d" + e, month: fullfrom.month, year: fullfrom.year }
        })

        const daysObjSecond = daysSecondMonth.map((e) => {
            return { id: roomcode, day: "d" + e, month: fullto.month, year: fullto.year }
        })

        const concat = daysObj.concat(daysObjSecond)

        setAllDaysBooked(concat.filter(e => e.day != "d0"  && e.day != "d-1"  && e.day != "d-2"))
    }, [roomcode])

    const confirmOrder = async () => {
        const arrId = [id]
        const batch = writeBatch(db)
        const queryCollection = collection(db, `clients/${customer}/bookings`)

     

        const queryStatus = await query(
            queryCollection,
            where(documentId(), 'in', arrId.map((e) => e)))

        await getDocs(queryStatus)
            .then(resp => resp.docs.forEach(((res) => {

                batch.update(res.ref, { status: true })
            })))

        batch.commit()

        setTimeout(() => {
            rerender()
        }, 500);
    }

    const cancelOrder = async () => {
        const date = new Date();

        setCancelling(true)

        setTimeout(() => {
            setCancelled(true)
            setCancelling(false)
            changeStatus()

            setTimeout(() => {
                rerender()
            }, 100);
        }, 2000);


        

        //THIS FUNCTION RE-ENABLE THE RESERVED SPOTS
        const daysOffirstMonth =  allDaysBooked.filter((e) => e.month.toString() == fullfrom.month)
        const daysOfsecondMonth =  allDaysBooked.filter((e) => e.month.toString() == fullto.month)
    
        const batch = writeBatch(db)
    
        const queryColFrom = collection(db, `clients/${customer}/rooms/${roomcode}/${fullfrom.year}`)
        const queryActualizarStockFrom = await query(
          queryColFrom,
          where(documentId(), 'in', daysOffirstMonth.map((e) => e.month.toString())))
    
    
          const queryColTo = collection(db, `clients/${customer}/rooms/${roomcode}/${fullto.year}`)
          const queryActualizarStockTo = await query(
            queryColTo,
            where(documentId(), 'in', daysOfsecondMonth.map((e) => e.month.toString())))
        
    
       await getDocs(queryActualizarStockFrom)
          .then(resp => resp.docs.forEach(((res) => {
            daysOffirstMonth.forEach((e) => {
              if (type != "shared") {
    
                return batch.update(res.ref, { [e.day]: true })
              } if (type == "shared") {
    
                return batch.update(res.ref, { [e.day]: res.data()[e.day] + guests  })
              } 
            })        
          })))
    
          await  getDocs(queryActualizarStockTo)
           
          .then(resp => resp.docs.forEach(((res) => {
            daysOfsecondMonth.forEach((e) => {
              if (type != "shared") {
                return batch.update(res.ref, { [e.day]: true })
              } if (type == "shared" ) {
                return batch.update(res.ref, { [e.day]: res.data()[e.day] + guests  })
              } 
            })
          })))
    
    
    
          await  batch.commit()
    

    }

    const changeStatus = async () => {

        const arrId = [id]
        const batch = writeBatch(db)
        const queryCollection = collection(db, `clients/${customer}/bookings`)

        const queryStatus = await query(
            queryCollection,
            where(documentId(), 'in', arrId.map((e) => e)))

        await getDocs(queryStatus)
            .then(resp => resp.docs.forEach(((res) => {

                batch.update(res.ref, { status: false })
            })))

        batch.commit()

    }

    return (


        <div className='dashboardBookOrder'>
            <div className={status !== false ? 'bookDataTop' : 'bookDataTop cancelled'}>
                <div className='bookDataTopLeft'>
                    <p className='clientText'>
                        {client.name.toUpperCase()}
                    </p>
                </div>

                <div className='dateTextCont'>
                    <p className={status === true ? 'dateText in' : 'dateText'}>
                        CHECK-IN: {from}
                    </p>

                    <p className={status === true ? 'dateText out' : 'dateText '}>
                        CHECK-OUT: {to}
                    </p>
                </div>

            </div>

            <div className='dateData'>

                <p className='minitext'>
                    <img className='modalItemsImg' src="https://img.icons8.com/material-rounded/96/000000/bedroom.png" /> {room}
                </p>

                <p className='minitext'>
                    <img className='modalItemsImg' src="https://img.icons8.com/ios-glyphs/90/1A1A1A/conference-call--v1.png" /> {guests}
                </p>

                <p className='minitext'>
                    <img className='modalItemsImg' src="https://img.icons8.com/ios-glyphs/90/000000/money--v1.png" /> ${total.toLocaleString('de-DE')}
                </p>

            </div>

            <div className='dateData'>

                <div className='minitext'>
                    {status !== null ?
                    
                <>
                  <p>{status === false ? "CANCELADA" : "CONFIRMADA"}</p>
                    <button className={status === false ? 'switch off' : 'switch on'} onClick={onSwitch} onMouseEnter={hoverSwitchText} onMouseLeave={hoverSwitchText} > <span className={status === false ? 'switchBallOff' : cancelling === true ? "switchBallCancelling" : 'switchBallOn'}>⬤</span> </button>

                    <button className={switchApprove === false && status === true ? 'cancelBookButton' : 'displayNone'} onClick={() => {
                        cancelOrder()
                    }}> CANCELAR </button>
                </>
                
                :

                <>
                <div className='column'>
                <p className='pending bookConditionText minitext'>Estado: PENDIENTE</p>

                <div className='row'>
                <button onClick={confirmOrder} className='on bookConditionText minitext cursorPointer'>CONFIRMAR</button>
                <button onClick={cancelOrder} className='off bookConditionText minitext cursorPointer'>CANCELAR</button>
                </div>
                </div>
                
                
                </>
                }
                  

                </div>

                <button className='actionButton' onClick={() => window.location = `mailto: ${client.email}`}>
                    <img className='actionImg' src="https://img.icons8.com/ios-filled/100/000000/new-post.png" />
                </button>



                <button className='actionButton' onClick={() => window.location = `https://wa.me/${Number(client.phone)}`}>
                    <img className='actionImg' src="https://img.icons8.com/ios-glyphs/240/000000/whatsapp.png" />
                </button>

                <button className='actionButton' onClick={() => window.location = `tel:${Number(client.phone)}`}>
                    <img className='actionImg' src="https://img.icons8.com/ios-glyphs/90/000000/phone-disconnected.png" />
                </button>

            </div>

            <p className={switchText === false ? "switchText displayNone" : "switchText"}>
                ¡CUIDADO! Esta acción es irreversible. Cambiar el estado de confirmación restablecerá los días que estaban reservados. No te olvides de avisarle al cliente sobre la cancelación.
{/*                 
                 BE CAREFUL! This action cannot be undone. Switching the confirmation status will re-enable the spots that were reserved. Don't forget to notify the customer about the cancellation. */}
                 </p>
        </div>
    )
}



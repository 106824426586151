import React, { useState } from 'react';
import { addDoc, collection, getFirestore } from 'firebase/firestore';
import './Contact.css';
import { useParams } from 'react-router-dom';

function Contact({ }) {

    const db = getFirestore()
    const { customer } = useParams()

    const [message, setMessage] = useState()
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()
    const [sent, setSent] = useState(false)



    const handleSubmit = (event) => {
        event.preventDefault();
    }


    //ESTA FUNCIÓN ENVÍA EL MENSAJE A FIREBASE, EN "CORREOS"
    //LOS MENSAJES SE VISUALIZAN EN EL PANEL DE ADMINISTRACIÓN
    const sendmessage = () => {
        const date = new Date();

        let mail = {}


        mail.id = parseInt(((Math.random() * 10000000) + (Math.random() * 10000000)) / (Math.random(1, 50)));
        mail.name = name;
        mail.text = message;
        mail.fulldate = { day: date.getDate(), month: (date.getMonth() + 1), year: date.getFullYear() };
        mail.hour = date.getHours() + ":" + date.getMinutes();
        mail.email = email;
        mail.timestamp = Date.now();
        mail.date = Date().substring(0, 24);
        mail.phone = phone;
        mail.status = false;

        const queryCollection = collection(db, `clients/${customer}/messages`)
        addDoc(queryCollection, mail)
            .then(res => console.log(res))
            .catch(err => console.log(err))
       
    }

    return (
        <div className='contactCont'>

 
            <form className="sectionwa-subtit-cont" onSubmit={handleSubmit}>
            <p className='ourGuests'>Dejanos tu consulta</p>

                <div className="inputsCont">
                    <p className="ourGuests white">¡Escribinos!</p>

           
                    <input className="RoomEditInput" type="text" name="name" placeholder="Ingresá tu nombre" onChange={(e) => setName(e.target.value)}>
                    </input>
            
                    <input className="RoomEditInput inputmiddle" type="email" name="email" placeholder="Ingresá tu email" onChange={(e) => setEmail(e.target.value)}>
                    </input>

         
                    <input className="RoomEditInput inputmiddle" type="phone" name="phone" placeholder="Ingresá tu celular" onChange={(e) => setPhone(e.target.value)}>
                    </input>

                    <textarea className="RoomEditInput textareainput" type="text" name="message" placeholder="Escribí tu mensaje acá..." onChange={(e) => setMessage(e.target.value)}></textarea>

                    {sent === false ? <div className='buttoncont'>
                        <button type="submit" className="wabotonmensaje" onClick={() => {
                            sendmessage(); setSent(true)
                        }}> Enviar </button>
                    </div> : <div className='buttoncont'>
                        <a type="submit" className="wabotonmensaje" > ¡Enviado!</a>
                    </div>}

                </div>
            </form>
        </div>
    );
}

export default Contact;

import React from 'react';
import RoomCards from './RoomCards'
import { useCalendarContext } from '../context/CalendarContext'
import './RoomCards.css';



export default function RoomCardsList({ rooms, forbook1, forbook2, forbook3, forbook4, forbook5, forbook6, forbook7, forbook8, forbook9, forbook10,  forbook11,  forbook12,  forbook13,  forbook14,  forbook15, searchIn, daysBooked, guests, weekCalendar, admin, customerId }) {

  const { dateList } = useCalendarContext()

  return (
    <>
      <div className='RoomsListCont'>

        {forbook1 === false && forbook2 === false && forbook3 === false && dateList.length >= 2 && forbook4 === false && forbook5 === false && forbook6 === false && forbook7 === false && forbook8 === false && forbook9 === false && forbook10 === false && forbook11 === false && forbook12 === false && forbook13 === false && forbook14 === false && forbook15 === false && searchIn === true ?

          <p className='noRoomsAdv'>
            ¡Perdón! No hay ninguna habitación disponible en el rango de días que elegiste. Por favor, elegí otras fechas.

            {/* We apologize for the inconvenience. The are no rooms available in the date range you selected. Please select an alternative date range. */}
            </p>
          :
          rooms.map((room) => <RoomCards key={room.roomname} {...room} forbook1={forbook1} forbook2={forbook2} forbook3={forbook3} forbook4={forbook4} forbook5={forbook5} forbook6={forbook6} forbook7={forbook7} forbook8={forbook8} forbook9={forbook9} forbook10={forbook10} forbook11={forbook11} forbook12={forbook12} forbook13={forbook13} forbook14={forbook14} forbook15={forbook15} daysBooked={daysBooked} guests={guests} weekCalendar={weekCalendar} admin={admin} customerId={customerId} />)}
      </div>
    </>
  )
}

import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import { Link } from 'react-router-dom';
import NewMessagesItem from './NewMessagesItem';
import { LineWobble } from '@uiball/loaders'

export default function NewMessages({ messages, today, todaysMonth, todaysYear, day, month, year, customer }) {

  const [messagesFromThisDay, setMessagesFromThisDay] = useState([])
  const [loader, setLoader] = useState(true)


  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 1000);
  }, [])

  useEffect(() => {

    if (messages.length != 0) {
      let messagesToday = messages.filter((e) => e.fulldate.day == today && e.fulldate.month == todaysMonth && e.fulldate.year == todaysYear)
      setMessagesFromThisDay(messagesToday)
    }

  }, [messages])

  return (
    <div className='DashboardSectionCont'>

      <div className='row'>
        <p className='DashboardTitles'>Nuevos mensajes</p>
        <Link className='linkArrivals' to={`/${customer}/dashboard/messages`}>
          <p className='minitextLink' > Detalles </p>
        </Link>
      </div>

      {loader === true ? <div className='dashboardloader'>
        <LineWobble
          size={80}
          lineWeight={5}
          speed={1.75}
          color="#576F72" />
      </div> :
        <div className='ArrivalsItemCont'>
          {messagesFromThisDay.length === 0 ?

            <p className='nonewdata'> No hay nuevos mensajes</p> :
            <>    {messagesFromThisDay.map((message) =>
              <NewMessagesItem key={message.id} {...message} />
            )}</>}
        </div>}
    </div>
  )
}

import React, { useState, useEffect, useRef } from 'react';
import { setDoc, doc, collection, getFirestore, getDocs} from 'firebase/firestore';
import { Jelly } from '@uiball/loaders'
import { Link } from 'react-router-dom';
import { sendForm } from "emailjs-com";

export default function HotelData({ username, nextstep }) {
    const db = getFirestore()
    const [images, setImages] = useState([])


    const [loading, setLoading] = useState(false)
    const [loadedHotelData, setLoadedHotelData] = useState(false)
    const [loadedRoom, setLoadedRoom] = useState(false)

    const [hotelName, setHotelName] = useState("")
    const [city, setCity] = useState("")
    const [country, setCountry] = useState("")
    const [description, setDescription] = useState("")
    const [hotelEmail, setHotelEmail] = useState("")
    const [phone, setPhone] = useState("")

    const [instagram, setInstagram] = useState("")
    const [facebook, setFacebook] = useState("")
    const [twitter, setTwitter] = useState("")
    const [youtube, setYoutube] = useState("")
    const [hotelAddress, setHotelAddress] = useState("")
    const [whatsapp, setWhatsapp] = useState("")


    useEffect(() => {

        const QueryCollection = collection(db, `holdear/homepage/app`)
    
        getDocs(QueryCollection)
          .then(resp => setImages(resp.docs.map(site => ({ id: site.id, ...site.data() }))))
          .catch((err) => console.log(err))
    
    
      }, [])
    

    const handleSubmit = (event) => {
        event.preventDefault();
    }

    const setHotelData = () => {

        const emailSplit = hotelEmail.split("")
        const checkEmail = emailSplit.some(e => e === "@")

        if (hotelName.length > 2 && city.length > 2 && country.length > 2 && description.length > 2 && description.length <= 800 && hotelEmail.length > 2 && hotelAddress.length > 2 && checkEmail === true  && phone.length > 2  && hotelAddress.length > 2 ) {

            setLoading(true)
            setDoc(doc(db, `clients/${username}/microsite`, "texts"), {

                name: hotelName,
                email: hotelEmail,
                address: hotelAddress,
                city: city,
                country: country,
                description: description,
                maps: "",
                phone: phone,
                whatsapp: whatsapp
            })

            setDoc(doc(db, `clients/${username}/microsite`, "social"), {
                twitter: twitter,
                facebook: facebook,
                youtube: youtube,
                instagram: instagram
            })

            setDoc(doc(db, `clients/${username}/microsite`, "amenities"), {
                newamenities: []
            })

            
    setDoc(doc(db, `clients`, `${username}`, "payments", "extraPrices"), {
        "days": 10,
        "months" : 10, 
    })
  
    setDoc(doc(db, `clients`, `${username}`, "payments", "extraDays"), {
      "mon": null,
      "tue" : null, 
      "wed" : null,
      "thu" : null, 
      "fri" : null,
      "sat" : null, 
      "sun" : null,
  })
  
  
  setDoc(doc(db, `clients`, `${username}`, "payments", "extraMonths"), {
    "january": null,
    "february" : null, 
    "march" : null,
    "april" : null, 
    "may" : null,
    "june" : null, 
    "july" : null,
    "august" : null,
    "september" : null,
    "october" : null,
    "november" : null,
    "december" : null,
  })
        }

        setTimeout(() => {
            setLoading(false)
            setLoadedHotelData(true)

        }, 3000);
    }


    const [personalData, setpersonalData] = useState([])
    useEffect(() => {

        const QueryCollection = collection(db, `clients/${username}/profile`)
    
        getDocs(QueryCollection)
          .then(resp => setpersonalData(resp.docs.map(site => ({ id: site.id, ...site.data() }))))
          .catch((err) => console.log(err))
    
    
      }, [username])
    
    
      const [formData, setFormData] = useState({
        to_name:  "",
        email_to: "",
      });
      
     useEffect(() => {
    
   
        if(personalData.length > 1) {
            let e = personalData.find(doc => doc.id == "data")

            setFormData({
                to_name:  username,
                email_to: e.email,
              })
        } else {
 
        }
   

     }, [personalData])


    const formRef = useRef(null);
    
        const handleChange2 = event => {
          setFormData({
            ...formData,
            [event.target.name]: event.target.value
          });
        };

      
        const handleSubmit2 = event => {
          
      
          sendForm(
            "holdearappgmail",
            "template_qi1znrb",
            formRef.current,
            "5HliEyZdhFxxuTp9H"
          ).then(response => {
            if (response.status === 200) {
              // email was sent successfully
            } else {
              // email failed to send
            }
          });
        };



    return (
        <>

            {loading === true ?

                <div className='loaderContJelly'>
                    <Jelly
                        size={150}
                        speed={0.9}
                        color="#576F72"
                    />
                </div>

                : <></>}


            {loading === false && loadedHotelData === false ?

                <div className='greenBack3 formSectionCont'>


                    <p className='welcometittle'> Información del hotel </p>
                    <form className='formPersonalDataCont' action="" onSubmit={handleSubmit}>
                        <div className='center'>
                            <p className='detailtext'>Nombre</p>
                            <input className='personalDataInput' onChange={(e) => setHotelName(e.target.value)} />
                        </div>

                        <div className='center'>
                            <p className='detailtext'>Ciudad</p>
                            <input className='personalDataInput' onChange={(e) => setCity(e.target.value)} />
                        </div>

                        <div className='center'>
                            <p className='detailtext'>País</p>
                            <input className='personalDataInput' onChange={(e) => setCountry(e.target.value)} />
                        </div>

                        <div className='center'>
                            <p className='detailtext'>Descripción (máx. 800 caracteres) </p>
                            <textarea className='textareaHotelData' onChange={(e) => setDescription(e.target.value)} />
                        </div>

                        <div className='center'>
                            <p className='detailtext'>Email </p>
                            <input className='personalDataInput' onChange={(e) => setHotelEmail(e.target.value)} />
                        </div>

                        <div className='center'>
                            <p className='detailtext'>Dirección </p>
                            <input className='personalDataInput' onChange={(e) => setHotelAddress(e.target.value)} />
                        </div>

                        <div className='center'>
                            <p className='detailtext'>Teléfono</p>
                            <input className='personalDataInput' onChange={(e) => setPhone(e.target.value)} />
                        </div>

                        <div className='center'>
                            <p className='detailtext'>Instagram (usuario)  </p>
                            <input className='personalDataInput' onChange={(e) => setInstagram(e.target.value)} />
                        </div>

                        <div className='center'>
                            <p className='detailtext'> Facebook (usuario) </p>
                            <input className='personalDataInput' onChange={(e) => setFacebook(e.target.value)} />
                        </div>

                        <div className='center'>
                            <p className='detailtext'> Twitter (usuario) </p>
                            <input className='personalDataInput' onChange={(e) => setTwitter(e.target.value)} />
                        </div>


                        <div className='center'>
                            <p className='detailtext'> Youtube (usuario) </p>
                            <input className='personalDataInput' onChange={(e) => setYoutube(e.target.value)} />
                        </div>

                        <div className='center'>
                            <p className='detailtext'> Número de Whatsapp  </p>
                            <input placeholder='+549 + area cód. + teléfono' className='personalDataInput' onChange={(e) => setWhatsapp(e.target.value)} />
                        </div>

                    </form>


                    <button onClick={() => {
                        setHotelData(); handleSubmit2()
                    }} className='nextButton'> Siguiente ⤑ </button>

                </div>
                : <></>

            }

            {loadedHotelData === true ?
                <div className='registrationSuccessCont'>
                    <p className='welcometittle marginbottomzero'>¡Felicitaciones!</p>

                    <p className='detailtextSuccess'> Tu cuenta fue creada con éxito.</p>
                    <p className='detailtextSuccess'> Recordá que tu <b>DNI/CUIT/CUIL será tu contraseña</b>.</p>
                    <p className='detailtextSuccess'>Luego podrás cambiarla desde el administrador.</p>
                    <img className='appImg' src={images[0].app7} alt="Llevá tu emprendimiento al próximo nivel." />


                    <Link className='link linkSuccess' to={`/${username}/dashboard/rooms`}>
                        <p > Ahora <b>agrega la primera habitación</b> y conocé tu <br /> nuevo <b>panel de control</b> presionando acá. </p>

                    </Link>

                </div>
                : <></>}


<form className='noShow'  ref={formRef} onSubmit={handleSubmit2}>
      <label>
        Name:
        <input
          type="text"
          name="to_name"
          value={formData.to_name}
          onChange={handleChange2}
        />
      </label>
      <label>
        Email:
        <input
          type="email"
          name="email_to"
          value={formData.email_to}
          onChange={handleChange2}
        />
      </label>

     
      <button type="submit" >Send</button>
    </form>
        </>

    )
}


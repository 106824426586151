import React from 'react'
import RoomsEditItem from './RoomsEditItem'
import { Link } from 'react-router-dom';

export default function RoomsEditItemList({ rooms, customer }) {

    return (
        <>
            <div className='checkListCont margintop'>
                {rooms.map(room =>

                    <RoomsEditItem key={room.id} customer={customer} rooms={room} {...room} />
                )}

                <div className='roomsEditCont2 greenBack3'>
                    <Link to={`/${customer}/dashboard/addroom`}>
                        <p className='addnewroomsEditCont-title '> + </p>
                        <p className='beds'> <b>NEW ROOM</b> </p>
                    </Link>
                </div>



                <div className='roomsEditCont2 greenBack3'>
                    <Link to={`/${customer}/dashboard/rooms/amenities`}>
                        <p className='beds '> <b>AMENITIES</b> </p>
                    </Link>
                </div>


            </div>
        </>

    )
}

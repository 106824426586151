import React, { useState, useEffect } from 'react'
import './Booking.css';
import { useLocation } from 'react-router-dom';

export default function BookOrderPage({ modal }) {

  const { search } = useLocation();

 


  const [bookOrder, setBookOrder] = useState([])
  const [show, setShow] = useState(false)
  const [paymentValidation, setPaymentValidation] = useState("second")

  useEffect(() => {
    setBookOrder(JSON.parse(sessionStorage.getItem('bookClient')))
    

    if(bookOrder.length !== 0) {
      setTimeout(() => {
        setShow(true)
      }, 100);
    }

  }, [modal])

  useEffect(() => {
    setPaymentValidation(search)
  }, [search])
  



  
  function printOrSave() {  
    window.print()
    
  }



  return (
    <>
{show === false &&  bookOrder.length === 0 ? <> </> : 

<div className='bookingsModal'>

    <div className='dashboardBookOrderWeek'>

    <p className='modalText'>¡Thank you, <b className='marginleft'>{bookOrder.client.name}</b>! </p>


<p className='modalText'> Confirmation will arrive by email. </p>

 <p className='modalText'>Please check your personal data. In case of any error, please notify us at this email:  email@gmail.com  </p>

    <div className='bookDataTop'>
        <div>
        <p  className='clientText'>
        {bookOrder.client.name.toUpperCase()}
        </p>
        </div>

        <div className='dateTextCont'>
        <p className='dateText in'>
            CHECK-IN: {bookOrder.from}
        </p>

        <p className='dateText out'>
        CHECK-OUT: {bookOrder.to}
        </p>
        </div>

    </div>

    <div className='dateData'>

            <p className='minitext'>
            <img className='modalItemsImg' src="https://img.icons8.com/material-rounded/96/000000/bedroom.png"/> {bookOrder.room}
            </p>

            <p className='minitext'>
            <img className='modalItemsImg' src="https://img.icons8.com/ios-glyphs/90/1A1A1A/conference-call--v1.png"/> {bookOrder.guests} GUESTS
            </p>

            <p className='minitext'>
            <img className='modalItemsImg' src="https://img.icons8.com/ios-glyphs/90/000000/money--v1.png"/> ${bookOrder.total.toLocaleString('de-DE')}
            </p>

    </div>

    <div className='dateData'>

     <p className='modalText modalMini'>{bookOrder.client.phone}</p>
      <p className='modalText modalMini'> {bookOrder.client.email}</p>          
    </div>

    <div>
<button className='printButton' onClick={printOrSave}> <img className='modalImg' src="https://img.icons8.com/ios-filled/100/FFFFFF/print.png"/> </button>

</div>
</div>

   
    
  
  
 </div>
}



</>
  )
}

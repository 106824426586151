import React, { useState, useEffect } from 'react'
import { getFirestore, collection, getDocs} from 'firebase/firestore';
import { LineWobble } from '@uiball/loaders'
import { Link } from 'react-router-dom';
import NavbarHomepage from './NavbarHomepage';
import ClientsItem from './ClientsItem';

export default function Clients() {

  const [loader, setLoader] = useState(true)
  const [images, setImages] = useState([])
  const db = getFirestore()

  useEffect(() => {
    setLoader(true)

    setTimeout(() => {
      setLoader(false)
    }, 1500);
  }, [images])

  useEffect(() => {

    const QueryCollection = collection(db, `holdear/homepage/clients`)

    getDocs(QueryCollection)
      .then(resp => setImages(resp.docs.map(site => ({ id: site.id, ...site.data() }))))
      .catch((err) => console.log(err))


  }, [])

  return (
    <>

      {loader === true ?
        <section>
          <div className='headerHome headergradient'>
            <NavbarHomepage />
            <LineWobble size={80} lineWeight={5} speed={1.75} color="white" />
          </div>
          <Link  to="/">
            <p className='logoMicrosite'>HOLDE <span className='logobackMicrosite'>.ar</span></p>

          </Link>
        </section>


        :

        <>
              <Link  to="/">
            <p className='logoMicrosite'>HOLDE <span className='logobackMicrosite'>.ar</span></p>

          </Link>
          <div className='headerHome headergradient'>
            <NavbarHomepage />
            <p className='sectionTittle'>Nuestros Holders</p>
            <p className='minitextnavbarHome '>Automatizá procesos. Organizate mejor.</p>
          </div>

          <ClientsItem link="/viejonogal" img={images[0].viejonogal} orientation="row" tittle="El Viejo Nogal" text="Las cabañas ubicados en la `Quinta El Nono` es un lugar ideal para disfrutar de Tandil. Su minicasa container es la estrella del complejo." place="Tandil, Buenos Aires, Argentina." maps="https://www.google.com/maps/place/Tandil,+Provincia+de+Buenos+Aires/data=!4m2!3m1!1s0x95911f9b2733946f:0xceef2c76899c202d?sa=X&ved=2ahUKEwiKwta1j-35AhUhr5UCHTSnB4EQ8gF6BAgOEAE" />


          <div className='appDiv greenBack column'>

            <p className='headerTittle'>¡Quiero ser Holder!</p>

            <Link className='linkHome' to="/contactus">
              <p className='headerDetailText appButton circleButton'>+</p>
            </Link>


          </div>
        </>


      }

    </>
  )
}

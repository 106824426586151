import React, { useState, useEffect } from 'react'
import CheckInGuestDetail from './CheckInGuestDetail'
import { LineWobble } from '@uiball/loaders'


export default function CheckinRoomCard({ id, beds, enabled, roomname, name, roomSelection, guest1, guest2, guest3, guest4, guest5, guest6, guest7, guest8, guest9, guest10, checkout, rerender, rooms, customer }) {

    const [guestCheck, setGuestCheck] = useState(false)
    const [loader, setLoader] = useState(true)



    const roomSelectionF = () => {
        roomSelection(id)
    }

    useEffect(() => {

        setTimeout(() => {
            const guestsArr = [guest1[0].id, guest2[0].id, guest3[0].id, guest4[0].id, guest5[0].id, guest6[0].id, guest7[0].id, guest8[0].id, guest9[0].id, guest10[0].id]
            let guestArrCheck = guestsArr.every(e => e === 0)

            setGuestCheck(guestArrCheck)

        }, 100);


    }, [loader, rooms])


    const cards = new Promise((resolve, reject) => {
        setTimeout(() => { resolve([]); }, 1000);
    });

    cards.then(() => {
        setLoader(false);
    });

    function checkOutReRender() {
        setLoader(true)

        setTimeout(() => {
            setLoader(false)

        }, 1000);
    }


    return (

        <div className='checkinRoomCont'>
            <p className='checkinRoomCont-title'> <b> {id.toUpperCase()}</b> {roomname} </p>

            <p className='beds'> <img className='bedimg' src="https://img.icons8.com/ios-glyphs/90/000000/bed.png" alt="" /> <b className='bedtext' >{beds}</b></p>

            <div className='checkInGuestsCont'>
                {loader === true ?
                    <LineWobble
                        size={80}
                        lineWeight={5}
                        speed={1.75}
                        color={"#7D9D9C"}
                    />
                    : guestCheck === true ? <> <p className='roomempty'>Habitación vacía</p> </> :
                        <>
                            <p className='w100'> HUÉSPEDES </p>
                            <CheckInGuestDetail guest={guest1} guestid={"guest1"} checkout={checkout} idx={id} customer={customer} />
                            <CheckInGuestDetail guest={guest2} guestid={"guest2"} checkout={checkout} idx={id} customer={customer} />
                            <CheckInGuestDetail guest={guest3} guestid={"guest3"} checkout={checkout} idx={id} customer={customer} />
                            <CheckInGuestDetail guest={guest4} guestid={"guest4"} checkout={checkout} idx={id} customer={customer} />
                            <CheckInGuestDetail guest={guest5} guestid={"guest5"} checkout={checkout} idx={id} customer={customer} />
                            <CheckInGuestDetail guest={guest6} guestid={"guest6"} checkout={checkout} idx={id} customer={customer} />
                            <CheckInGuestDetail guest={guest7} guestid={"guest7"} checkout={checkout} idx={id} customer={customer} />
                            <CheckInGuestDetail guest={guest8} guestid={"guest8"} checkout={checkout} idx={id} customer={customer} />
                            <CheckInGuestDetail guest={guest9} guestid={"guest9"} checkout={checkout} idx={id} customer={customer} />
                            <CheckInGuestDetail guest={guest10} guestid={"guest10"} checkout={checkout} idx={id} customer={customer} />
                        </>
                }
            </div>

            <button className='newcheckinbutton' onClick={roomSelectionF}> CHECK-IN</button>

        </div>



    )
}

import React, { useState, useEffect } from 'react';
import DayEditCont from './DayEditCont';
import WeekCalendarModal from './WeekCalendarModal';


export default function WeekCalendarItem({ rooms, day, loopFilter, room1Months, room2Months, room3Months, year, month, todaysYear, room1MonthsNY, room2MonthsNY, room3MonthsNY, allDaysBooked, daysLoop, searchIn, daysInCurrentMonth, room4Months, room4MonthsNY, room5Months, room5MonthsNY, room6Months, room6MonthsNY, room7Months, room7MonthsNY, room8Months, room8MonthsNY, room9Months, room9MonthsNY, room10Months, room10MonthsNY, room11Months, room11MonthsNY, room12Months, room12MonthsNY, room13Months, room13MonthsNY, room14Months, room14MonthsNY, room15Months, room15MonthsNY, bookings }) {

    const [daysAvail, setDaysAvail] = useState()
    const [firstDay, setFirstDay] = useState("")
    const [thisDayName, setThisDayName] = useState("")
    const [month1, setMonth1] = useState()
    const [month2, setMonth2] = useState()
    const [month3, setMonth3] = useState()
    const [month4, setMonth4] = useState()
    const [month5, setMonth5] = useState()
    const [month6, setMonth6] = useState()
    const [month7, setMonth7] = useState()
    const [month8, setMonth8] = useState()
    const [month9, setMonth9] = useState()
    const [month10, setMonth10] = useState()
    const [month11, setMonth11] = useState()
    const [month12, setMonth12] = useState()
    const [month13, setMonth13] = useState()
    const [month14, setMonth14] = useState()
    const [month15, setMonth15] = useState()



    const [arrives, setArrives] = useState(false)
    const [departures, setDepartures] = useState(false)
    const [onlyDepartures, setOnlyDepartures] = useState(false)
    const [onlyArrivals, setOnlyArrivals] = useState(false)
    const [showBookingsModal, setShowBookingsModal] = useState(false)
    const [booksFromThisDay, setBooksFromThisDay] = useState([])
    const [departuresFromToday, setDeparturesFromToday] = useState([])
    const [loader, setLoader] = useState(false)

    const a = "d" + day
    const condition1 = year !== todaysYear

 

  

    useEffect(() => {

        // function setmf(a, b) {
        //     if (a.length != 0) {
        //         b(a.find(e => e.id == month))
        //     }
        // }

        // function availabilityFunction(a, b, c) {
        //     if (a != undefined) {
        //         b.push(a[c])
        //     }
        // }

        const a = "d" + day

        
        setTimeout(() => {
            if (condition1 === true) {

                const room1 = room1MonthsNY.find(e => e.id == month)
                const room2 = room2MonthsNY.length != 0 ? room2MonthsNY.find(e => e.id == month) : <></>
                const room3 = room3MonthsNY.length != 0 ? room3MonthsNY.find(e => e.id == month) : <></>
                const room4 = room4MonthsNY.length != 0 ? room4MonthsNY.find(e => e.id == month) : <></>
                const room5 = room5MonthsNY.length != 0 ? room5MonthsNY.find(e => e.id == month) : <></>
                const room6 = room6MonthsNY.length != 0 ? room6MonthsNY.find(e => e.id == month) : <></>
                const room7 = room7MonthsNY.length != 0 ? room7MonthsNY.find(e => e.id == month) : <></>
                const room8 = room8MonthsNY.length != 0 ? room8MonthsNY.find(e => e.id == month) : <></>
                const room9 = room9MonthsNY.length != 0 ? room9MonthsNY.find(e => e.id == month) : <></>
                const room10 = room10MonthsNY.length != 0 ? room10MonthsNY.find(e => e.id == month) : <></>
                const room11 = room11MonthsNY.length != 0 ? room11MonthsNY.find(e => e.id == month) : <></>
                const room12 = room12MonthsNY.length != 0 ? room12MonthsNY.find(e => e.id == month) : <></>
                const room13 = room13MonthsNY.length != 0 ? room13MonthsNY.find(e => e.id == month) : <></>
                const room14 = room14MonthsNY.length != 0 ? room14MonthsNY.find(e => e.id == month) : <></>
                const room15 = room15MonthsNY.length != 0 ? room15MonthsNY.find(e => e.id == month) : <></>

    
                const availability = [room1[a], room2[a], room3[a], room4[a], room5[a], room6[a], room7[a], room8[a], room9[a], room10[a], room11[a], room12[a], room13[a], room14[a], room15[a]]
                setDaysAvail(availability.filter(e => e != undefined).every(e => e === false || e === 0))

                setMonth1(room1)
                setMonth2(room2)
                setMonth3(room3)
                setMonth4(room4)
                setMonth5(room5)
                setMonth6(room6)
                setMonth7(room7)
                setMonth8(room8)
                setMonth9(room9)
                setMonth10(room10)
                setMonth11(room11)
                setMonth12(room12)
                setMonth13(room13)
                setMonth14(room14)
                setMonth15(room15)
            } else {

                const room1 = room1Months.find(e => e.id == month)
                const room2 = room2Months.length != 0 ? room2Months.find(e => e.id == month) : <></>
                const room3 = room3Months.length != 0 ? room3Months.find(e => e.id == month) : <></>
                const room4 = room4Months.length != 0 ? room4Months.find(e => e.id == month) : <></>
                const room5 = room5Months.length != 0 ? room5Months.find(e => e.id == month) : <></>
                const room6 = room6Months.length != 0 ? room6Months.find(e => e.id == month) : <></>
                const room7 = room7Months.length != 0 ? room7Months.find(e => e.id == month) : <></>
                const room8 = room8Months.length != 0 ? room8Months.find(e => e.id == month) : <></>
                const room9 = room9Months.length != 0 ? room9Months.find(e => e.id == month) : <></>
                const room10 = room10Months.length != 0 ? room10Months.find(e => e.id == month) : <></>
                const room11 = room11Months.length != 0 ? room11Months.find(e => e.id == month) : <></>
                const room12 = room12Months.length != 0 ? room12Months.find(e => e.id == month) : <></>
                const room13 = room13Months.length != 0 ? room13Months.find(e => e.id == month) : <></>
                const room14 = room14Months.length != 0 ? room14Months.find(e => e.id == month) : <></>
                const room15 = room15Months.length != 0 ? room15Months.find(e => e.id == month) : <></>



                const availability = [room1[a], room2[a], room3[a], room4[a], room5[a], room6[a], room7[a], room8[a], room9[a], room10[a], room11[a], room12[a], room13[a], room14[a], room15[a]]


                setDaysAvail(availability.filter(e => e != undefined).every(e => e === false || e === 0))

                setMonth1(room1)
                setMonth2(room2)
                setMonth3(room3)
                setMonth4(room4)
                setMonth5(room5)
                setMonth6(room6)
                setMonth7(room7)
                setMonth8(room8)
                setMonth9(room9)
                setMonth10(room10)
                setMonth11(room11)
                setMonth12(room12)
                setMonth13(room13)
                setMonth14(room14)
                setMonth15(room15)
            }


        }, 50);

    }, [room15Months, month, room15MonthsNY, loopFilter])



    useEffect(() => {
        const dayString = day + "/" + month + "/" + year
        const arriveCheck = bookings.map(e => e.from === dayString && e.status === true)
        const bookExists = bookings.filter(e => e.from === dayString && e.status === true)

        if (arriveCheck.some(e => e === true)) {
            setArrives(true)
            setBooksFromThisDay(bookExists)
        } else {
            setArrives(false)
        }
    }, [month, loopFilter, year])

    useEffect(() => {
        const dayString = day + "/" + month + "/" + year
        const arriveCheck = bookings.map(e => e.to === dayString && e.status === true)
        const bookExists = bookings.filter(e => e.to === dayString && e.status === true)

        if (arriveCheck.some(e => e === true)) {
            setDepartures(true)
            setDeparturesFromToday(bookExists)
        } else {
            setDepartures(false)
        }
    }, [month, loopFilter, year])

    const showBookings = () => {
        if (arrives === true || departures === true) {
            setShowBookingsModal(true)
        }

    }

    const checkArrivals = () => {
        setOnlyArrivals(true)
    }

    const checkDepartures = () => {
        setOnlyDepartures(true)
    }

    const closeModal = () => {
        setShowBookingsModal(false)
        setOnlyArrivals(false)
        setOnlyDepartures(false)
    }


    useEffect(() => {

        const yearf = year
        const monthf = month
        const firstDayF = new Date(yearf, monthf - 1, 1)
        const thisDay = new Date(yearf, monthf - 1, day)

        setFirstDay(firstDayF.toString()[0] + firstDayF.toString()[1] + firstDayF.toString()[2])

        setThisDayName(thisDay.toString()[0] + thisDay.toString()[1] + thisDay.toString()[2])
    }, [month, year])


    const MonthsArr = [month1, month2, month3, month4,month5,month6, month7, month8, month9,month10,month11,month12,month13,month14,month15]


 




    return (
        <>

            {loader === true ?

                <div className='weekDayCont'>

                </div>

                :

                <div className='weekDayCont'>

                    <p className='dayboxWeek'>
                        {thisDayName}  <b> {day}  </b>
                    </p>
                    <div>


                        {daysAvail === false || daysAvail === true ?
                            <>

                            {MonthsArr.map((e) =>  
                                                            
                                <>
                                {e.id != undefined ? <div className={e[a] === true ? 'weekRoomCont avail' : 'weekRoomCont notavail'} >
                                   <div > {e[a] === true ? <b> Disp</b> : <b> Sold </b>} </div>
                               </div>
                                   : <></>}
                               </>

                            
                                
                                )}
                            
                            </> : <></>
                        }


                    </div>

         

                    {arrives === true ?

                        <div onClick={() => {
                            showBookings(); checkArrivals()
                        }} className='guestArriving'>

                            <b> Arribos </b>

                        </div> : <> </>
                    }


                    {departures === true ?

                        <div onClick={() => {
                            showBookings(); checkDepartures()
                        }} className='departures'>

                            <b> Salidas </b>

                        </div> : <> </>
                    }

                




                </div>
            }

{showBookingsModal === true ?

<>


    <WeekCalendarModal closeModal={closeModal} booksFromThisDay={booksFromThisDay} departuresFromToday={departuresFromToday} arrivals={onlyArrivals} departures={onlyDepartures} />

</>
: <> </>
}

        </>



    )
}

import React from 'react'

export default function HomeAppItem({img, tittle, text, orientation, bg, plans}) {
  return (

    <div className={bg === "green " ? "greenBack" : ""} >
    <section className={orientation === "row" ? 'appDiv direction' : 'appDiv directionRev'}>
    <div className='imgDiv'>
      <img className='appImg' src={img} alt={tittle} />
    </div>

    <div className='textDiv'>
      <p className={plans === "true" ? "tittlePlans" : 'textTittle'}> {tittle} </p>
      <p className='textParagraph'> {text} </p>
    </div>
   </section>
   </div>
 
  )
}

import React, { useState, useEffect } from 'react';
import { setDoc, doc, collection, getFirestore, getDocs, writeBatch, where, query, documentId } from 'firebase/firestore';
import RoomsEditInput from './RoomsEditInput';
import RoomsEditImage from './RoomsEditImage';
import Backwardsbutton from '../../buttons/Backwardsbutton';
import { Link } from 'react-router-dom';
import AddRoomInput from './AddRoomInput';
import { LineWobble } from '@uiball/loaders'


export default function RoomsEditDetail({ customer, rooms, id, roomname, description, beds, type, enabled, price, size, img, img1, img2, img3, img4, img5, img6, img7, img8, img9, renderized, render, rerender }) {

  const db = getFirestore()
  const handleSubmit = (event) => {
    event.preventDefault();

  }




  const [NewId, setNewId] = useState(id)
  const [NewRoomname, setNewRoomname] = useState(roomname)
  const [NewDescription, setNewDescription] = useState(description)
  const [NewBeds, setNewBeds] = useState(beds)
  const [NewType, setNewType] = useState(type)
  const [NewEnabled, setNewEnabled] = useState(enabled)
  const [NewPrice, setNewPrice] = useState(price)
  const [NewSize, setNewSize] = useState(size)
  const [roomMonthsNY, setRoomMonthsNY] = useState([])
  const [roomMonths, setRoomMonths] = useState([])
  const [roomMonthsNYsorted, setRoomMonthsNYsorted] = useState([])
  const [roomMonthssorted, setRoomMonthssorted] = useState([])
  const [openMonthEditorSwitch, setOpenMonthEditorSwitch] = useState(false)
  const [thisyear, setThisyear] = useState()
  const [year, setYear] = useState()
  const [month, setMonth] = useState()
  const [reloadImageLinks, setReloadImageLinks] = useState(false)
  const [loader, setLoader] = useState(true)


  useEffect(() => {
    setLoader(true)

    setTimeout(() => {
      setLoader(false)
    }, 2000);
  }, [])





  useEffect(() => {
    const date = new Date();
    setYear(date.getFullYear())
    setThisyear(date.getFullYear())
  }, [])

  const openMonthEditor = () => {
    if (openMonthEditorSwitch === true) {
      setOpenMonthEditorSwitch(false)
    } else {
      setOpenMonthEditorSwitch(true)
    }
  }
  const getMonths = () => {
    function saveMonths(a, set) {
      const queryCol = collection(db, `clients/${customer}/rooms/${id}/${a}`)

      getDocs(queryCol)
        .then(resp => set(resp.docs.map(room => ({ id: room.id, ...room.data() }))))
        .catch((err) => console.log(err))
    }

    saveMonths(year, setRoomMonths)
    saveMonths((year + 1), setRoomMonthsNY)
  }

  useEffect(() => {
    setRoomMonthsNYsorted(roomMonthsNY.map(e => e.id).sort((a, b) => a - b))
    setRoomMonthssorted(roomMonths.map(e => e.id).sort((a, b) => a - b))
  }, [roomMonthsNY, renderized])

  const addMonth = () => {// This function adds the month to the calendar in firebase.

    render()
    if ((type === "compartida" || type === "parcela")&& (month == 1 || month == 3 || month == 5 || month == 7 || month == 8 || month == 10 || month == 12)) {

      setTimeout(() => {
        const roomRef = doc(db, `clients/${customer}/rooms`, id)

        setDoc(doc(roomRef, year, month), {
          "d1": beds, "d2": beds, "d3": beds, "d4": beds, "d5": beds, "d6": beds, "d7": beds, "d8": beds, "d9": beds, "d10": beds, "d11": beds, "d12": beds, "d13": beds, "d14": beds, "d15": beds, "d16": beds, "d17": beds, "d18": beds, "d19": beds, "d20": beds, "d21": beds, "d22": beds, "d23": beds, "d24": beds, "d25": beds, "d26": beds, "d27": beds, "d28": beds, "d29": beds, "d30": beds, "d31": beds
        }
        )
      }, 1000);
    } if ((type === "compartida" || type === "parcela") && (month == 4 || month == 6 || month == 9 || month == 11)) {
      setTimeout(() => {
        const roomRef = doc(db, `clients/${customer}/rooms`, id)

        setDoc(doc(roomRef, year, month), {
          "d1": beds, "d2": beds, "d3": beds, "d4": beds, "d5": beds, "d6": beds, "d7": beds, "d8": beds, "d9": beds, "d10": beds, "d11": beds, "d12": beds, "d13": beds, "d14": beds, "d15": beds, "d16": beds, "d17": beds, "d18": beds, "d19": beds, "d20": beds, "d21": beds, "d22": beds, "d23": beds, "d24": beds, "d25": beds, "d26": beds, "d27": beds, "d28": beds, "d29": beds, "d30": beds
        }
        )
      }, 1000);
    } if ((type === "compartida" || type === "parcela") && month == 2) {
      setTimeout(() => {
        const roomRef = doc(db, `clients/${customer}/rooms`, id)

        setDoc(doc(roomRef, year, month), {
          "d1": beds, "d2": beds, "d3": beds, "d4": beds, "d5": beds, "d6": beds, "d7": beds, "d8": beds, "d9": beds, "d10": beds, "d11": beds, "d12": beds, "d13": beds, "d14": beds, "d15": beds, "d16": beds, "d17": beds, "d18": beds, "d19": beds, "d20": beds, "d21": beds, "d22": beds, "d23": beds, "d24": beds, "d25": beds, "d26": beds, "d27": beds, "d28": beds, "d29": beds
        }
        )
      }, 1000);
    }
    if ((type !== "compartida" && type !== "parcela") && (month == 1 || month == 3 || month == 5 || month == 7 || month == 8 || month == 10 || month == 12)) {

      setTimeout(() => {
        const roomRef = doc(db, `clients/${customer}/rooms`, id)

        setDoc(doc(roomRef, year, month), {
          "d1": true, "d2": true, "d3": true, "d4": true, "d5": true, "d6": true, "d7": true, "d8": true, "d9": true, "d10": true, "d11": true, "d12": true, "d13": true, "d14": true, "d15": true, "d16": true, "d17": true, "d18": true, "d19": true, "d20": true, "d21": true, "d22": true, "d23": true, "d24": true, "d25": true, "d26": true, "d27": true, "d28": true, "d29": true, "d30": true, "d31": true
        }
        )
      }, 1000);
    } if ((type !== "compartida" && type !== "parcela") && (month == 4 || month == 6 || month == 9 || month == 11)) {
      setTimeout(() => {
        const roomRef = doc(db, `clients/${customer}/rooms`, id)

        setDoc(doc(roomRef, year, month), {
          "d1": true, "d2": true, "d3": true, "d4": true, "d5": true, "d6": true, "d7": true, "d8": true, "d9": true, "d10": true, "d11": true, "d12": true, "d13": true, "d14": true, "d15": true, "d16": true, "d17": true, "d18": true, "d19": true, "d20": true, "d21": true, "d22": true, "d23": true, "d24": true, "d25": true, "d26": true, "d27": true, "d28": true, "d29": true, "d30": true
        }
        )
      }, 1000);
    } if ((type !== "compartida" && type !== "parcela") && month == 2 && year != 2024 && year != 2028 && year != 2032 && year != 2036) {
      setTimeout(() => {
        const roomRef = doc(db, `clients/${customer}/rooms`, id)

        setDoc(doc(roomRef, year, month), {
          "d1": true, "d2": true, "d3": true, "d4": true, "d5": true, "d6": true, "d7": true, "d8": true, "d9": true, "d10": true, "d11": true, "d12": true, "d13": true, "d14": true, "d15": true, "d16": true, "d17": true, "d18": true, "d19": true, "d20": true, "d21": true, "d22": true, "d23": true, "d24": true, "d25": true, "d26": true, "d27": true, "d28": true, "d29": true
        }
        )
      }, 1000);
    } if ((type !== "compartida" && type !== "parcela") && month == 2 && (year == 2024 || year == 2028 || year == 2032 || year == 2036)) {
      setTimeout(() => {
        const roomRef = doc(db, `clients/${customer}/rooms`, id)

        setDoc(doc(roomRef, year, month), {
          "d1": true, "d2": true, "d3": true, "d4": true, "d5": true, "d6": true, "d7": true, "d8": true, "d9": true, "d10": true, "d11": true, "d12": true, "d13": true, "d14": true, "d15": true, "d16": true, "d17": true, "d18": true, "d19": true, "d20": true, "d21": true, "d22": true, "d23": true, "d24": true, "d25": true, "d26": true, "d27": true, "d28": true
        }
        )
      }, 1000);

    }
  }



  const roomEdit = [id]


  const editProp = async (a, b) => {

    const batch = writeBatch(db)

    const queryCol = collection(db, `clients/${customer}/rooms`)
    const queryRooms = await query(
      queryCol,
      where(documentId(), 'in', roomEdit.map(e => e)))

    await getDocs(queryRooms)
      .then(resp => resp.docs.forEach(((res) => {
        batch.update(res.ref, { [a]: b })
      })))

    await batch.commit()

    render()
  }



  return (

    <>

      <div className='column center'>
        <Link className='link navbarList' to={`/${customer}/dashboard/rooms`}>
          <Backwardsbutton text="⬸ Regresar" />
        </Link>


        {loader === true ? <div className='weekcalendarloader'>
          <LineWobble
            size={80}
            lineWeight={5}
            speed={1.75}
            color="#576F72" />
        </div> :
          <>
            <div className='roomeditCard'>

              <form className='RoomEditform' action="" onSubmit={handleSubmit}>
                <p className='roomsedittitle'> {roomname} </p>


                <RoomsEditInput name="nombre" type="text" data={roomname} text={roomname} onchange={(e) => setNewRoomname(e.target.value)} edit={editProp} newData={NewRoomname} prop="roomname" />

                <RoomsEditInput name="descripción" type="text" data={description} text={description} onchange={(e) => setNewDescription(e.target.value)} edit={editProp} newData={NewDescription} prop="description" />

                <RoomsEditInput name="Camas/parcelas" type="text" data={beds} text={beds} onchange={(e) => setNewBeds(e.target.value)} edit={editProp} newData={NewBeds} prop="beds" />

                <RoomsEditInput name="Tipo" type="text" data={type} text={type} onchange={(e) => setNewType(e.target.value)} edit={editProp} newData={NewType} prop="type" />

                <RoomsEditInput name="Habilitado (true/false)" type="boolean" data={enabled} text={enabled} onchange={(e) => setNewEnabled(e.target.value)} edit={editProp} newData={NewEnabled} prop="enabled" options={true} />

                <RoomsEditInput name="Precio" type="number" data={price} text={price} onchange={(e) => setNewPrice(e.target.value)} edit={editProp} newData={NewPrice} prop="price" />

                <RoomsEditInput name="Tamaño" type="number" data={size} text={size} onchange={(e) => setNewSize(e.target.value)} edit={editProp} newData={NewSize} prop="size" />

                <button className='roomsEditCont-title buttonMonths' onClick={() => {
                  openMonthEditor(); getMonths()
                }}>HABILITAR NUEVO MES EN EL CALENDARIO</button>
                {openMonthEditorSwitch === false ? <></> :
                  <div>
                    <AddRoomInput type="number" name="año" place="Año" onchange={(e) => setYear(e.target.value)} />
                    <AddRoomInput type="number" name="mes" place="Mes expresado en número" onchange={(e) => setMonth(e.target.value)} />

                    <button className="addRoomButton" onClick={() => {
                      addMonth()
                    }}> Agregar mes </button>
                    <p className='roomsEditCont-title'>MESES HABILITADOS</p>
                    <div>
                      <p className='roomsEditCont-title buttonMonths'>{thisyear}</p>
                      <div className='row'>
                        {roomMonths.length > 0 ?
                          <>
                            {roomMonthssorted.map((e) =>
                              <p className='monthItem'> {e} </p>
                            ).sort((a, b) => a - b)}
                          </> : <></>
                        }
                      </div>

                      <div>
                        <p className='roomsEditCont-title buttonMonths'>{thisyear + 1}</p>
                        <div className='row'>
                          {roomMonthsNY.length > 0 ?
                            <>
                              {roomMonthsNYsorted.map((e) =>
                                <p className='monthItem'> {e} </p>
                              ).sort((a, b) => a - b)}
                            </> : <></>
                          }
                        </div>
                      </div>
                    </div>
                  </div>}
              </form>


            </div>

            <div className='RoomsEditImgCont'>

              <RoomsEditImage prop="img" roomname={roomname} roomid={id} image={img} customer={customer} edit={editProp} rerender={rerender} />

              {img != null || img != undefined ? <RoomsEditImage prop="img1" roomname={roomname} roomid={id} image={img1} customer={customer} edit={editProp} rerender={rerender} /> : <></>}

              {img1 != null || img1 != undefined ? <RoomsEditImage prop="img2" roomname={roomname} roomid={id} image={img2} customer={customer} edit={editProp} rerender={rerender} /> : <></>}

              {img2 != null || img2 != undefined ? <RoomsEditImage prop="img3" roomname={roomname} roomid={id} image={img3} customer={customer} edit={editProp} rerender={rerender} /> : <></>}
              {img3 != null || img3 != undefined ? <RoomsEditImage prop="img4" roomname={roomname} roomid={id} image={img4} customer={customer} edit={editProp} rerender={rerender} /> : <></>}
              {img4 != null || img4 != undefined ? <RoomsEditImage prop="img5" roomname={roomname} roomid={id} image={img5} customer={customer} edit={editProp} rerender={rerender} /> : <></>}
              {img5 != null || img5 != undefined ? <RoomsEditImage prop="img6" roomname={roomname} roomid={id} image={img6} customer={customer} edit={editProp} rerender={rerender} /> : <></>}
              {img6 != null || img6 != undefined ? <RoomsEditImage prop="img7" roomname={roomname} roomid={id} image={img7} customer={customer} edit={editProp} rerender={rerender} /> : <></>}
              {/* {img7 !== null || img7 !== undefined ?                     <RoomsEditImage prop="img8" roomid={id} image={img8} customer={customer} edit={editProp} rerender={rerender} openGallery={openGalleryF}/>  : <></> }       */}



            </div>

          </>

        }

      </div>
    </>
  )
}

import React from 'react';
import { Link } from 'react-router-dom';

export default function NavbarMasterItem({ route, src, text, minitext }) {

 
  return (
    <>

          <Link className='link navbarListMaster' to={route}>
            <img className='navbarIconMaster' src={src} />
            <p className='minitextnavbarMaster' > {text} </p>
          </Link>
        </>



  )
}

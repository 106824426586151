// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBKyih9McWjZ69AQIrsFdesddyQzq-nqNs",
  authDomain: "holdear-9a112.firebaseapp.com",
  projectId: "holdear-9a112",
  storageBucket: "holdear-9a112.appspot.com",
  messagingSenderId: "663262864022",
  appId: "1:663262864022:web:85676bbba82f6e1862d8b7",
  measurementId: "G-6TPD77YSBM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


export default function getFirestoreApp() {
    return app
}
import React, { useState, useEffect } from 'react';
import { setDoc, doc, collection, getFirestore, getDocs } from 'firebase/firestore';
import { useParams, useLocation } from 'react-router-dom';
import NavbarPanel from '../NavbarPanel';
import RoomsEditInput from '../roomsEdit/RoomsEditInput';
import { useLoginContext } from '../../context/LoginContext'
import Login from '../login/Login';
import { LineWobble } from '@uiball/loaders'
import { Link } from 'react-router-dom';

export default function PaymentsCont() {
    const db = getFirestore()
    const { customer, roomedit } = useParams()

    const [paymentData, setPaymentData] = useState([])
    const [conditiondata, setConditiondata] = useState()

    const [showBankData, setShowBankData] = useState()
    const location = useLocation()

    const [name, setName] = useState()
    const [cbu, setCbu] = useState()
    const [alias, setAlias] = useState()
    const [bankname, setBankname] = useState()

    const [condition, setCondition] = useState()

    const [newname, setNewname] = useState(name)
    const [newcbu, setNewcbu] = useState(cbu)
    const [newalias, setNewalias] = useState(alias)
    const [newbankname, setNewbankname] = useState(bankname)
    const { loginCheck, planCheck } = useLoginContext()


    const [loader, setLoader] = useState(true)


    const loaderPromise = new Promise((resolve, reject) => {
        setTimeout(() => { resolve([]); }, 2000);
    });

    loaderPromise.then(() => {
        setLoader(false);
    });

    useEffect(() => {
        const queryCol = collection(db, `clients/${customer}/payments`)

        getDocs(queryCol)
            .then(resp => setPaymentData(resp.docs.map(bdata => ({ id: bdata.id, ...bdata.data() }))))
            .catch((err) => console.log(err))
    }, [customer, conditiondata])


    const dataLoad = (a) => {
        return new Promise((resolve,reject) => {
            if(a === undefined || a === []) {
                reject()
            } else {
                resolve()
            }
        })
    }
    

    useEffect(() => {



        const bankData = paymentData.find(pay => pay.id == "bank")
        const conditionData = paymentData.find(pay => pay.id == "condition")

        dataLoad(conditionData).then(() => {

            setConditiondata(conditionData.condition)
        })
        .catch(error => error)

        dataLoad(bankData).then(() => {
            setName(bankData.name)
            setCbu(bankData.cbu)
            setAlias(bankData.alias)
            setBankname(bankData.bankname)

        })
        .catch(error => error)
  

    }, [paymentData])



    const showBankDataF = () => {
        if (showBankData === true) {
            setShowBankData(false)
        } else {
            setShowBankData(true)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
    }



    const editProp = async (a, b) => {

        setDoc(doc(db, `clients`, `${customer}`, "payments", "bank"), {
            name: newname === undefined ? name : newname,
            cbu: newcbu === undefined ? cbu : newcbu,
            alias: newalias === undefined ? alias : newalias,
            bankname: newbankname === undefined ? bankname : newbankname

        })

    }

    const chooseCondition = (a) => {
        setCondition(a)
    }

    const setPaymentCondition = async (a) => {

        setDoc(doc(db, `clients`, `${customer}`, "payments", "condition"), {
            condition: condition
        })

        setTimeout(() => {
            window.location.reload(true)
        }, 2000);
    }





    const [loginAnimation, setLoginAnimation] = useState(false)

    useEffect(() => {
        setLoginAnimation(true)

        setTimeout(() => {
            setLoginAnimation(false)


        }, 2000);
    }, [])


    return (
        <>
            {loginCheck != true ?

                <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>
                    <p className='logoMicrositeLoading' >HOLDE <span className='logobackMicrosite'>.ar</span></p>

                    <Login customer={customer} />
                </div>
                :
                <>
                    <div className='dashboardCont'>
                        <NavbarPanel customer={customer} />
                        <p className='sectionTittles'>| PAYMENTS ROOM </p>

                        {loader === true ? <div className='weekcalendarloader'>
                            <LineWobble
                                size={80}
                                lineWeight={5}
                                speed={1.75}
                                color="#576F72" />
                        </div> :

                            <>


                                <div className='paymentscont'>
                                    <p className='mercadopagoTittle'>Elegí el método de pago que quieres activar</p>
                                    <div className='row center'>

                                        {planCheck.basic === false ?
                                            <div className={showBankData === true ? "displaynone" : 'paymentItem column'}>
                                                <p className='paymentsText green'>Mejorá tu plan para empezar a cobrar con Mercadopago.</p>
                                                <img className='checkOutImgSmall ' src="https://www.mgscreativa.com/images/stories/virtuemart/product/logo-mercadopago9.png" alt="" />
                                                <Link className='linkArrivals' to={`/${customer}/dashboard/plans`}>
                                                <p className='minitextLink' > NUESTROS PLANES</p>
                                            </Link>
                                            </div>

                                            : <>

                                                <div className={showBankData === true ? "displaynone" : 'paymentItem'}>
                                                    <a href={`https://auth.mercadopago.com.ar/authorization?client_id=2085148632588929&response_type=code&platform_id=mp&state=${customer}&redirect_uri=https://holde.ar/payments`} className="ButtonNone" >
                                                        <img className='checkOutImgSmall' src="https://www.mgscreativa.com/images/stories/virtuemart/product/logo-mercadopago9.png" alt="" />
                                                    </a>
                                                </div>
                                            </>}


                                        <div className={showBankData === true ? 'paymentItem column w100' : "paymentItem column"} >
                                            <button onClick={showBankDataF} className="ButtonNone" >
                                                <img className='checkOutImgSmall' src="https://img.icons8.com/ios-glyphs/90/000000/museum.png" alt="" />
                                            </button>

                                            <form className={showBankData === true ? 'RoomEditform' : "displaynone"} action="" onSubmit={handleSubmit}>
                                                <RoomsEditInput name="name" type="text" data={name} text={name} onchange={(e) => setNewname(e.target.value)} edit={editProp} newData={newname} prop="name" />
                                                <RoomsEditInput name="cbu" type="text" data={cbu} text={cbu} onchange={(e) => setNewcbu(e.target.value)} edit={editProp} newData={newcbu} prop="cbu" />
                                                <RoomsEditInput name="alias" type="text" data={alias} text={alias} onchange={(e) => setNewalias(e.target.value)} edit={editProp} newData={newalias} prop="alias" />
                                                <RoomsEditInput name="bankname" type="text" data={bankname} text={bankname} onchange={(e) => setNewbankname(e.target.value)} edit={editProp} newData={newbankname} prop="bankname" />
                                            </form>
                                        </div>

                                    </div>
                                </div>



                                <div className='paymentscont'>
                                    <p className='mercadopagoTittle'> Elegí cómo cobrar </p>

                                    {planCheck.basic === false ?
                                        <div className={showBankData === true ? "displaynone" : 'paymentItem column'}>
                                            <p className='paymentsText green'>Mejorá tu plan y elegí cómo cobrar anticipos de reservas.</p>
                                            <img className='checkOutImg' src="https://firebasestorage.googleapis.com/v0/b/holdear-9a112.appspot.com/o/homepage%2Fhomepage%2Fplans%2Fundraw_upgrade_re_gano.svg?alt=media&token=e83187fa-7d74-4a52-8edc-67e1d9d3ea1a" alt="" />
                                            <Link className='linkArrivals' to={`/${customer}/dashboard/plans`}>
                                                <p className='minitextLink' > NUESTROS PLANES</p>
                                            </Link>


                                        </div>

                                        : <>

                                            <div className='paymentItem column w50'>

                                                <p className='paymentsText'>Seleccioná entre estas opciones <b>el porcentaje</b> que deseás cobrarle a tus huéspedes al momento de la reserva.</p>
                                                <div className='row center flexwrap'>

                                                    <button className={conditiondata === null ? 'paybutton paybuttonselected' : 'paybutton'} onClick={() => {
                                                        chooseCondition(null)
                                                    }}>
                                                        0%
                                                    </button>

                                                    <button className={conditiondata === 10 ? 'paybutton paybuttonselected' : 'paybutton'} onClick={() => {
                                                        chooseCondition(10)
                                                    }}>
                                                        10%
                                                    </button>

                                                    <button className={conditiondata === 4 ? 'paybutton paybuttonselected' : 'paybutton'} onClick={() => {
                                                        chooseCondition(4)
                                                    }}>
                                                        25%
                                                    </button>

                                                    <button className={conditiondata === 2 ? 'paybutton paybuttonselected' : 'paybutton'} onClick={() => {
                                                        chooseCondition(2)
                                                    }}>
                                                        50%
                                                    </button>

                                                    <button className={conditiondata === 1 ? 'paybutton paybuttonselected' : 'paybutton'} onClick={() => {
                                                        chooseCondition(1)
                                                    }}>
                                                        100%
                                                    </button>
                                                </div>

                                                <button className='editButtonText margintop' onClick={() => {
                                                    setPaymentCondition()
                                                }}>Confirmar</button>

                                            </div>
                                        </>}



                                </div>

                                {planCheck.basic === false ?

                                    <div className='ww40 mercadopagoTittle'>
                                        <iframe className='newPlanVideo' src="https://www.youtube.com/embed/pj9Nqr0Y30U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>

                                    : <></>}

                            </>}






                    </div>


                </>} </>
    )
}

import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import { Link } from 'react-router-dom';
import { LineWobble } from '@uiball/loaders'
import RoomsStatusItem from './RoomsStatusItem';


export default function RoomsStatus({ rooms, customer }) {
    const [loader, setLoader] = useState(true)


    useEffect(() => {
        setTimeout(() => {
            setLoader(false)
        }, 1000);
    }, [])


    return (
        <div className='DashboardRoomsCont'>
            <div className='row'>
                <p className='DashboardTitles'> Resumen de habitaciones</p>

                <Link className='linkArrivals' to={`/${customer}/dashboard/checkin`}>
                    <p className='minitextLink' > Check-In/Out</p>
                </Link>
            </div>
            {loader === true ? <div className='dashboardloader'>
                <LineWobble
                    size={80}
                    lineWeight={5}
                    speed={1.75}
                    color="#576F72" />
            </div> :
                <>
                    <div className='ArrivalsItemCont'>
                        { rooms.length != 0 ? <> {rooms.map((room) =>
                            <RoomsStatusItem key={room.id} {...room} />

                        )} </>  : <></> }
                        </div> </>
            }

        </div>
    )
}


import React from 'react'
import Comment from './Comment'
import { LineWobble } from '@uiball/loaders'

export default function CommentsList({ comments, dashboard, customer, changed }) {


  const commentFilter = dashboard === true ? comments : comments.filter(e => e.state === true)

  return (
    <>

      {commentFilter.length === 0 ?
        <>
          {dashboard === true ? <LineWobble
            size={80}
            lineWeight={5}
            speed={1.75}
            color="#576F72" /> : <p className='ourGuests'>¡Este espacio está reservado para vos!</p>}
        </>

        :

        <>
          {commentFilter.map((commentary) =>

            <Comment key={commentary.id} {...commentary} dashboard={dashboard} customer={customer} changed={changed} />
          )}


        </>
      }

    </>
  )
}

import React from 'react'
import BookOrderDashboard from './BookOrderDashboard'

export default function BookOrderDashboardList({
    bookings, search, rerender, customer
}) {


  const weektimeStamp = 1659571200
  // const daytimeStamp = (weektimeStamp/7)
  // const hourtimeStamp = ((weektimeStamp/7)/24)


  return (
    
    <>
    <div className='bookOrderDashboardlist'>
    

    {search.length === 0 ? 
    
    <>
            {bookings.sort(function (a, b) {
        return new Date(b.timestamp) - new Date(a.timestamp);
      }).filter( (a) => a.timestamp > (Date.now() - weektimeStamp) ).map((book) => <BookOrderDashboard key={book.id} {...book} bookings={bookings} rerender={rerender} customer={customer} />)  }
    </> : 
    
    
    
    <>
        {bookings.sort(function (a, b) {
        return new Date(b.timestamp) - new Date(a.timestamp);
      }).filter( a => a.client.name.toLowerCase().includes(search.toLowerCase())).map((book) => <BookOrderDashboard key={book.id} {...book} bookings={bookings} rerender={rerender} customer={customer} />)  }
    </>
    
    }

</div>
    </>
  )
}

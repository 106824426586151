import React, { useState, useEffect } from 'react'
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';


export default function GetNewPlan({ video, customer }) {

  const [images, setImages] = useState([])
  const db = getFirestore()


  useEffect(() => {

    const QueryCollection = collection(db, `holdear/homepage/app`)

    getDocs(QueryCollection)
      .then(resp => setImages(resp.docs.map(site => ({ id: site.id, ...site.data() }))))
      .catch((err) => console.log(err))


  }, [])


  return (


    <div className='newPlanDisplay center margintop'>
      <div className='w50'>
        {images.length != 0 ?

          <div className="column center" >

            <div className='imgDiv w100'>
              <img className='UyImg' src="https://firebasestorage.googleapis.com/v0/b/holdear-9a112.appspot.com/o/homepage%2Fhomepage%2Fplans%2Fundraw_upgrade_re_gano.svg?alt=media&token=e83187fa-7d74-4a52-8edc-67e1d9d3ea1a" alt="Upgrade your plan" />
            </div>

            <div className='textDiv w100'>
              <p className="textTittle"> ¡Uy! Parece que necesitás mejorar tu plan </p>
              <p className='textParagraph'> Pero no te preocupes, porque son <b>muy accesibles</b>. Pero antes mirá en el video de acá al lado cómo funciona esta herramienta. </p>
              <Link className='linkArrivals' to={`/${customer}/dashboard/plans`}>
                <p className='minitextLink greenback2' > NUESTROS PLANES</p>
              </Link>


            </div>

          </div>

          : <></>}

      </div>

      <div className='w40'>
        <iframe className='newPlanVideo' src={video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>

    </div>
  )
}



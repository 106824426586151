import React, { useState, useEffect } from 'react';
import ArrivalsItem from './ArrivalsItem';
import './Dashboard.css';
import { Link } from 'react-router-dom';
import { LineWobble } from '@uiball/loaders'

export default function Arrivals({ bookings, today, todaysMonth, todaysYear, customer }) {

  const [booksFromThisDay, setBooksFromThisDay] = useState([])
  const [loader, setLoader] = useState(true)


  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 1000);
  }, [])

  useEffect(() => {

    if(bookings.length != 0 ) {
      let arrivalsToday = bookings.filter((e) => e.fullfrom.day == today && e.fullfrom.month == todaysMonth && e.fullfrom.year == todaysYear)
      setBooksFromThisDay(arrivalsToday)
    } 

  }, [bookings])

  return (
    <div className='DashboardSectionCont'>

      <div className='row'>
        <p className='DashboardTitles'>Arribos de hoy</p>
        <Link className='linkArrivals' to={`/${customer}/dashboard/bookcalendar`}>
          <p className='minitextLink' > Detalles</p>
        </Link>
      </div>



      {loader === true ? <div className='dashboardloader'>
        <LineWobble
          size={80}
          lineWeight={5}
          speed={1.75}
          color="#576F72" />
      </div> :

        <div className='ArrivalsItemCont'>

          {booksFromThisDay.length === 0 ?

            <p className='nonewdata'> Nadie llegará hoy </p> :
            <>       {booksFromThisDay.map((book) =>
              <ArrivalsItem key={book.id} {...book} />
            )}</>}

        </div>}


    </div>
  )
}

import React, { useState, useEffect } from 'react'
import RoomCards from '../rooms/RoomCards'

export default function OurRoomsList({ rooms, customer }) {


  const [roomsFilter, setRoomsFilter] = useState([])
  const [roomSelected, setRoomSelected] = useState([])
  const [room, setRoom] = useState(0)



  useEffect(() => {
    setRoomsFilter(rooms.filter(e => e.enabled === "true" || e.enabled === true))
  }, [rooms])



  useEffect(() => {
    setRoomSelected(roomsFilter[room])
  }, [roomsFilter, room])


  const nextRoom = () => {
    if (rooms.length > (room + 1)) {
      setRoom(room + 1)
    } else {
      setRoom(0)
    }
  }

  const prevRoom = () => {
    if (room != 0) {
      setRoom(room - 1)
    } else {
      setRoom(rooms.length - 1)
    }
  }


  return (
    <>
      <div className='ourRoomsCont'>

    {roomsFilter.length == 1 ? <></> : 
    <>
            <button className='ourRoomsButton ourRoomsbackButton' onClick={prevRoom}>〈<span className='galleryButtonText'>prev</span></button>
        <button className='ourRoomsButton ourRoomsforButton' onClick={nextRoom}><span className='galleryButtonText'>sig</span>〉</button>

    </>
    
    }

        {roomSelected != undefined ?
          <>

            <RoomCards id={roomSelected.id} img={roomSelected.img} price={roomSelected.price} type={roomSelected.type} roomname={roomSelected.roomname} description={roomSelected.description} size={roomSelected.size} beds={roomSelected.beds}

              microsite={true} customer={customer} forbook1={true} forbook2={true} forbook3={true} forbook4={true} forbook5={true} forbook6={true} forbook7={true} forbook8={true} forbook9={true} forbook10={true} forbook11={true} forbook12={true} forbook13={true} forbook14={true} forbook15={true} />
          </>
          :
          <></>
        }
      </div>
    </>
  )
}

import React, { useState, useEffect } from 'react';
import { addDoc, collection, getFirestore} from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { RaceBy } from '@uiball/loaders'


export default function NewComment({ newCommentModal, closeModal }) {
    const db = getFirestore()
    const { customer, roomedit } = useParams()

    const [loading, setLoading] = useState()
    const [loaded, setLoaded] = useState()
    const [newname, setNewname] = useState()
    const [newDateFrom, setNewDateFrom] = useState()
    const [newDateTo, setNewDateTo] = useState()
    const [newmessage, setNewmessage] = useState()
    const [newrating, setNewrating] = useState()


    const handleSubmit = (event) => {
        event.preventDefault();

    }

    const starone = () => {
        setNewrating(1)
    }

    const startwo = () => {
        setNewrating(2)
    }

    const starthree = () => {
        setNewrating(3)
    }
    const starfour = () => {
        setNewrating(4)
    }

    const starfive = () => {
        setNewrating(5)
    }
    const newComment = () => {

        setLoading(true)

        const newMessage = {}

        newMessage.name = newname
        newMessage.dateFrom = newDateFrom
        newMessage.dateTo = newDateTo
        newMessage.message = newmessage
        newMessage.rating = newrating
        newMessage.timestamp = Date.now()
        newMessage.state = false

        const queryCollection = collection(db, `clients/${customer}/comments`)
        addDoc(queryCollection, newMessage)
            .then(res => console.log(res))
            .catch(err => console.log(err))

        setTimeout(() => {
            setLoading(false)
            setLoaded(true)
            setTimeout(() => {
                closeModal()
            }, 2000);
        }, 2000);


    }

    return (
        <div className={newCommentModal === false ? "displayNone" : 'newcommentModal'} >
            <p className='newcommentClosebutton ' onClick={closeModal}>Cerrar</p>
            <p className='ourGuests'>Tu mensaje ayudará a nuestros futuros huéspedes</p>

            <form className='newCommentForm' action="" onSubmit={handleSubmit}>


                <div className='inputRoomEditLabelCont'>
                    <label className='labelRoomEdit' > Nombre </label>
                    <input className='newcommentinput' type="text" onChange={(e) => setNewname(e.target.value)} />
                </div>

                <div className='inputRoomEditLabelCont'>
                    <label className='labelRoomEdit'> Desde </label>
                    <input className='newcommentinput' type="date" onChange={(e) => setNewDateFrom(e.target.value)} />
                </div>

                <div className='inputRoomEditLabelCont'>
                    <label className='labelRoomEdit' > Hasta </label>
                    <input className='newcommentinput' type="date" onChange={(e) => setNewDateTo(e.target.value)} />
                </div>

                <div className='inputRoomEditLabelCont'>
                    <label className='labelRoomEdit' > Mensaje </label>
                    <textarea className='newcommentinput newcommenttextarea' type="date" onChange={(e) => setNewmessage(e.target.value)} />
                </div>


                <div className='inputRoomEditLabelCont'>
                    <label className='labelRoomEdit'> Puntaje </label>
                    <div className='starsGroup'>


                        <button className='star' onClick={starone}>★</button>
                        <button className='star' onClick={startwo}>★</button>
                        <button className='star' onClick={starthree}>★</button>
                        <button className='star' onClick={starfour}>★</button>
                        <button className='star' onClick={starfive}>★</button>
                    </div>
                </div>
                <button className='newcommentsubmitbutton' onClick={newComment}>

                    {loading === true ?

                        <RaceBy
                            size={80}
                            lineWeight={5}
                            speed={1.4}
                            color="white"
                        /> : loaded === true ? "¡Gracias!" : "Comentar"
                    }


                </button>
            </form>

        </div>
    )
}

import React, { useState, useEffect } from 'react'
import { useRoomsContext } from '../context/RoomsContext'

export default function RoomsGallery({ room, openClose, detail }) {

    const [picture, setPicture] = useState()
    const [changePic, setChangePic] = useState(1)
    const [thisRoom, setThisRoom] = useState()

    const { rooms } = useRoomsContext()

    useEffect(() => {
        const findRoom = rooms.find(e => e.id == room)


        setThisRoom(findRoom)

    }, [])

    useEffect(() => {
        setTimeout(() => {
            setPicture(thisRoom.img)
        }, 500);

    }, [thisRoom])



    const skipPic = () => {
        if (picture === thisRoom.img && thisRoom.img1 != null) {
            setPicture(thisRoom.img1)
            setChangePic(changePic + 1)
        } else if (picture === thisRoom.img1 && thisRoom.img2 != null) {
            setPicture(thisRoom.img2)
            setChangePic(changePic + 1)
        } else if (picture === thisRoom.img2 && thisRoom.img3 != null) {
            setPicture(thisRoom.img3)
            setChangePic(changePic + 1)
        } else if (picture === thisRoom.img3 && thisRoom.img4 != null) {
            setPicture(thisRoom.img4)
            setChangePic(changePic + 1)
        } else if (picture === thisRoom.img4 && thisRoom.img5 != null) {
            setPicture(thisRoom.img5)
            setChangePic(changePic + 1)
        } else if (picture === thisRoom.img5 && thisRoom.img6 != null) {
            setPicture(thisRoom.img6)
            setChangePic(changePic + 1)
        } else if (picture === thisRoom.img6 && thisRoom.img7 != null) {
            setPicture(thisRoom.img7)
            setChangePic(changePic + 1)
        } else if (picture === thisRoom.img7 && thisRoom.img8 != null) {
            setPicture(thisRoom.img8)
            setChangePic(changePic + 1)
        } else if (picture === thisRoom.img8 && thisRoom.img9 != null) {
            setPicture(thisRoom.img9)
            setChangePic(changePic + 1)
        } else if (picture === thisRoom.img9) {
            setPicture(thisRoom.img1)
            setChangePic(changePic + 1)
        } else {
            setPicture(thisRoom.img)
            setChangePic(changePic + 1)
        }
    }

    const backPic = () => {
        if (picture === thisRoom.img && thisRoom.img9 != null) {
            setPicture(thisRoom.img9)
            setChangePic(changePic + 1)
        } else if (picture === thisRoom.img1 && thisRoom.img != null) {
            setPicture(thisRoom.img)
            setChangePic(changePic + 1)
        } else if (picture === thisRoom.img2 && thisRoom.img1 != null) {
            setPicture(thisRoom.img1)
            setChangePic(changePic + 1)
        } else if (picture === thisRoom.img3 && thisRoom.img2 != null) {
            setPicture(thisRoom.img2)
            setChangePic(changePic + 1)
        } else if (picture === thisRoom.img4 && thisRoom.img3 != null) {
            setPicture(thisRoom.img3)
            setChangePic(changePic + 1)
        } else if (picture === thisRoom.img5 && thisRoom.img4 != null) {
            setPicture(thisRoom.img4)
            setChangePic(changePic + 1)
        } else if (picture === thisRoom.img6 && thisRoom.img5 != null) {
            setPicture(thisRoom.img5)
            setChangePic(changePic + 1)
        } else if (picture === thisRoom.img7 && thisRoom.img6 != null) {
            setPicture(thisRoom.img6)
            setChangePic(changePic + 1)
        } else if (picture === thisRoom.img8 && thisRoom.img7 != null) {
            setPicture(thisRoom.img7)
            setChangePic(changePic + 1)
        } else if (picture === thisRoom.img9 && thisRoom.img8 != null) {
            setPicture(thisRoom.img8)
            setChangePic(changePic + 1)
        } else if (picture === thisRoom.img9) {
            setPicture(thisRoom.img)
            setChangePic(changePic + 1)
        } else {
            setPicture(thisRoom.img)
            setChangePic(changePic + 1)
        }
    }


    return (
        <div className={detail === true ? 'galleryImgCont' : 'galleryImgCont w80'} >
            {detail === true ? <></> : <button className='openGalleryButton' onClick={openClose}> Cerrar </button>}


            <button className='galleryButton backButton buttonBack leftB' onClick={backPic}>〈<span className='galleryButtonText'></span></button>


            <button className='galleryButton forButton buttonBack rightB' onClick={skipPic}><span className='galleryButtonText'></span>〉</button>

            <img className='galleryImg' src={picture} alt="" />
        </div>
    )
}





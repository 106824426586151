import React, { useState, useEffect } from 'react';
import BookListCont from '../../booking/BookListCont'
import NavbarPanel from '../NavbarPanel'
import { useParams, useLocation } from 'react-router-dom';
import { useLoginContext } from '../../context/LoginContext'
import Login from '../login/Login';

export default function CalendarCont({ }) {

  const location = useLocation()
  const [adminAct, setAdminAct] = useState(false)
  const { customer } = useParams()
  const { loginCheck } = useLoginContext()




  useEffect(() => {
    if (location.pathname === "/dashboard/calendar") {
      setAdminAct(true)
    }
  }, [])


  const [loginAnimation, setLoginAnimation] = useState(false)

  useEffect(() => {
    setLoginAnimation(true)

    setTimeout(() => {
      setLoginAnimation(false)


    }, 2000);
  }, [])


  return (
    <>

      {loginCheck != true ?

        <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>
          <p className='logoMicrositeLoading' >HOLDE <span className='logobackMicrosite'>.ar</span></p>

          <Login customer={customer} />
        </div>
        :

        <>
          <div className='dashboardCont'>
            <NavbarPanel customer={customer} />
            <p className='sectionTittles'>| NEW BOOK</p>

            <div className='booklistcontAdmin '>
              <BookListCont admin={adminAct} customerId={customer} />
            </div>
          </div>
        </>

      }
    </>
  )
}

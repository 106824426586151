import React from 'react';

export default function ArrivalsItem({ client, guests, room }) {



    return (
        <div className='ArrivalsItem'>
            <p className='ArrivalsName'>{client.name}</p>
            <p className='minitext'>HUÉSPEDES: {guests}</p>
            <p className='minitext'>HABITACIÓN: {room} </p>

            <div className='ArrivalsButtonsCont'>
                <button className='actionButton' onClick={() => window.location = `mailto: ${client.email}`}>
                    <img className='actionImgArrivals' src="https://img.icons8.com/ios-filled/100/000000/new-post.png" />
                </button>

                <button className='actionButton' onClick={() => window.location = `https://wa.me/${Number(client.phone)}`}>
                    <img className='actionImgArrivals' src="https://img.icons8.com/ios-glyphs/240/000000/whatsapp.png" />
                </button>

                <button className='actionButton' onClick={() => window.location = `tel:${Number(client.phone)}`}>
                    <img className='actionImgArrivals' src="https://img.icons8.com/ios-glyphs/90/000000/phone-disconnected.png" />
                </button>
            </div>
        </div>

    )
}

import React from 'react'
import { Link } from 'react-router-dom';


export default function NewUsersItem({id}) {
  return (

   
    <Link className='newUserItem' to={`/${id}/`}>
    {id}
    </Link>
    
  )
}

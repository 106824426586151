import React, { useState, useEffect } from 'react'
import { getFirestore } from 'firebase/firestore';
import { useBookingContext } from '../../context/BookingContext'
import NavbarPanel from '../NavbarPanel';
import { useParams } from 'react-router-dom';
import { useLoginContext } from '../../context/LoginContext'
import Login from '../login/Login';
import AgendaItemList from './AgendaItemList';
import './Agenda.css';
import { LineWobble } from '@uiball/loaders'


export default function AgendaCont() {
    const db = getFirestore()
    const { customer } = useParams()
    const { contextBookings, bookings, rerender } = useBookingContext()
    const { loginCheck, planCheck  } = useLoginContext()

    useEffect(() => {
        contextBookings.push(customer)
    }, [])

    const [search, setSearch] = useState("")
    const [active, setActive] = useState(false)
    const [subject, setSubject] = useState("")
    const [newmessage, setNewmessage] = useState("")
    const [emailSelection, setEmailSeletcion] = useState([])
    const [newEmail, setNewEmail] = useState(false)


    const [repeated, setRepeated] = useState(false)
    const handleSubmit = (event) => {
        event.preventDefault();
    }

    useEffect(() => {
        setActive(true)
    }, [])


    const [loader, setLoader] = useState(false)

    useEffect(() => {
        setLoader(true)

        setTimeout(() => {
            setLoader(false)
        }, 2000);
    }, [])

    let allEmails = bookings.map(e => e.client.email)

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    let allEmailsFilter = allEmails.filter(onlyUnique)



    const copyToClipboard = () => {
        navigator.clipboard.writeText(`${allEmailsFilter}`)
    }




    const pushEmail = (a) => {
        const find = emailSelection.some(e => e == a)

        if (find === false) {
            emailSelection.push(a)
            rerender()
        } else {
            setRepeated(true)
            setTimeout(() => {
                setRepeated(false)
            }, 3000);
        }
    }

    const newEmailF = () => {
        if (newEmail === false) {
            setNewEmail(true)
        } else {
            setNewEmail(false)
        }
    }


    return (
        <>
            {loginCheck != true ?

                <div className={loader === true ? 'logincont loginlogoAnim' : 'logincont'}>
                    <p className='logoMicrositeLoading' >HOLDE <span className='logobackMicrosite'>.ar</span></p>

                    <Login customer={customer} />
                </div>
                :
                <>
                    <div className='dashboardCont'>
                        <NavbarPanel bookings={bookings} active={active} customer={customer} />

                        <p className='sectionTittles'>| AGENDA ROOM</p>


                        {loader === true ? <div className='weekcalendarloader'>
                            <LineWobble
                                size={80}
                                lineWeight={5}
                                speed={1.75}
                                color="#576F72" />
                        </div> :
                            <>
                                <div className='agendaSectionCont'>


                                    <div className='emailCont'>
                                        <p className='emailcontTittle'> MÁS CERCA DE TUS HUÉSPEDES</p>

                                        <div className='row center columnQuery'>
                                            <img className='emailImg' src="https://firebasestorage.googleapis.com/v0/b/holdear-9a112.appspot.com/o/homepage%2Fhomepage%2Fapp%2Fundraw_mailbox_re_dvds.svg?alt=media&token=99d39c14-f882-4ee5-ae49-5ffc483e6f84" alt="" />
                                            <div className='column center'>

                                                <p className='emailContText emailwhiteBack '>Enviá correos a tus contactos en dos clicks.</p>
                                                <p className='emailContText'>Esta función solo exporta al cuerpo del mail el texto plano (sin estilo), que podrá ser editado en el procesador de e-mails que utilice.</p>
                                                <p className='emailContText'>Tené en cuenta que GMAIL permite un envío de hasta 500 e-mails cada 24 horas.</p>

                                                <p className='emailContText'>Si querés un diseño exclusivo para tu newsletter, escribinos.</p>
                                            </div>
                                        </div>

                                        {/* FORM EMAIL */}
                                        {newEmail === false ?

                                            <div className='center row margintop'>

                                                <button className='newemailbutton' onClick={newEmailF}> Nuevo e-mail </button>
                                                <button className='newemailbutton' onClick={copyToClipboard}>Copiar todas las cuentas</button>

                                            </div>
                                            :

                                            <div className='column'>
                                                <div className='agendaInputCont'>
                                                    <p className='agendaLabel' > Asunto del email </p>
                                                    <input className='agendainput' type="text" onChange={(e) => setSubject(e.target.value)} />
                                                </div>



                                                <div className='agendaInputCont'>
                                                    <p className='agendaLabel' > Con copia oculta</p>
                                                    <div className='agendainput'>

                                                        {emailSelection != 0 ?

                                                            <>
                                                                {emailSelection.map(e =>

                                                                    <p className='emailsSelected'> {e[0]}{e[1]}{e[2]}{e[3]}{e[4]}{e[5]}{e[6]}{e[7]}{e[8]}..., </p>

                                                                )}
                                                            </>

                                                            : <></>}

                                                    </div>
                                                </div>

                                                <div className='agendaInputCont' >
                                                    <label className='agendaLabel moveUp' >Mensaje </label>
                                                    <textarea className='agendainput newcommenttextarea' type="date" onChange={(e) => setNewmessage(e.target.value)} />
                                                </div>

                                                <div className='marginauto'>
                                                    <a className='newemailbutton' href={`mailto:contacto@holde.ar?bcc=${emailSelection}&subject=${subject}&body=${newmessage}`} > Enviar </a>

                                                </div>

                                            </div>
                                        }



                                    </div>



                                    <AgendaItemList bookings={bookings} search={search} rerender={rerender} customer={customer} pushEmail={pushEmail} />

                                </div>
                            </>}



                        {repeated === true ?
                            <>
                                <div className='repeated'>
                                    El email ya está agregado
                                </div>
                            </>
                            : <></>}


                    </div>

                </>
            }
        </>
    )
}

import React, { useState, useEffect } from 'react';
import WeekCalendarItem from './WeekCalendarItem'

export default function WeekCalendarList({rooms, daysInCurrentMonth, monthDays, daysLoop, room1Months, room2Months,room3Months,m1, m2, m3, year, month, today, todaysMonth, todaysYear, room1MonthsNY, room2MonthsNY, room3MonthsNY, m1nextMonth, m2nextMonth, m3nextMonth, daysOfNextMonth, nextmonth, bookings, scroll, room4Months, m4, room4MonthsNY, room5Months, m5, room5MonthsNY, room6Months, m6, room6MonthsNY, room7Months, m7, room7MonthsNY, room8Months, m8, room8MonthsNY, room9Months, m9, room9MonthsNY, room10Months, m10, room10MonthsNY, room11Months, m11, room11MonthsNY, room12Months, m12, room12MonthsNY, room13Months, m13, room13MonthsNY, room14Months, m14, room14MonthsNY, room15Months, m15, room15MonthsNY}) {

    const [loopFilter, setLoopFilter] = useState([])

    useEffect(() => {
      if(scroll === 1) {
       return  setLoopFilter(daysLoop.filter(e => e < 16))
      } if (scroll === 2) {
        return setLoopFilter(daysLoop.filter(e => e > 15 && e <= 31))
      }
    }, [daysLoop, scroll])



  return (

    <>
    <div className='BookListWeek'>

   {loopFilter.map( (dayloop) => 
      <WeekCalendarItem key={dayloop} rooms={rooms}  day={dayloop} daysInCurrentMonth={daysInCurrentMonth} room1Months={room1Months} room2Months={room2Months} room3Months={room3Months} m1={m1} month={month} year={year} todaysYear={todaysYear} room1MonthsNY={room1MonthsNY} room2MonthsNY={room2MonthsNY} room3MonthsNY={room3MonthsNY} m1nextMonth={m1nextMonth} m2nextMonth={m2nextMonth} m3nextMonth={m3nextMonth} daysOfNextMonth={daysOfNextMonth} nextmonth={nextmonth} bookings={bookings} loopFilter={loopFilter} room4Months={room4Months} room4MonthsNY={room4MonthsNY} m4={m4}  
      room5Months={room5Months} room5MonthsNY={room5MonthsNY} m5={m5}  
      room6Months={room6Months} room6MonthsNY={room6MonthsNY} m6={m6}  
      room7Months={room7Months} room7MonthsNY={room7MonthsNY} m7={m7}  
      room8Months={room8Months} room8MonthsNY={room8MonthsNY} m8={m8}  
      room9Months={room9Months} room9MonthsNY={room9MonthsNY} m9={m9} 
      room10Months={room10Months} room10MonthsNY={room10MonthsNY} m10={m10} 
      room11Months={room11Months} room11MonthsNY={room11MonthsNY} m11={m11} 
      room12Months={room12Months} room12MonthsNY={room12MonthsNY} m12={m12} 
      room13Months={room13Months} room13MonthsNY={room13MonthsNY} m13={m13} 
      room14Months={room14Months} room14MonthsNY={room14MonthsNY} m14={m14} 
      room15Months={room15Months} room15MonthsNY={room15MonthsNY} m15={m15} />
    )} 


     </div>

    </>
  )
}

import React, { useState, useEffect } from 'react';
import { setDoc, doc, collection, getFirestore, getDocs, writeBatch, where, query, documentId } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { useRoomsContext } from '../../context/RoomsContext'
import NavbarPanel from '../NavbarPanel';
import { useLoginContext } from '../../context/LoginContext'
import Login from '../login/Login';
import { Link } from 'react-router-dom';
import RoomsEditInput from '../roomsEdit/RoomsEditInput';
import PricingButton from './PricingButton';
import GetNewPlan from '../plans/GetNewPlan';
import { LineWobble } from '@uiball/loaders'

export default function PricingCont() {


  const [loginAnimation, setLoginAnimation] = useState(false)
  const db = getFirestore()
  const { customer } = useParams()
  const { rooms, contextRooms, rerenderF } = useRoomsContext()
  const { loginCheck, planCheck } = useLoginContext()

  const [showRoomsEditor, setShowRoomsEditor] = useState(false)
  const [showExtras, setShowExtras] = useState(false)

  const [newPrice, setNewPrice] = useState()
  const [roomSelected, setRoomSelected] = useState([])
  const [render, setrender] = useState(false)
  const [loader, setLoader] = useState(true)

  const [paymentData, setPaymentData] = useState([])

  const [monday, setMonday] = useState()
  const [tuesday, setTuesday] = useState()
  const [wednesday, setWednesday] = useState()
  const [thursday, setThursday] = useState()
  const [friday, setFriday] = useState()
  const [saturday, setSaturday] = useState()
  const [sunday, setSunday] = useState()

  const [january, setJanuary] = useState()
  const [february, setFebruary] = useState()
  const [march, setMarch] = useState()
  const [april, setApril] = useState()
  const [may, setMay] = useState()
  const [june, setJune] = useState()
  const [july, setJuly] = useState()
  const [august, setAugust] = useState()
  const [september, setSeptember] = useState()
  const [october, setOctober] = useState()
  const [november, setNovember] = useState()
  const [december, setDecember] = useState()

  const [dayExtra, setDayExtra] = useState()
  const [monthExtra, setMonthExtra] = useState()
  const [condition, setCondition] = useState()

  const [openDayCondition, setOpenDayCondition] = useState(false)
  const [openMonthCondition, setOpenMonthCondition] = useState(false)

  const openDayConditionF = () => {
    if (openDayCondition === false) {
      setOpenDayCondition(true)
    } else {
      setOpenDayCondition(false)
    }
  }

  const openMonthConditionF = () => {
    if (openMonthCondition === false) {
      setOpenMonthCondition(true)
    } else {
      setOpenMonthCondition(false)
    }
  }

  useEffect(() => {
    const queryCol = collection(db, `clients/${customer}/payments`)

    getDocs(queryCol)
      .then(resp => setPaymentData(resp.docs.map(bdata => ({ id: bdata.id, ...bdata.data() }))))
      .catch((err) => console.log(err))
  }, [customer, render])


  const renderF = () => {
    if (render === true) {
      setrender(false)
    } else {
      setrender(true)
    }
  }
  useEffect(() => {
    contextRooms.push(customer)
  }, [render])

  useEffect(() => {
    setLoginAnimation(true)
    setTimeout(() => {
      setLoginAnimation(false)
    }, 2000);
  }, [])

  const loaderPromise = new Promise((resolve, reject) => {
    setTimeout(() => { resolve([]); }, 2000);
  });

  loaderPromise.then(() => {
    setLoader(false);
  });


  const handleSubmit = (event) => {
    event.preventDefault();
  }

  const showRooms = () => {
    if (showRoomsEditor === true) {
      setShowRoomsEditor(false)
    } else {
      setShowRoomsEditor(true)
    }
  }

  const showExtra = () => {
    if (showExtras === true) {
      setShowExtras(false)
    } else {
      setShowExtras(true)
    }
  }

  const setRoom = (a) => {
    setRoomSelected([a])
  }


  const editProp = async (a, b) => {

    const batch = writeBatch(db)

    const queryCol = collection(db, `clients/${customer}/rooms`)
    const queryRooms = await query(
      queryCol,
      where(documentId(), 'in', roomSelected.map(e => e)))

    await getDocs(queryRooms)
      .then(resp => resp.docs.forEach(((res) => {
        batch.update(res.ref, { [a]: b })
      })))

    await batch.commit()

    renderF()
  }

  const days = ["lunes", "martes", "miércoles", "jueves", "viernes", "sábado", "domingo"]
  const months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"]



  useEffect(() => {
    if (paymentData.find(e => e.id === "extraDays") != undefined) {
      let extraDayData = paymentData.find(e => e.id === "extraDays")
      let extraMonthData = paymentData.find(e => e.id === "extraMonths")
      let extraPricesData = paymentData.find(e => e.id === "extraPrices")

      setTimeout(() => {
        setMonday(extraDayData.mon)
        setTuesday(extraDayData.tue)
        setWednesday(extraDayData.wed)
        setThursday(extraDayData.thu)
        setFriday(extraDayData.fri)
        setSaturday(extraDayData.sat)
        setSunday(extraDayData.sun)

        setJanuary(extraMonthData.january)
        setFebruary(extraMonthData.february)
        setMarch(extraMonthData.march)
        setApril(extraMonthData.april)
        setMay(extraMonthData.may)
        setJune(extraMonthData.june)
        setJuly(extraMonthData.july)
        setAugust(extraMonthData.august)
        setSeptember(extraMonthData.september)
        setOctober(extraMonthData.october)
        setNovember(extraMonthData.november)
        setDecember(extraMonthData.december)

        setDayExtra(extraPricesData.days)
        setMonthExtra(extraPricesData.months)

      }, 100);
    }


  }, [paymentData])


  const pushSelection = async (a, b, c) => {
    let arr = a == "day" ? paymentData.find(e => e.id === "extraDays") : paymentData.find(e => e.id === "extraMonths")

    let arrId = a == "day" ? ["extraDays"] : ["extraMonths"]

    const batch = writeBatch(db)
    const queryCollection = collection(db, `clients/${customer}/payments`)

    const queryStatus = await query(
      queryCollection,
      where(documentId(), 'in', arrId.map((e) => e)))

    await getDocs(queryStatus)
      .then(resp => resp.docs.forEach(((res) => {

        batch.update(res.ref, { [b]: c })
      })))

    batch.commit()

    setTimeout(() => {
      renderF()

    }, 100);


  }


  // MODIFY  % OF PLUS OR DISCOUNT

  const chooseCondition = (a) => {
    setCondition(a)
  }


  const setPaymentCondition = async (a) => {

    setDoc(doc(db, `clients`, `${customer}`, "payments", "extraPrices"), {
      days: a == "days" ? condition : dayExtra,
      months: a == "months" ? condition : monthExtra
    })

    setTimeout(() => {
      window.location.reload(true)
    }, 2000);
  }

  function paymentDataLoaded(a) {
    return new Promise((resolve, reject) => {

      if(a === undefined || a.length === 0) {
        reject("Not ok")
      } else {
        resolve("ok")
      }
    } )
    
  }

  useEffect(() => {
    if(paymentData !== undefined && paymentData.length !== 0) {
      setStatus(paymentData.find(e => e.id === "bookCondition").condition)
    } else {

    }
  
  }, [paymentData])
  

  const [cancelled, setCancelled] = useState(false)
  const [cancelling, setCancelling] = useState(false)
  const [status, setStatus] = useState()
  const [resToggle, setResToggle] = useState(false)


  const toggleResStatus = () => {

    let condRes = status === true || status === undefined ? false : true

    setCancelling(true)

    setDoc(doc(db, `clients`, `${customer}`, "payments", "bookCondition"), {
      condition: condRes,
    })

    setTimeout(() => {
        setCancelled(true)
        setCancelling(false)


        setTimeout(() => {
          renderF()
        }, 100);
    }, 2000);
    


  }

  return (
    <>
      {loginCheck != true ?

        <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>
          <p className='logoMicrositeLoading' >HOLDE <span className='logobackMicrosite'>.ar</span></p>

          <Login customer={customer} />
        </div>
        :


        <>
          <div className='dashboardCont'>
            <NavbarPanel rooms={rooms} customer={customer} />
            <p className='sectionTittles'>| PRICING ROOM </p>


            {loader === true ? <div className='weekcalendarloader'>
              <LineWobble
                size={80}
                lineWeight={5}
                speed={1.75}
                color="#576F72" />
            </div> :
              <>

                {planCheck.basic === false ?
                  <GetNewPlan customer={customer} video="https://www.youtube.com/embed/bsHhGQ1aKmw" />

                  :

                  <>

                  <section className='dashboardCont center2'>
                    <div onClick={showRoomsEditor === false ? showRooms : undefined} className={showRoomsEditor === true ? 'paymentItem column relativePos wfitcont' : "paymentItem relativePos column"} >
                      <button className="ButtonNone" >
                        <p className='beds'> <b>HABITACIONES</b> </p>
                      </button>

                      <div>

                        {showRoomsEditor === true ?
                          <div className='marginTop'>
                            {rooms.map(e =>


                              <div key={e.id} onClick={() => {
                                setRoom(e.id)
                              }}>
                                <RoomsEditInput name={e.roomname} type="text" data={e.price} text={e.price} onchange={(e) => setNewPrice(e.target.value)} edit={editProp} newData={newPrice} prop="price" />
                              </div>
                            )}
                          </div> : <></>}
                        <button className={showRoomsEditor === false ? "displayNone" : 'closeButton'} onClick={() => {
                          showRooms()
                        }}>Cerrar</button>

                      </div>

                    </div>




                    <div onClick={showExtras === false ? showExtra : undefined} className={showExtras === true ? 'paymentItem column relativePos w50' : "paymentItem relativePos column"}>
                      <button className="ButtonNone" >
                        <p className='beds'> <b>EXTRAS</b> </p>
                        <p className={showExtras === true ? "displayNone" : 'minitext margintop2'}>Aumentos o descuentos por temporada alta o día de la semana.</p>
                      </button>

                      {paymentData.find(e => e.id === "extraDays") === undefined ? <></> :

                        <>

                          <p className={showExtras === true ? 'beds margintop2 pricingRed' : "displayNone"}>Los aumentos y descuentos son acumulables.</p>

                          <div className={showExtras === false ? "displayNone" : ""}>
                            <p className='margintop2'>Elegí los días con aumento ⇧, descuento ⇩ o precio base. </p>


                            <div className="disclosureDaysCont greyBack">
                              <p onClick={openDayConditionF} className={openDayCondition === false ? "minitext" : 'closeButtonDiscl'}>{openDayCondition === false ? "Modificar porcentaje" : 'Cerrar'}</p>
                              <div className={openDayCondition === false ? "displayNone" : 'column center '}>


                                <div className='row center flexwrap' >

                                  <button className={dayExtra === 10 ? 'paybutton paybuttonselected' : 'paybutton'} onClick={() => {
                                    chooseCondition(10)
                                  }}>    10% </button>

                                  <button className={dayExtra === 4 ? 'paybutton paybuttonselected' : 'paybutton'} onClick={() => {
                                    chooseCondition(4)
                                  }}>    25% </button>

                                  <button className={dayExtra === 2 ? 'paybutton paybuttonselected' : 'paybutton'} onClick={() => {
                                    chooseCondition(2)
                                  }}>    50%</button>

                                  <button className={dayExtra === 1 ? 'paybutton paybuttonselected' : 'paybutton'} onClick={() => {
                                    chooseCondition(1)
                                  }}>    100% </button>
                                </div>

                                <button className='editButtonText margintop2' onClick={() => {
                                  setPaymentCondition("days")
                                }}>Confirmar</button>
                              </div>
                            </div>



                            <div className='pricingButtonListCont'>
                              {
                                days.map(e =>


                                  <PricingButton key={e} text={e} pushSelection={pushSelection} type="day" paymentData={paymentData} />

                                )
                              }
                            </div>
                          </div>

                          <div className={showExtras === false ? "displayNone" : ""}>
                            <p className='margintop2'>Elegí los meses con aumento ⇧, descuento ⇩ o precio base.</p>

                            <div className="disclosureDaysCont greyBack">
                              <p onClick={openMonthConditionF} className={openMonthCondition === false ? "minitext" : 'closeButtonDiscl'}>{openMonthCondition === false ? "Modificar porcentaje" : 'Cerrar'}</p>
                              <div className={openMonthCondition === false ? "displayNone" : 'column center '}>


                                <div className='row center flexwrap' >

                                  <button className={monthExtra === 10 ? 'paybutton paybuttonselected' : 'paybutton'} onClick={() => {
                                    chooseCondition(10)
                                  }}>    10% </button>

                                  <button className={monthExtra === 4 ? 'paybutton paybuttonselected' : 'paybutton'} onClick={() => {
                                    chooseCondition(4)
                                  }}>    25% </button>

                                  <button className={monthExtra === 2 ? 'paybutton paybuttonselected' : 'paybutton'} onClick={() => {
                                    chooseCondition(2)
                                  }}>    50%</button>

                                  <button className={monthExtra === 1 ? 'paybutton paybuttonselected' : 'paybutton'} onClick={() => {
                                    chooseCondition(1)
                                  }}>    100% </button>
                                </div>

                                <button className='editButtonText margintop2' onClick={() => {
                                  setPaymentCondition("months")
                                }}>Confirmar</button>
                              </div>
                            </div>

                            <div className='pricingButtonListCont'>
                              {
                                months.map(e =>

                                  <PricingButton key={e} text={e} pushSelection={pushSelection} type="month" paymentData={paymentData} />

                                )
                              }
                            </div>
                          </div>
                        </>
                      }
                      <button className={showExtras === false ? "displayNone" : 'closeButton'} onClick={() => {
                        showExtra()
                      }}>Cerrar</button>
                    </div>

                    <div className='paymentItem relativePos column'>
                    <p className='beds'> <b>TIPO DE RESERVA</b> </p>
                    <p className='minitext margintop2'>Si querés tener un control extra sobre las reservas de tu hospedaje, podés elegir mantenerlas como PENDIENTES hasta tu confirmación. </p>
                    <p className='minitext margintop2'>Tené en cuenta que los lugares se liberarán SOLO si cancelás la reserva. Mientras el estado sea PENDIENTE o CONFIRMADA los lugares permanecerán ocupados. </p>

                    
                    
                    <button className={status === false ? 'switch pending margintop2' : 'switch on margintop2'} onClick={toggleResStatus} > <span className={cancelling === true ? "switchBallCancelling" : status === false ? 'switchBallOff' : 'switchBallOn'}>⬤</span> 
                    </button>


                    <p className={status === false ? 'minitext pending bookConditionText' : 'minitext on bookConditionText'} >{status === false ? "Las reservas se mostrarán como PENDIENTES hasta que las confirmes o canceles en la sección de reservas de tu administrador" : "Las reservas de mostrarán en todo momento como CONFIRMADAS para tus huéspedes."}</p>
                    </div>
                    </section>
                  </>

                }
              </>
            }


          </div>

        </>

      }


    </>
  )
}

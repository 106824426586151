import React, { useState, useEffect } from 'react';
import { getFirestore } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useLoginContext } from '../../context/LoginContext'


export default function LoginUserCheck({ customer }) {
  const db = getFirestore()
  const navigate = useNavigate();
  const { rerenderLogin, usersRegistered } = useLoginContext()


  const handleSubmit = (event) => {
    event.preventDefault();
  }


  const [username, setUsername] = useState()
  const [errorLog, setErrorLog] = useState(false)
  const [userExists, setUserExists] = useState(false)

  useEffect(() => {
    rerenderLogin()
  }, [])

  useEffect(() => {
    let usersRegisteredCheck = usersRegistered.find(e => e.id == username)


    if (usersRegisteredCheck !== undefined) {
      setUserExists(true)


    } else {
      return
    }
  }, [customer])


  const userCheck = () => {
    let usersRegisteredCheck = usersRegistered.find(e => e.id == username)


    if (usersRegisteredCheck !== undefined) {
      setUserExists(true)
      setTimeout(() => {

        return navigate(`/${username}/dashboard`, { replace: true }), [navigate]

      }, 200);
    } else {
      setErrorLog(true)
      setTimeout(() => {
        setErrorLog(false)
      }, 1900);
    }
  }


  const [loginAnimation, setLoginAnimation] = useState(false)

  useEffect(() => {
    setLoginAnimation(true)

    setTimeout(() => {
      setLoginAnimation(false)


    }, 2000);
  }, [])


  return (

    <>
      <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>
        <p className='logoMicrositeLoading' >HOLDE <span className='logobackMicrosite'>.ar</span></p>
        <div className='logincont'>

          <form className='formPersonalDataCont' action="" onSubmit={handleSubmit}>
            <div className='center'>
              <p className='detailtext'>Ingresa tu nombre de usuario</p>
              <input required className='personalDataInput' onChange={(e) => setUsername(e.target.value)} />
            </div>
          </form>
        </div>
        <button type='submit' className='loginbutton' onClick={userCheck}>
          ⤑ </button>

      </div>
    </>

  )
}

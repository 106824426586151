import React, { useState, useEffect } from 'react';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref, listAll, uploadBytes, getDownloadURL } from "firebase/storage";
import { RaceBy } from '@uiball/loaders'



export default function ImgEditButtonRooms({ prop, link, customer, roomid, edit, rerender, roomname }) {
  const db = getFirestore()
  const storage = getStorage();
  const [newimg, setNewimg] = useState()
  const [uploading, setUploading] = useState(false)
  const [uploaded, setUploaded] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [ready, setReady] = useState(false)
  const [image, setImage] = useState(null)

  const [allImages, setAllImages] = useState([])
  const [allImagesLinks, setAllImagesLinks] = useState([])
  const [showUpButton, setshowUpButton] = useState(false)


  // TO GET ALL THE LINKS OF THE IMGS IN STORAGE

  useEffect(() => {
    const listRef = ref(storage, `clients/${customer}/rooms/${roomid}`);
    listAll(listRef)
      .then((res) => {
        res.items.forEach((itemRef) => {
          allImages.push(itemRef)
        });
      }).catch((error) => {
        console.log(error)
      });

  }, [])


  const [change, setchange] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setchange(false)

      setTimeout(() => {
        setchange(true)
      }, 1500);
    }, 1000);
  }, [])



  useEffect(() => {
    allImages.forEach((e) => {
      const storageRef = ref(storage, `clients/${customer}/rooms/${roomid}/${e.name}`)
      getDownloadURL(storageRef)
        .then(url =>
          allImagesLinks.push(`${url}`)
        )
        .catch((error) => { console.log(error) })
    })
  }, [change])

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }



  const [allImagesLinksFilter, setAllImagesLinksFilter] = useState([])

  useEffect(() => {

    setAllImagesLinksFilter(allImagesLinks.filter(onlyUnique))

  }, [change])



  const [openGallery, setopenGallery] = useState(false)

  const openGalleryF = () => {
    if (openGallery === true) {
      setopenGallery(false)

    } else {
      setopenGallery(true)

    }
  }




  const upload = () => {
    setUploading(true)

    setTimeout(() => {
      setUploading(false)
      setUploaded(true)

      setTimeout(() => {
        setUploaded(false)
        setConfirm(true)
      }, 500);
    }, 2000)
  }





  const [bigImg, setBigImg] = useState(false)
  // ESTA FUNCIÓN GUARDA EN EL ESTADO IMAGE A LA IMAGEN ELEGIDA EN EL INPUT
  const handleChange = (e) => {
    console.log(e.target.files[0].size)
    if (e.target.files[0] && e.target.files[0].size < 300000) {
      setImage(e.target.files[0])
      setshowUpButton(true)
      setopenGallery(false)
    } else {
      setUploaded(false)
      setBigImg(true)

      setTimeout(() => {
        setBigImg(false)
      }, 4000);
    }
  }


  const handleUploadimg = () => {

    const storageRef = ref(storage, `clients/${customer}/rooms/${roomid}/${image.name}`)
    uploadBytes(storageRef, image);
    getDownloadURL(storageRef)
      .then(url => setNewimg(`${url}`))
      .catch((error) => { console.log(error) })
      .finally(rerender())
  }




  const confirmImgChange = () => {
    edit(prop, newimg)

    setTimeout(() => {
      rerender()
      setshowUpButton(false)
      setReady(true)
      setopenGallery(false)
    }, 200);
  }


  const chooseFromGallery = (a) => {
    setNewimg(a)
    setshowUpButton(true)
  }


  return (
    <>
      {image != null ?


        <div onClick={() => {
          handleUploadimg(); upload()
        }} type="image" className='addimageButton'>


          {confirm === true ? <button onClick={confirmImgChange} className='confirmationButton'> {ready === false ? "Confirmar" : "¡Listo!"} </button> : uploaded === true ?
            <img className='uploadImg' src="https://img.icons8.com/external-others-inmotus-design/67/000000/external-Done-basic-web-ui-elements-others-inmotus-design.png" />
            :

            uploading === true ?
              <RaceBy
                size={80}
                lineWeight={5}
                speed={1.4}
                color="white"
              /> :

              <img className='uploadImg' src="https://img.icons8.com/pastel-glyph/64/000000/upload--v1.png" />
          }
        </div>

        :

        <div className='imgbuttonCont'>
          <div onClick={openGalleryF} className='addimageButton'>
            +
          </div>
        </div>

      }

      {bigImg === true ? <p className='bigImgCont'>El archivo supera los 300kb. Pruebe con uno de menor tamaño.</p> : <></>}

      {allImagesLinksFilter !== undefined && openGallery === true ?
        <>

          <div className="imgGalleryDataBaseCont">
            <p className='imgGalleryTittle'> {roomname} - Imágenes</p>
            <div onClick={openGalleryF} className='closeButton'>
              Cerrar
            </div>
            <div className='imgGalleryImagesCont'>


              {allImagesLinksFilter.map(e =>
                <img key={e} onClick={() => { chooseFromGallery(e) }} className="imgGallery" src={e} alt="" />
              )}

              {allImagesLinksFilter.length === 0 ?

                <button className='reloadButtonCont' onClick={() => { window.location.reload(true) }}>

                  <img className='reloadButtonImg' src="https://img.icons8.com/ios-glyphs/480/null/refresh--v1.png" />

                </button> : <></>}
            </div>
            <div className='imgbuttonCont'>
              {showUpButton === true ?

                <button onClick={confirmImgChange} className='addimageButton2'> Subir </button>
                : <></>}


              <div onClick={
                handleUploadimg
              } className='addimageButton2'>
                Cargar nueva
              </div>
              <input onChange={handleChange} type="file" className='addimageButtonAbs' />
            </div>
          </div>

        </>
        :

        <></>
      }
    </>
  )
}




import React, { useState } from 'react'
import { useRoomsContext } from '../../context/RoomsContext'
import { LineWobble } from '@uiball/loaders'

export default function CheckInput({ roomselected, number }) {

    const { rooms, newguest1, newguest2, newguest3, newguest4, newguest5, newguest6, newguest7, newguest8, newguest9, newguest10, rerender } = useRoomsContext()

    const [loader, setLoader] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [newname, setNewname] = useState("")
    const [newid, setNewid] = useState(Number)
    const [newidType, setNewidType] = useState("")
    const [newemail, setNewemail] = useState("")
    const [newphone, setNewphone] = useState(Number)
    const [newnationality, setNewnationality] = useState("")
    const [newcheckinDate, setNewcheckinDate] = useState("")
    const [newcheckoutDate, setNewcheckoutDate] = useState("")
    const [newpayment, setNewpayment] = useState(Boolean)


    const newGuest = () => {


        setLoader(true)

        setTimeout(() => {
            setLoader(false)
            setLoaded(true)
        }, 1500);

        const date = new Date();

        let guest = {}

        guest.name = newname
        guest.id = Number(newid)
        guest.idType = newidType
        guest.email = newemail
        guest.phone = Number(newphone)
        guest.nationality = newnationality
        guest.checkinDate = newcheckinDate
        guest.checkoutDate = newcheckoutDate
        guest.payment = newpayment

        if (number === 1) {
            return newguest1.pop(), newguest1.push(guest)
        } if (number === 2) {
            return newguest2.pop(), newguest2.push(guest)
        } if (number === 3) {
            return newguest3.pop(), newguest3.push(guest)
        } if (number === 4) {
            return newguest4.pop(), newguest4.push(guest)
        } if (number === 5) {
            return newguest5.pop(), newguest5.push(guest)
        } if (number === 6) {
            return newguest6.pop(), newguest6.push(guest)
        } if (number === 7) {
            return newguest7.pop(), newguest7.push(guest)
        } if (number === 8) {
            return newguest8.pop(), newguest8.push(guest)
        } if (number === 9) {
            return newguest9.pop(), newguest9.push(guest)
        } if (number === 10) {
            return newguest10.pop(), newguest10.push(guest)
        }


    }



    const handleSubmit = (event) => {
        event.preventDefault();

    }


    return (

        <form className='newguestform' action="" onSubmit={handleSubmit}>
            <p className='guesttitle'>Huésped {number} </p>

            <input className='newguestinput' type="text" onChange={(e) => setNewname(e.target.value)} placeholder="Nombre" />
            <input className='newguestinput' type="text" onChange={(e) => setNewid(e.target.value)} placeholder="Documento" />
            <input className='newguestinput' type="text" onChange={(e) => setNewidType(e.target.value)} placeholder="Doc. Tipo" />
            <input className='newguestinput' type="text" onChange={(e) => setNewemail(e.target.value)} placeholder="Email" />
            <input className='newguestinput' type="text" onChange={(e) => setNewphone(e.target.value)} placeholder="Teléfono" />
            <input className='newguestinput' type="text" onChange={(e) => setNewnationality(e.target.value)} placeholder="Nacionalidad" />
            <input className='newguestinput' type="date" onChange={(e) => setNewcheckinDate(e.target.value)} placeholder="Check-In fecha" />
            <input className='newguestinput' type="date" onChange={(e) => setNewcheckoutDate(e.target.value)} placeholder="Check-Out fecha" />
            <input className='newguestinput' type="text" onChange={(e) => setNewpayment(e.target.value)} placeholder="Pago" />

            <button className={loaded === true ? 'newguestsubmitbuttonLoaded' : 'newguestsubmitbutton'} onClick={newGuest
            }> {loader === true ? <LineWobble
                className="marginauto"
                size={80}
                lineWeight={5}
                speed={1.75}
                color="#7D9D9C"
            /> : loaded === true ? "✓" : "CARGAR"} </button>

        </form>
    )
}

import React, { useState, useEffect } from 'react';


export default function AmenitiesButton({ id, text, data, push, useramenities, dashboard, render }) {

    const [image, setImage] = useState()
    const [img, setImg] = useState()
    const [exists, setExists] = useState(false)

    useEffect(() => {
        const amenities = data.find(e => e.id == "amenities")

        const eachAmenitie = Object.entries(amenities)

        const thisAmenitie = eachAmenitie.find(e => e[0] == text)

        setTimeout(() => {
            setImg(thisAmenitie[1])
        }, 200);
    }, [data])

    useEffect(() => {
        const arr = useramenities.find(e => e.id === "amenities") != undefined ? useramenities.find(e => e.id === "amenities").newamenities : []


        if (arr.includes(text) === true) {
            setExists(true)
        } else {
            setExists(false)
        }
    }, [useramenities, render])


    const pushF = () => {
        push(text)
    }



    return (

        <>
            {dashboard === true ?
                <>
                    <button onClick={pushF} className={exists === true && dashboard === true ? 'amenitiesDashboardItemCont green' : 'amenitiesDashboardItemCont'}>
                        <img className='amenitiesImg' src={img} alt={text} />
                        <p className='amenitiesText'> {text} </p>
                    </button>
                </>

                :
                <>

                    <div className={exists === true && dashboard === true ? 'amenitiesDashboardItemCont green' : 'amenitiesDashboardItemCont'}>
                        <img className='amenitiesImg' src={img} alt={text} />
                        <p className='amenitiesText'> {text} </p>
                    </div>

                </>
            }
        </>
    )
}

import React, { useState, useEffect } from 'react'
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { useLoginContext } from '../../context/LoginContext'
import NavbarMaster from '../Navbar/NavbarMaster';
import './Masterdashboard.css';
import NewUsers from './NewUsers';

export default function MasterDashboard() {
    
    const { usersRegistered } = useLoginContext()


    return (
        <>
         <div className='dashboardCont'>
            <NavbarMaster />
            <p className='sectionTittles'>| DASHBOARD</p>

            <div className='dashboardColumnCont'>
              <div className='dashboardRowCont'>
                <NewUsers  usersRegistered={usersRegistered} />
              </div>

        

              <div className='dashboardRowCont'>
              </div>
            </div>
            </div>
        </>
    )
}

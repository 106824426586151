import React from 'react';
import RoomCardsList from './RoomCardsList';
import { useRoomsContext } from '../context/RoomsContext'

export default function RoomCardsListCont({forbook1, forbook2, forbook3, forbook4, forbook5, forbook6, forbook7, forbook8, forbook9, forbook10,  forbook11,  forbook12,  forbook13,  forbook14,  forbook15,  searchIn, step1, step2, step3, line1, line2, guests, weekCalendar, admin }) {


    const { rooms } = useRoomsContext()
  

  
  return (
    <>
     
    <RoomCardsList rooms={rooms} forbook1={forbook1} forbook2={forbook2} forbook3={forbook3} forbook4={forbook4} forbook5={forbook5} forbook6={forbook6} forbook7={forbook7} forbook8={forbook8} forbook9={forbook9} forbook10={forbook10} forbook11={forbook11} forbook12={forbook12} forbook13={forbook13} forbook14={forbook14} forbook15={forbook15}  searchIn={searchIn} step1={step1} step2={step2} step3={step3} line1={line1} line2={line2}  guests={guests} weekCalendar={weekCalendar} admin={admin} />

    </>
  )
}



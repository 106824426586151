import React from 'react';

export default function NewMessagesItem({ name, text, phone, email}) {



    return (
        <div className='ArrivalsItem newbookingsBack'>
            <p className='ArrivalsName'>{name}</p>
            <p className='MessageText'>{text}</p>



            <div className='ArrivalsButtonsCont'>
                <button className='actionButton' onClick={() => window.location = `mailto: ${email}`}>
                    <img className='actionImgArrivals' src="https://img.icons8.com/ios-filled/100/000000/new-post.png" />
                </button>

                <button className='actionButton' onClick={() => window.location = `https://wa.me/${Number(phone)}`}>
                    <img className='actionImgArrivals' src="https://img.icons8.com/ios-glyphs/240/000000/whatsapp.png" />
                </button>

                <button className='actionButton' onClick={() => window.location = `tel:${Number(phone)}`}>
                    <img className='actionImgArrivals' src="https://img.icons8.com/ios-glyphs/90/000000/phone-disconnected.png" />
                </button>
            </div>
        </div>

    )
}

import React, { useState, useEffect } from 'react';
import { addDoc, collection, getFirestore, getDocs } from 'firebase/firestore';
import { useParams, useLocation } from 'react-router-dom';
import NavbarPanel from '../NavbarPanel';
import { useLoginContext } from '../../context/LoginContext'
import Login from '../login/Login';
import PlansItem from './PlansItem';
import { LineWobble } from '@uiball/loaders'
import { useRoomsContext } from '../../context/RoomsContext'


export default function PlansCont() {
    const db = getFirestore()
    const { customer, roomedit } = useParams()
    const { rooms, contextRooms } = useRoomsContext()
    const { loginCheck } = useLoginContext()
    const location = useLocation()
    const [paymentData, setPaymentData] = useState([])
    const [conditiondata, setConditiondata] = useState()
    const [loader, setLoader] = useState(true)
    const [roomPrice, setRoomPrice] = useState()



    useEffect(() => {
        contextRooms.push(customer)
    }, [])


    useEffect(() => {
        const date = new Date();

        let locationCheck = location.search.includes("status=approved")
        const queryCollection = collection(db, `clients/${customer}/pays`,)

        let pay = {}

        pay.timestamp = Date.now()
        pay.date = date
        pay.customer = customer
        pay.data = location.search
        pay.fullbookdate = { day: date.getDate(), month: (date.getMonth() + 1), year: date.getFullYear() }

        if (locationCheck === true) {

            addDoc(queryCollection, pay)
                .then(res => console.log(res))
                .catch(err => console.log(err))

        }
    }, [location])

    useEffect(() => {
        let roomPricesArr = rooms.map(e => e.price).sort(function (a, b) {
            return a - b;
        })
        setRoomPrice(roomPricesArr[0])
    }, [rooms])





    const loaderPromise = new Promise((resolve, reject) => {
        setTimeout(() => { resolve([]); }, 2000);
    });

    loaderPromise.then(() => {
        setLoader(false);
    });

    useEffect(() => {
        const queryCol = collection(db, `clients/${customer}/payments`)

        getDocs(queryCol)
            .then(resp => setPaymentData(resp.docs.map(bdata => ({ id: bdata.id, ...bdata.data() }))))
            .catch((err) => console.log(err))
    }, [customer, conditiondata])


    const [loginAnimation, setLoginAnimation] = useState(false)

    useEffect(() => {
        setLoginAnimation(true)

        setTimeout(() => {
            setLoginAnimation(false)


        }, 2000);
    }, [])

    return (
        <>

            {loginCheck != true ?

                <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>
                    <p className='logoMicrositeLoading' >HOLDE <span className='logobackMicrosite'>.ar</span></p>

                    <Login customer={customer} />
                </div>
                :
                <>
                    <div className='dashboardCont'>
                        <NavbarPanel customer={customer} />
                        <p className='sectionTittles'>| PLANS ROOM </p>

                        {loader === true ?

                            <div className='weekcalendarloader'>
                                <LineWobble
                                    size={80}
                                    lineWeight={5}
                                    speed={1.75}
                                    color="#576F72" />
                            </div> :

                            <>
                                <div className='plansSection'>

                                    <div className='plansColumn'>
                                        <p className='mercadopagoTittle'>En <span className='holdeFont green'>HOLDE</span> vos elegís cuánto pagar</p>
                                        <p className='planText'> Nuestro objetivo es brindarle una herramienta a aquellos pequeños y medianos emprendimientos hoteleros a un costo accesible para cualquiera. Confiamos en que esta plataforma les hará más fácil administrar y mostrar sus hospedaje, gracias al eficiente motor de reservas. </p>
                                        <p className='planText'> Y porque todo esto de alguna forma tiene que existir gracias a un aporte económico, te damos la posibilidad de contar con más funcionalidades por un precio que arranca tan bajo como un café con dos medialunas. </p>
                                        <p className='planText greenback2'> Los pagos son MENSUALES y tu plan se ajustará al pago recibido, con un tiempo de tolerancia de hasta el día 15 de cada mes*. </p>
                                    </div>

                                    <div className='plansAdmCont '>
                                        <div className='plansColumns'>
                                            <p className='guesttitle'>BASIC</p>
                                            <div className='column center'>
                                                <PlansItem image="https://img.icons8.com/ios-filled/100/000000/cafe.png" text="Café con dos medialunas" price="499" priceF={499} customer={customer} suscripcion="https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c93808483e8f4f2018400fd662010fd"/>

                                                <PlansItem image="https://img.icons8.com/external-vitaliy-gorbachev-fill-vitaly-gorbachev/60/000000/external-pizza-fast-food-vitaliy-gorbachev-fill-vitaly-gorbachev-1.png" text="Grande de muzza" price="999" priceF={999} customer={customer} suscripcion="https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c93808483fd8e070184010259360163" />

                                                <PlansItem image="https://img.icons8.com/sf-regular-filled/96/000000/hamburger.png" text="Hamburguesa con papas" price="1499" priceF={1499} customer={customer} suscripcion="https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c93808483e8f4f201840102b39010fe"  />
                                            </div>
                                        </div>
                                        <div className='plansColumns'>
                                            <p className='guesttitle'>PRO (próximamente) </p>
                                            <div className='column center'>
                                                <PlansItem image="https://img.icons8.com/external-tanah-basah-glyph-tanah-basah/96/000000/external-camping-camping-tanah-basah-glyph-tanah-basah.png" text="Una noche de camping" price="2999" priceF={2999} customer={customer} suscripcion="https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c9380848383e02f01840103383e5334"  />

                                                <PlansItem image="https://img.icons8.com/ios-filled/100/000000/kids-bedroom.png" text="Una noche de hostel" price="4999" priceF={4999} customer={customer} suscripcion="https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c9380848383e02f0184010382015335"  />

                                                <PlansItem image="https://img.icons8.com/ios-filled/100/000000/bed.png" text="Una noche en la habitación más barata de tu hospedaje" price={roomPrice} priceF={parseInt(roomPrice)} customer={customer} suscripcion={null} />
                                            </div>
                                        </div>
                                    </div>
                                    <p className='minitext'> *Los precios están sujetos a posibles cambios trimestrales, en los meses 3, 6, 9 y 12. </p>
                                </div>
       

                            </>
                        }
                    </div>
                </>
            }
        </>)
}

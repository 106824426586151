import React, { useState, useEffect } from 'react';
import { setDoc, doc, collection, getFirestore, getDocs } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './NewAccount.css';
import { Waveform } from '@uiball/loaders'
import { Jelly } from '@uiball/loaders'
import PersonalData from './PersonalData';
import HotelData from './HotelData';



export default function NewAccountCont() {



    const db = getFirestore()

    const [userName, setUserName] = useState()
    const [userCheck, setUserCheck] = useState()
    const [notavail, setNotavail] = useState()
    const [avail, setAvail] = useState()
    const [confirmation, setConfirmation] = useState(false)
    const [verification, setVerification] = useState(false)
    const [loading, setLoading] = useState(false)
    const [nameSubmiting, setNameSubmiting] = useState(false)

    const [personalDataStep, setPersonalDataStep] = useState(false)
    const [hotelDataStep, setHotelDataStep] = useState(false)

    const [userNameSubmited, setUserNameSubmited] = useState(false)
    useEffect(() => {
        const QueryCollection = collection(db, `clients`)

        getDocs(QueryCollection)
            .then(resp => setUserCheck(resp.docs.map(book => ({ id: book.id, ...book.data() }))))
            .catch((err) => console.log(err))

    }, [])

    
    
 

    const confirmationF = () => {

        const usersCheck = userCheck.find(e => e.id == userName)
        const userNameSplit = userName.split("")
        const checkSpaces = userNameSplit.some(e => e === " ")
        const checkNumbers = userNameSplit.some(e => e == 0 || e == 1 || e == 2 || e == 3 || e == 4 || e == 5 || e == 6 || e == 7 || e == 8 || e == 9)


        if (usersCheck === undefined && userName.length > 3 && checkSpaces === false && checkNumbers === false) {
            setLoading(true)

            setTimeout(() => {
                setConfirmation(false)
                setLoading(false)
                setAvail(true)
                setNotavail(false)
            }, 3000);

        } else {
            setLoading(true)
            setTimeout(() => {
                setConfirmation(false)
                setLoading(false)
                setNotavail(true)
                setAvail(false)
            }, 3000);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

    }

    const registerUserName = () => {
        const date = new Date();

        setNameSubmiting(true)
        setUserNameSubmited(true)
        setDoc(doc(db, `clients`, userName), {
            client: userName,
            since: Date.now(),
            sinceFullDate: { day: date.getDate(), month: (date.getMonth() + 1), year: date.getFullYear() }

        })

        setTimeout(() => {
            setPersonalDataStep(true)
            setNameSubmiting(false)
        }, 2000);
    }

    const hotelDataStepF = () => {
        setHotelDataStep(true)
        setPersonalDataStep(false)
    }


    const codeVerF = (a) => {
      if(a === true ) {
        setVerification(true)
      }
    }

    

    
    return (
        <>

            <Link to="/">
                <p className='logoMicrosite black'>HOLDE <span className='logobackMicrosite'>.ar</span></p>

            </Link>




<section className='sectionNewAcc'>
{personalDataStep === true ?
<PersonalData username={userName} nextstep={hotelDataStepF} />
: <></>}

{hotelDataStep === true ?
<HotelData  username={userName} />
:<></>}

{nameSubmiting === true ?

    <div className='loaderContJelly'>
        <Jelly
        size={150}
        speed={0.9}
        color="#576F72"
    />
    </div>

    :
    <>
            <div className={userNameSubmited === true ? 'greenBack3 formSectionCont displaynone' : 'greenBack3 formSectionCont'}>
                <div>
                    <p className='welcometittle'>¡Empecemos!</p>
                </div>

                <p className='detailtext'>Primero, elegí el nombre de usuario</p>
                <form className='inputWelCont' action="" onSubmit={handleSubmit}>
                    <input className='welcomeInput' onChange={(e) => setUserName((e.target.value).toLowerCase())} />

                    {avail === true ?

                        <button className='welcomeButton'>

                            <span onClick={registerUserName} className='detailtextButton'>Registrar</span>

                        </button>
                        :

                        <button onClick={confirmationF} className='welcomeButton'>
                            {loading === true ?

                                <Waveform
                                    size={15}
                                    lineWeight={1}
                                    speed={1}
                                    color="black"
                                />

                                :
                                <>⤑</>
                            }
                        </button>
                    }
                </form>
                <div className='detailtextcont'>
                    <p className='detailtext'>Tu <b>hold</b> será: <b>holde.ar/{userName}</b> {avail === true ? <span className='greentext'><b>✓</b></span> : <></>} {notavail === true ? <span className='redtext'><b>✕</b> 
                    <span> </span> Este nombre ya existe o no cumple con alguna de las reglas.</span> : <></>}  </p>
                    <p></p>
                    <p className='detailtext'>❍ Al menos 4 letras.</p>
                    <p className='detailtext'>❍ Solo minúsculas.</p>
                    <p className='detailtext'>❍ Sin espacios.</p>
                    <p className='detailtext'>❍ Sin números.</p>
                    <p className='detailtext'>❍ Por ejemplo, para "Chalten Backpackers Hostel", el nombre de usuario podría ser: <b>chaltenbackpackers</b></p>
                    <p className='detailtext redtext'>❍ ¡Cuidado! El nombre de usuario <span className='underline'>no podrá cambiarse en el futuro</span>.</p>
                </div>
            </div>
        </>
}



    </section>



        </>
            )
}

import React, { useState, useEffect } from 'react';
import NavbarPanelItem from './NavbarPanelItem';

export default function NavbarPanel({ customer, bookings }) {

    const [noti, setNoti] = useState(false)
    const [notiNumber, setNotiNumber] = useState(Number)
    const [screen, setScreen] = useState()
    const [menuOpen, setMenuOpen] = useState(false)
    useEffect(() => {
        setScreen(window.screen.width)
    }, [])


    const weektimeStamp = 1659571200
    const daytimeStamp = (weektimeStamp / 7)
    const hourtimeStamp = ((weektimeStamp / 7) / 24)

    useEffect(() => {
        if (bookings != undefined) {


            const notiInLastHour = bookings.map((a) => a.timestamp > (Date.now() - (hourtimeStamp))).filter(e => e === true)


            if (bookings.some((a) => a.timestamp > (Date.now() - (hourtimeStamp))) === true) {
                setNoti(true)
                setNotiNumber(notiInLastHour.length)
            }
        }
    }, [])


    const openMenu = () => {
        if (menuOpen === false) {
            setMenuOpen(true)

        } else { setMenuOpen(false) }
    }



    return (
        <>
            {screen > 800 ? <></> :
                <button className='navbarmobilebutton' onClick={openMenu} ><p className={menuOpen === false ? 'leftBar' : 'leftBarOpened'}>|</p> <p className={menuOpen === false ? 'righBar' : 'righBarOpened'}>|</p> <p className={menuOpen === false ? 'leftBar' : 'displayNone'}>|</p>
                </button>
            }

            {menuOpen === false && screen < 800 ? <></> :

                <div className='navbarAllCont'>
                    <nav className='navbarPanelCont inverted-border-radius'>

                        <p className='logo'>HOLDE <span className='logoback'>.ar</span></p>

                        <NavbarPanelItem route={`/${customer}`} src="https://img.icons8.com/external-nawicon-glyph-nawicon/64/null/external-browser-seo-and-marketing-nawicon-glyph-nawicon.png" text="IR AL SITIO"  />


                        <NavbarPanelItem route={`/${customer}/dashboard/plans`} src="https://img.icons8.com/ios-glyphs/90/000000/buy-upgrade.png" text="PLANES" />

                        <NavbarPanelItem route={`/${customer}/dashboard/calendar`} src="https://img.icons8.com/ios-glyphs/90/000000/macos-maximize.png" text="NUEVA RESERVA" />

                        <NavbarPanelItem route={`/${customer}/dashboard`} src="https://img.icons8.com/material-rounded/192/000000/home.png" text="DASHBOARD" />

                        <NavbarPanelItem route={`/${customer}/dashboard/bookings`} src="https://img.icons8.com/ios-filled/100/000000/reservation.png" text="RESERVAS" />

                        <NavbarPanelItem route={`/${customer}/dashboard/checkin`} src="https://img.icons8.com/external-those-icons-fill-those-icons/96/000000/external-Bell-hotel-those-icons-fill-those-icons.png" text="CHECK-IN/OUT" />

                        <NavbarPanelItem route={`/${customer}/dashboard/bookcalendar`} src="https://img.icons8.com/ios-filled/100/000000/calendar--v1.png" text="CALENDARIO" />

                        <NavbarPanelItem route={`/${customer}/dashboard/rooms`} src="https://img.icons8.com/ios-glyphs/90/000000/bed.png" text="HABITACIONES" />

                        <NavbarPanelItem route={`/${customer}/dashboard/pricing`} src="https://img.icons8.com/ios-glyphs/90/000000/cost.png" text="PRECIOS" />

                        <NavbarPanelItem route={`/${customer}/dashboard/agenda`} src="https://img.icons8.com/external-those-icons-fill-those-icons/96/000000/external-agenda-business-those-icons-fill-those-icons.png" text="AGENDA"  minitext="NUEVO ⥣"/>

                        <NavbarPanelItem route={`/${customer}/dashboard/messages`} src="https://img.icons8.com/ios-glyphs/90/000000/topic.png" text="MENSAJES" />

                        <NavbarPanelItem route={`/${customer}/dashboard/payments`} src="https://img.icons8.com/ios-glyphs/90/000000/bank-card-back-side.png" text="MÉTODOS DE PAGO" />

                        {/* 
                        <NavbarPanelItem route={`/`} src="https://img.icons8.com/external-flat-icons-inmotus-design/67/000000/external-database-economics-data-analisys-flat-icons-inmotus-design.png" text="METRICS" minitext="STS" /> */}

                        <NavbarPanelItem route={`/${customer}/dashboard/profile`} src="https://img.icons8.com/material/96/000000/user--v1.png" text="PERFIL" />

                        <NavbarPanelItem route={`/${customer}/dashboard/microsite`} src="https://img.icons8.com/material/96/000000/web.png" text="MICROSITIO" />

                        <NavbarPanelItem route={`/${customer}/dashboard/microsite/comments`} src="https://img.icons8.com/external-others-inmotus-design/67/000000/external-Comment-comments-others-inmotus-design-3.png" text="COMENTARIOS" />

                        <NavbarPanelItem route={`/contactus`} src="https://img.icons8.com/external-phatplus-solid-phatplus/64/576f72/external-support-authentication-phatplus-solid-phatplus-2.png" text="SOPORTE" />

                    </nav>

                </div>
            }


        </>
    )
}



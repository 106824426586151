import React from 'react'

export default function SearchButton({removeSelectionButton, forBook}) {




      const forBookF = async() => {
        forBook()
      }

      const removeSelectionButtonF = () => {
        removeSelectionButton()
      }

    return (
        <div className='searchRoomsButtonCont'>

            <div className='searchRoomsButtonsDetailCont'>
                <button className='searchRoomsButton' onClick={removeSelectionButtonF}> REMOVER SELECCIÓN </button>

            </div>

            <div className='searchRoomsButtonsDetailCont'>
                <img className='searchRoomsimg' src="https://img.icons8.com/ios-filled/150/000000/search--v1.png" />
                <button className='searchRoomsButton' onClick={forBookF}> BUSCAR </button>

            </div>

        </div>
    )
}

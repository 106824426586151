import React, { useState, useEffect } from 'react'
import './Booking.css';
import BookList from './BookList';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { useCalendarContext } from '../context/CalendarContext'
import { useBookingContext } from '../context/BookingContext'
import { useRoomsContext } from '../context/RoomsContext'
import RoomCardsListCont from '../rooms/RoomCardsListCont';
import SearchButton from './SearchButton';
import { Waveform } from '@uiball/loaders'
import BookBar from './BookBar';
import BookedOrderModal from './BookedOrderModal';
import { useParams } from 'react-router-dom';

export default function BookListCont({ customerId, customerAdmin }) {
  const db = getFirestore()
  const { dateList, removeSelection, daysBookedCont } = useCalendarContext()
  const { rooms, contextRooms, rerenderF } = useRoomsContext()
  const { guestsTotal } = useBookingContext()
  const { customer } = useParams()



  useEffect(() => {
    contextRooms.push(customer)
  }, [])

  useEffect(() => {

    if(rooms.length === 0) {
     rerenderF()
    }
  }, [rooms])


  const [daysInCurrentMonth, setDaysInCurrentMonth] = useState()
  const [loader, setLoader] = useState(false)
  const [calendarloader, setCalendarloader] = useState(true)
  const [hideCalendar, setHideCalendar] = useState(false)
  const [searchIn, setSearchIn] = useState(false)

  const [nextmonth, setNextmonth] = useState()
  const [month, setMonth] = useState()
  const [year, setYear] = useState()
  const [firstDay, setFirstDay] = useState("")
  const [monthDays, setMonthDays] = useState()

  const [from, setFrom] = useState({})
  const [to, setTo] = useState({})

  const [renderBook, setRenderBook] = useState(true)

  //THESE STATES SAVES ALL THE MONTHS OF THE CURRENTLY YEAR OF EACH ROOM
  const [room1Months, setRoom1Months] = useState([])
  const [room1MonthsNY, setRoom1MonthsNY] = useState([])
  const [m1, setM1] = useState([])
  const [room1availability, setRoom1availability] = useState([])
  const [room1availabilityNM, setRoom1availabilityNM] = useState([])
  const [room1ForBook, setRoom1ForBook] = useState(false)
  const [m1nextMonth, setM1nextMonth] = useState([])

  const [room2Months, setRoom2Months] = useState([])
  const [room2MonthsNY, setRoom2MonthsNY] = useState([])
  const [m2, setM2] = useState([])
  const [room2availability, setRoom2availability] = useState([])
  const [room2availabilityNM, setRoom2availabilityNM] = useState([])
  const [room2ForBook, setRoom2ForBook] = useState(false)
  const [m2nextMonth, setM2nextMonth] = useState([])

  const [room3Months, setRoom3Months] = useState([])
  const [room3MonthsNY, setRoom3MonthsNY] = useState([])
  const [m3, setM3] = useState([])
  const [room3availability, setRoom3availability] = useState([])
  const [room3availabilityNM, setRoom3availabilityNM] = useState([])
  const [room3ForBook, setRoom3ForBook] = useState(false)
  const [m3nextMonth, setM3nextMonth] = useState([])

  const [room4Months, setRoom4Months] = useState([])
  const [room4MonthsNY, setRoom4MonthsNY] = useState([])
  const [m4, setM4] = useState([])
  const [room4availability, setRoom4availability] = useState([])
  const [room4availabilityNM, setRoom4availabilityNM] = useState([])
  const [room4ForBook, setRoom4ForBook] = useState(false)
  const [m4nextMonth, setM4nextMonth] = useState([])

  const [room5Months, setRoom5Months] = useState([])
  const [room5MonthsNY, setRoom5MonthsNY] = useState([])
  const [m5, setM5] = useState([])
  const [room5availability, setRoom5availability] = useState([])
  const [room5availabilityNM, setRoom5availabilityNM] = useState([])
  const [room5ForBook, setRoom5ForBook] = useState(false)
  const [m5nextMonth, setM5nextMonth] = useState([])

  const [room6Months, setRoom6Months] = useState([])
  const [room6MonthsNY, setRoom6MonthsNY] = useState([])
  const [m6, setM6] = useState([])
  const [room6availability, setRoom6availability] = useState([])
  const [room6availabilityNM, setRoom6availabilityNM] = useState([])
  const [room6ForBook, setRoom6ForBook] = useState(false)
  const [m6nextMonth, setM6nextMonth] = useState([])

  const [room7Months, setRoom7Months] = useState([])
  const [room7MonthsNY, setRoom7MonthsNY] = useState([])
  const [m7, setM7] = useState([])
  const [room7availability, setRoom7availability] = useState([])
  const [room7availabilityNM, setRoom7availabilityNM] = useState([])
  const [room7ForBook, setRoom7ForBook] = useState(false)
  const [m7nextMonth, setM7nextMonth] = useState([])

  const [room8Months, setRoom8Months] = useState([])
  const [room8MonthsNY, setRoom8MonthsNY] = useState([])
  const [m8, setM8] = useState([])
  const [room8availability, setRoom8availability] = useState([])
  const [room8availabilityNM, setRoom8availabilityNM] = useState([])
  const [room8ForBook, setRoom8ForBook] = useState(false)
  const [m8nextMonth, setM8nextMonth] = useState([])

  const [room9Months, setRoom9Months] = useState([])
  const [room9MonthsNY, setRoom9MonthsNY] = useState([])
  const [m9, setM9] = useState([])
  const [room9availability, setRoom9availability] = useState([])
  const [room9availabilityNM, setRoom9availabilityNM] = useState([])
  const [room9ForBook, setRoom9ForBook] = useState(false)
  const [m9nextMonth, setM9nextMonth] = useState([])

  const [room10Months, setRoom10Months] = useState([])
  const [room10MonthsNY, setRoom10MonthsNY] = useState([])
  const [m10, setM10] = useState([])
  const [room10availability, setRoom10availability] = useState([])
  const [room10availabilityNM, setRoom10availabilityNM] = useState([])
  const [room10ForBook, setRoom10ForBook] = useState(false)
  const [m10nextMonth, setM10nextMonth] = useState([])

  const [room11Months, setRoom11Months] = useState([])
  const [room11MonthsNY, setRoom11MonthsNY] = useState([])
  const [m11, setM11] = useState([])
  const [room11availability, setRoom11availability] = useState([])
  const [room11availabilityNM, setRoom11availabilityNM] = useState([])
  const [room11ForBook, setRoom11ForBook] = useState(false)
  const [m11nextMonth, setM11nextMonth] = useState([])

  const [room12Months, setRoom12Months] = useState([])
  const [room12MonthsNY, setRoom12MonthsNY] = useState([])
  const [m12, setM12] = useState([])
  const [room12availability, setRoom12availability] = useState([])
  const [room12availabilityNM, setRoom12availabilityNM] = useState([])
  const [room12ForBook, setRoom12ForBook] = useState(false)
  const [m12nextMonth, setM12nextMonth] = useState([])

  const [room13Months, setRoom13Months] = useState([])
  const [room13MonthsNY, setRoom13MonthsNY] = useState([])
  const [m13, setM13] = useState([])
  const [room13availability, setRoom13availability] = useState([])
  const [room13availabilityNM, setRoom13availabilityNM] = useState([])
  const [room13ForBook, setRoom13ForBook] = useState(false)
  const [m13nextMonth, setM13nextMonth] = useState([])

  const [room14Months, setRoom14Months] = useState([])
  const [room14MonthsNY, setRoom14MonthsNY] = useState([])
  const [m14, setM14] = useState([])
  const [room14availability, setRoom14availability] = useState([])
  const [room14availabilityNM, setRoom14availabilityNM] = useState([])
  const [room14ForBook, setRoom14ForBook] = useState(false)
  const [m14nextMonth, setM14nextMonth] = useState([])

  const [room15Months, setRoom15Months] = useState([])
  const [room15MonthsNY, setRoom15MonthsNY] = useState([])
  const [m15, setM15] = useState([])
  const [room15availability, setRoom15availability] = useState([])
  const [room15availabilityNM, setRoom15availabilityNM] = useState([])
  const [room15ForBook, setRoom15ForBook] = useState(false)
  const [m15nextMonth, setM15nextMonth] = useState([])

  const [daysOfThisMonth, setDaysOfThisMonth] = useState([])
  const [daysOfNextMonth, SetDaysOfNextMonth] = useState([])

  const [daysBooked, setDaysBooked] = useState(Number)
  const [allDaysBooked, setAllDaysBooked] = useState([]);
  const [allDaysBooked2, setAllDaysBooked2] = useState([]);

  const [guests, setGuests] = useState(1)
  const [modal, setModal] = useState(false)
  const [bookOrder, setBookOrder] = useState([])

  const [step1, setStep1] = useState(false)
  const [step2, setStep2] = useState(false)
  const [step3, setStep3] = useState(false)
  const [line1, setLine1] = useState(false)
  const [line2, setLine2] = useState(false)

  const [admin, setAdmin] = useState(false)

  useEffect(() => {
    setAdmin(JSON.parse(sessionStorage.getItem('adminToken')))
  }, [])


  function addGuest() {
    if (guests <= 9) {
      setGuests(guests + 1)
    }
  }

  function removeGuest() {
    if (guests == 1) {

    } else {
      setGuests(guests - 1)
    }
  }

  useEffect(() => {
    guestsTotal.push(guests)
  }, [guests])


  //// CARDS LOADER ///

  const cards = new Promise((resolve, reject) => {
    setTimeout(() => { resolve([]); }, 2000);
  });

  cards.then(() => {
    setLoader(false);
  });

  const calendarLoaderPro = new Promise((resolve, reject) => {
    setTimeout(() => { resolve([]); }, 2000);
  });

  cards.then(() => {
    setCalendarloader(false);

  });

  useEffect(() => {    //THESE FUNCTIONS SAVES ALL THE MONTHS OF THE CURRENTLY YEAR OF EACH ROOM

    function saveMonths(room, set) {
      const queryCol = collection(db, `clients/${customerId}/rooms/room${room}/${year}`)

      getDocs(queryCol)
        .then(resp => set(resp.docs.map(room => ({ id: room.id, ...room.data() }))))
        .catch((err) => console.log(err))
    }

    if (dateList.length === 0) {
      saveMonths(1, setRoom1Months)
      saveMonths(2, setRoom2Months)
      saveMonths(3, setRoom3Months)
      saveMonths(4, setRoom4Months)
      saveMonths(5, setRoom5Months)
      saveMonths(6, setRoom6Months)
      saveMonths(7, setRoom7Months)
      saveMonths(8, setRoom8Months)
      saveMonths(9, setRoom9Months)
      saveMonths(10, setRoom10Months)
      saveMonths(11, setRoom11Months)
      saveMonths(12, setRoom12Months)
      saveMonths(13, setRoom13Months)
      saveMonths(14, setRoom14Months)
      saveMonths(15, setRoom15Months)
    } if (dateList.length === 2) {
      saveMonths(1, setRoom1MonthsNY)
      saveMonths(2, setRoom2MonthsNY)
      saveMonths(3, setRoom3MonthsNY)
      saveMonths(4, setRoom4MonthsNY)
      saveMonths(5, setRoom5MonthsNY)
      saveMonths(6, setRoom6MonthsNY)
      saveMonths(7, setRoom7MonthsNY)
      saveMonths(8, setRoom8MonthsNY)
      saveMonths(9, setRoom9MonthsNY)
      saveMonths(10, setRoom10MonthsNY)
      saveMonths(11, setRoom11MonthsNY)
      saveMonths(12, setRoom12MonthsNY)
      saveMonths(13, setRoom13MonthsNY)
      saveMonths(14, setRoom14MonthsNY)
      saveMonths(15, setRoom15MonthsNY)
    }
  }, [year, dateList])

  useEffect(() => {
    function getDaysInMonth(y, m) {
      return new Date(y, m, 0).getDate();
    }

    const date = new Date();
    const yearF = date.getFullYear()
    const monthF = date.getMonth() + 1

    setYear(yearF)
    setMonth(monthF)

  }, [])

  useEffect(() => {

    function getDaysInMonth(y, m) {
      return new Date(y, m, 0).getDate();
    }

    const daysInCurrentMonthF = setDaysInCurrentMonth(getDaysInMonth(year, month));

  }, [month])


  useEffect(() => {

    if (month <= 11) {
      const nextmonthF = month + 1
      setNextmonth(nextmonthF)
    } if (month === 12) {
      setNextmonth(1)
    }
  }, [month])

  useEffect(() => {

    if (month <= 11) {
      const nextmonthF = month + 1
      setNextmonth(nextmonthF)
    } if (month === 12) {
      setNextmonth(1)
    }
  }, [month])


  useEffect(() => {

    const yearf = year
    const monthf = month
    const firstDayF = new Date(yearf, monthf - 1, 1)

    setFirstDay(firstDayF.toString()[0] + firstDayF.toString()[1] + firstDayF.toString()[2])
  }, [month])



  useEffect(() => {
    function getDaysInMonth(y, m) {
      return new Date(y, m, 0).getDate();
    }
    setMonthDays(getDaysInMonth(year, month))
  }, [month])



  useEffect(() => {
    if (dateList.length === 0) {
      setFrom(dateList)
    } if (dateList.length > 1) {
      setFrom(dateList[0])
      setTo(dateList[dateList.length - 1])
    }
  }, [dateList])



  const changeCheckIn = () => {
    if (dateList.length >= 1) {
      removeSelection()
    }

  }
  useEffect(() => { // This useEffect updates the state daysBooked, that returns the total of days selected by the user.
    const a = to.day - from.day;

    if (a < 0) {
      return setDaysBooked(a + from.daysInMonth)
    } if (a > 0 && from.month < to.month && from.year === to.year) {
      return setDaysBooked(a + from.daysInMonth)
    } if (a > 0 && from.month > to.month && from.year != to.year) {
      return setDaysBooked(a + from.daysInMonth)
    } else {
      return setDaysBooked(to.day - from.day)
    }
  }, [to])


  function setmf(a, b) {
    if (a.length != 0) {
      b(a.find(e => e.id == from.month)) 

    }
  }

  function setmfNM(a, b) {
    // let mon = from.year === to.year ? to.month : 1

    if (a.length != 0) {
      b(a.find(e => e.id == to.month))

    }
  }

 


  useEffect(() => {
      room1Months.length != 0 ? setmf(room1Months, setM1) : <></>
      room2Months.length != 0 ? setmf(room2Months, setM2) : <></>
      room3Months.length != 0 ? setmf(room3Months, setM3) : <></>

      room4Months.length != 0 ? setmf(room4Months, setM4) : <></>

      room5Months.length != 0 ? setmf(room5Months, setM5) : <></>

      room6Months.length != 0 ? setmf(room6Months, setM6) : <></>

      room7Months.length != 0 ? setmf(room7Months, setM7) : <></>

      room8Months.length != 0 ? setmf(room8Months, setM8) : <></>

      room9Months.length != 0 ? setmf(room9Months, setM9) : <></>

      room10Months.length != 0 ? setmf(room10Months, setM10) : <></>

      room11Months.length != 0 ? setmf(room11Months, setM11) : <></>

      room12Months.length != 0 ? setmf(room12Months, setM12) : <></>

      room13Months.length != 0 ? setmf(room13Months, setM13) : <></>

      room14Months.length != 0 ? setmf(room14Months, setM14) : <></>

      room15Months.length != 0 ? setmf(room15Months, setM15) : <></>


  }, [from])

  
  useEffect(() => {

    //These variables contains the days of the current month and the following
    // if (from.year === to.year) {
      
    //   room1MonthsNY.length != 0 ? setmf(room1MonthsNY, setM1nextMonth) : <></>
    //   room2MonthsNY.length != 0 ? setmf(room2MonthsNY, setM2nextMonth) : <></>
    //   room3MonthsNY.length != 0 ? setmf(room3MonthsNY, setM3nextMonth) : <></>
    //   room4MonthsNY.length != 0 ? setmf(room4MonthsNY, setM4nextMonth) : <></>
    //   room5MonthsNY.length != 0 ? setmf(room5MonthsNY, setM5nextMonth) : <></>
    //   room6MonthsNY.length != 0 ? setmf(room6MonthsNY, setM6nextMonth) : <></>
    //   room7MonthsNY.length != 0 ? setmf(room7MonthsNY, setM7nextMonth) : <></>
    //   room8MonthsNY.length != 0 ? setmf(room8MonthsNY, setM8nextMonth) : <></>
    //   room9MonthsNY.length != 0 ? setmf(room9MonthsNY, setM9nextMonth) : <></>
    //   room10MonthsNY.length != 0 ? setmf(room10MonthsNY, setM10nextMonth) : <></>
    //   room11MonthsNY.length != 0 ? setmf(room11MonthsNY, setM11nextMonth) : <></>
    //   room12MonthsNY.length != 0 ? setmf(room12MonthsNY, setM12nextMonth) : <></>
    //   room13MonthsNY.length != 0 ? setmf(room13MonthsNY, setM13nextMonth) : <></>
    //   room14MonthsNY.length != 0 ? setmf(room14MonthsNY, setM14nextMonth) : <></>
    //   room15MonthsNY.length != 0 ? setmf(room15MonthsNY, setM15nextMonth) : <></>

    // } else {

      room1MonthsNY.length != 0 ? setmfNM(room1MonthsNY, setM1nextMonth) : <></>
      room2MonthsNY.length != 0 ? setmfNM(room2MonthsNY, setM2nextMonth) : <></>
      room3MonthsNY.length != 0 ? setmfNM(room3MonthsNY, setM3nextMonth) : <></>
      room4MonthsNY.length != 0 ? setmfNM(room4MonthsNY, setM4nextMonth) : <></>
      room5MonthsNY.length != 0 ? setmfNM(room5MonthsNY, setM5nextMonth) : <></>
      room6MonthsNY.length != 0 ? setmfNM(room6MonthsNY, setM6nextMonth) : <></>
      room7MonthsNY.length != 0 ? setmfNM(room7MonthsNY, setM7nextMonth) : <></>
      room8MonthsNY.length != 0 ? setmfNM(room8MonthsNY, setM8nextMonth) : <></>
      room9MonthsNY.length != 0 ? setmfNM(room9MonthsNY, setM9nextMonth) : <></>
      room10MonthsNY.length != 0 ? setmfNM(room10MonthsNY, setM10nextMonth) : <></>
      room11MonthsNY.length != 0 ? setmfNM(room11MonthsNY, setM11nextMonth) : <></>
      room12MonthsNY.length != 0 ? setmfNM(room12MonthsNY, setM12nextMonth) : <></>
      room13MonthsNY.length != 0 ? setmfNM(room13MonthsNY, setM13nextMonth) : <></>
      room14MonthsNY.length != 0 ? setmfNM(room14MonthsNY, setM14nextMonth) : <></>
      room15MonthsNY.length != 0 ? setmfNM(room15MonthsNY, setM15nextMonth) : <></> 

  }, [room1MonthsNY])

  



  useEffect(() => {


    if (daysBooked === 0 || daysBooked === NaN) {
      return
    } if (daysBooked >= 1) {
      // This variable contains the range of days selected.
      const daysBookedArr = Array(daysBooked).fill(from.day).map((x, y) => x + y)
      const daysOfNextMonthFilt = daysBookedArr.filter(e => e > from.daysInMonth)
      return setDaysOfThisMonth(daysBookedArr.filter(e => e <= from.daysInMonth)), SetDaysOfNextMonth(daysOfNextMonthFilt.map(e => e - from.daysInMonth))
    }

  }, [daysBooked])

  useEffect(() => {
    const fullto = { day: to.day, month: to.month, year: to.year }
    const fullfrom = { day: from.day, month: from.month, year: from.year }

    // This variable contains the range of days selected.
    const daysBookedArr = Array(daysBooked).fill(fullfrom.day).map((x, y) => x + y)
    const daysOfNextMonthFilt = daysBookedArr.filter(e => e > from.daysInMonth)

    const daysFirstMonth = daysBookedArr.filter(e => e <= from.daysInMonth)
    const daysSecondMonth = daysOfNextMonthFilt.map(e => e - to.daysInMonth)

    const daysObj = daysFirstMonth.map((e) => {
      return { day: "d" + e, month: from.month, year: from.year }
    })

    const daysObjSecond = daysSecondMonth.map((e) => {
      return { day: "d" + e, month: to.month, year: to.year }
    })

    setAllDaysBooked(daysObj.concat(daysObjSecond))


 
    // This variable contains the range of days selected.
    const daysBookedArr2 = Array(daysBooked).fill(fullfrom.day + 1).map((x, y) => x + y)
    const daysOfNextMonthFilt2 = daysBookedArr.filter(e => e >= from.daysInMonth)

    const daysFirstMonth2 = daysBookedArr2.filter(e => e <= from.daysInMonth)
    const daysSecondMonth2 = daysOfNextMonthFilt2.map(e => e - from.daysInMonth)
    const arr = [fullfrom.day].concat(daysFirstMonth2)

    const daysObj2 = arr.map((e) => {
      return { day: "d" + e, month: from.month, year: from.year }
    })

    const daysObjSecond2 = daysSecondMonth2.map((e) => {
      return { day: "d" + e, month: to.month, year: to.year }
    })

    const concat = daysObj2.concat(daysObjSecond2)
    setAllDaysBooked2(concat.filter(e => e.day != "d0" ))

  }, [daysOfNextMonth])

 
  useEffect(() => {


    if (daysOfThisMonth.length >= 1 || (daysOfThisMonth.concat(daysOfNextMonth)).length >= 1) {
      // This function gets the data from availability of each room of each day in the range showed above.
      setRoom1availability(daysOfThisMonth.map(e => m1["d" + e]))
      setRoom2availability(daysOfThisMonth.map(e => m2["d" + e]))
      setRoom3availability(daysOfThisMonth.map(e => m3["d" + e]))
      setRoom4availability(daysOfThisMonth.map(e => m3["d" + e]))
      setRoom5availability(daysOfThisMonth.map(e => m3["d" + e]))
      setRoom6availability(daysOfThisMonth.map(e => m3["d" + e]))
      setRoom7availability(daysOfThisMonth.map(e => m3["d" + e]))
      setRoom8availability(daysOfThisMonth.map(e => m3["d" + e]))
      setRoom9availability(daysOfThisMonth.map(e => m3["d" + e]))
      setRoom10availability(daysOfThisMonth.map(e => m3["d" + e]))
      setRoom11availability(daysOfThisMonth.map(e => m3["d" + e]))
      setRoom12availability(daysOfThisMonth.map(e => m3["d" + e]))
      setRoom13availability(daysOfThisMonth.map(e => m3["d" + e]))
      setRoom14availability(daysOfThisMonth.map(e => m3["d" + e]))
      setRoom15availability(daysOfThisMonth.map(e => m3["d" + e]))

      // This function gets the data from availability of each room of each day in the range showed above.
      setRoom1availabilityNM(daysOfNextMonth.map(e => m1nextMonth["d" + e]))
      setRoom2availabilityNM(daysOfNextMonth.map(e => m2nextMonth["d" + e]))
      setRoom3availabilityNM(daysOfNextMonth.map(e => m3nextMonth["d" + e]))
      setRoom4availabilityNM(daysOfNextMonth.map(e => m4nextMonth["d" + e]))
      setRoom5availabilityNM(daysOfNextMonth.map(e => m5nextMonth["d" + e]))
      setRoom6availabilityNM(daysOfNextMonth.map(e => m6nextMonth["d" + e]))
      setRoom7availabilityNM(daysOfNextMonth.map(e => m7nextMonth["d" + e]))
      setRoom8availabilityNM(daysOfNextMonth.map(e => m8nextMonth["d" + e]))
      setRoom9availabilityNM(daysOfNextMonth.map(e => m9nextMonth["d" + e]))
      setRoom10availabilityNM(daysOfNextMonth.map(e => m10nextMonth["d" + e]))
      setRoom11availabilityNM(daysOfNextMonth.map(e => m11nextMonth["d" + e]))
      setRoom12availabilityNM(daysOfNextMonth.map(e => m12nextMonth["d" + e]))
      setRoom13availabilityNM(daysOfNextMonth.map(e => m13nextMonth["d" + e]))
      setRoom14availabilityNM(daysOfNextMonth.map(e => m14nextMonth["d" + e]))
      setRoom15availabilityNM(daysOfNextMonth.map(e => m15nextMonth["d" + e]))

    } else {
      return
    }

  }, [daysOfNextMonth, m1nextMonth])



  function forBook() {
    if(dateList.length === 2) {
      const fullRoom1Avai = room1availability.concat(room1availabilityNM)
      const fullRoom2Avai = room2availability.concat(room2availabilityNM)
      const fullRoom3Avai = room3availability.concat(room3availabilityNM)
      const fullRoom4Avai = room4availability.concat(room4availabilityNM)
      const fullRoom5Avai = room5availability.concat(room5availabilityNM)
      const fullRoom6Avai = room6availability.concat(room6availabilityNM)
      const fullRoom7Avai = room7availability.concat(room7availabilityNM)
      const fullRoom8Avai = room8availability.concat(room8availabilityNM)
      const fullRoom9Avai = room9availability.concat(room9availabilityNM)
      const fullRoom10Avai = room10availability.concat(room10availabilityNM)
      const fullRoom11Avai = room11availability.concat(room11availabilityNM)
      const fullRoom12Avai = room12availability.concat(room12availabilityNM)
      const fullRoom13Avai = room13availability.concat(room13availabilityNM)
      const fullRoom14Avai = room14availability.concat(room14availabilityNM)
      const fullRoom15Avai = room15availability.concat(room15availabilityNM)
      setRoom1ForBook(fullRoom1Avai.every(e => (e === true || e >= guests) && rooms.find(e => e.id === "room1").beds >= guests))
  
  
      if (rooms[1] != undefined) {
        setRoom2ForBook(fullRoom2Avai.every(e => (e === true || e >= guests) &&  rooms.find(e => e.id === "room2").beds >= guests))
      }
  
      if (rooms[2] != undefined) {
        setRoom3ForBook(fullRoom3Avai.every(e => (e === true || e >= guests) &&  rooms.find(e => e.id === "room3").beds >= guests))
      }
  
      if (rooms[3] != undefined) {
        setRoom4ForBook(fullRoom4Avai.every(e => (e === true || e >= guests) &&  rooms.find(e => e.id === "room4").beds >= guests))
      }
      if (rooms[4] != undefined) {
        setRoom5ForBook(fullRoom5Avai.every(e => (e === true || e >= guests) &&  rooms.find(e => e.id === "room5").beds >= guests))
      }
      if (rooms[5] != undefined) {
        setRoom6ForBook(fullRoom6Avai.every(e => (e === true || e >= guests) &&  rooms.find(e => e.id === "room6").beds >= guests))
      } 
  
      if (rooms[6] != undefined) {
        setRoom7ForBook(fullRoom7Avai.every(e => (e === true || e >= guests) &&  rooms.find(e => e.id === "room7").beds >= guests))
      }
  
      if (rooms[7] != undefined) {
        setRoom8ForBook(fullRoom8Avai.every(e => (e === true || e >= guests) &&  rooms.find(e => e.id === "room8").beds >= guests))
      }
  
      if (rooms[8] != undefined) {
        setRoom9ForBook(fullRoom9Avai.every(e => (e === true || e >= guests) &&  rooms.find(e => e.id === "room9").beds >= guests))
      }
  
      if (rooms[9] != undefined) {
        setRoom10ForBook(fullRoom10Avai.every(e => (e === true || e >= guests) &&  rooms.find(e => e.id === "room10").beds >= guests))
      }
  
      if (rooms[10] != undefined) {
        setRoom11ForBook(fullRoom11Avai.every(e => (e === true || e >= guests) &&  rooms.find(e => e.id === "room11").beds >= guests))
      }
      if (rooms[11] != undefined) {
        setRoom12ForBook(fullRoom12Avai.every(e => (e === true || e >= guests) &&  rooms.find(e => e.id === "room12").beds >= guests))
      }
      
      if (rooms[12] != undefined) {
        setRoom13ForBook(fullRoom13Avai.every(e => (e === true || e >= guests) &&  rooms.find(e => e.id === "room13").beds >= guests))
      }
  
      if (rooms[13] != undefined) {
        setRoom14ForBook(fullRoom14Avai.every(e => (e === true || e >= guests) &&  rooms.find(e => e.id === "room14").beds >= guests))
      }
  
      if (rooms[14] != undefined) {
        setRoom15ForBook(fullRoom15Avai.every(e => (e === true || e >= guests) &&  rooms.find(e => e.id === "room15").beds >= guests))
      }
  
      setLoader(true)
      setHideCalendar(true)
      setSearchIn(true)
      setStep1(true)
      setLine1(true)
    } else {
      
    }

   
  }


  function showCalendar() {
    setHideCalendar(false)
  }

  ////////////////// MONTH BUTTONS

  const monthForward = () => { // THIS FUNCTION

    setMonth(month + 1)

    if (month === 12) {
      setMonth(1)
      setYear(year + 1)
    }
  }
  const monthBackwards = () => {

    const newDay = new Date();

    const currentMonth = newDay.getMonth() + 1;
    const currentYear = newDay.getFullYear();


    setMonth(month - 1)
    if (month === 1) {
      setMonth(12)
      setYear(year - 1)
    } if (month === currentMonth && year === currentYear) {
      setMonth(month)
    }
  }


  const removeSelectionButton = () => {

    removeSelection()
    setRoom1ForBook(false)
    setRoom2ForBook(false)
    setRoom3ForBook(false)
    setRoom4ForBook(false)
    setRoom5ForBook(false)
    setRoom6ForBook(false)
    setRoom7ForBook(false)
    setRoom8ForBook(false)
    setRoom9ForBook(false)
    setRoom10ForBook(false)
    setRoom11ForBook(false)
    setRoom12ForBook(false)
    setRoom13ForBook(false)
    setRoom14ForBook(false)
    setRoom15ForBook(false)
    setSearchIn(false)
    setAllDaysBooked2([])
    setDaysBooked([])
    if(renderBook === true) {
      setRenderBook(false)
    } else {
      setRenderBook(true)
    }
  }


  useEffect(() => {

    if (bookOrder === null) {

    } if (bookOrder !== null && bookOrder.length !== 0) {
      setModal(true)
    }
  }, [bookOrder])




  return (
    <>

      {hideCalendar === false ?

        <div className='yearAndMontCont' >

          <p className='year'> {year} </p>
          <div className='MonthButtonsCont'>  <button className='MonthButton' onClick={
            monthBackwards}>〈</button> <p>{month === 1 ? "ENERO" : month === 2 ? "FEBRERO" : month === 3 ? "MARZO" : month === 4 ? "ABRIL" : month === 5 ? "MAYO" : month === 6 ? "JUNIO" : month === 7 ? "JULIO" : month === 8 ? "AGOSTO" : month === 9 ? "SEPTIEMBRE" : month === 10 ? "OCTUBRE" : month === 11 ? "NOVIEMBRE" : month === 12 ? "DICIEMBRE" : <></>}</p>  <button className='MonthButton' onClick={monthForward}>〉</button></div>

          {calendarloader === true ? <div className="loader"> <Waveform
            size={40}
            lineWeight={3.5}
            speed={1}
            color="black"
          /> </div> : <>

            <div>

              <BookList renderBook={renderBook} admin={admin} room1Months={room1Months} room2Months={room2Months} room3Months={room3Months} m1={m1} m2={m2} m3={m3} m1nextMonth={m1nextMonth} m2nextMonth={m2nextMonth} m3nextMonth={m3nextMonth} daysOfThisMonth={daysOfThisMonth} daysOfNextMonth={daysOfNextMonth} nextmonth={nextmonth} daysInCurrentMonth={daysInCurrentMonth} year={year} month={month} to={to} from={from} firstDay={firstDay} monthDays={monthDays} daysBooked={daysBooked} room1MonthsNY={room1MonthsNY} room2MonthsNY={room2MonthsNY} room3MonthsNY={room3MonthsNY} allDaysBooked={allDaysBooked2} searchIn={searchIn} customerId={customerId} room4Months={room4Months} room4MonthsNY={room4MonthsNY} m4={m4}  
room5Months={room5Months} room5MonthsNY={room5MonthsNY} m5={m5}  
room6Months={room6Months} room6MonthsNY={room6MonthsNY} m6={m6}  
room7Months={room7Months} room7MonthsNY={room7MonthsNY} m7={m7}  
room8Months={room8Months} room8MonthsNY={room8MonthsNY} m8={m8}  
room9Months={room9Months} room9MonthsNY={room9MonthsNY} m9={m9} 
room10Months={room10Months} room10MonthsNY={room10MonthsNY} m10={m10} 
room11Months={room11Months} room11MonthsNY={room11MonthsNY} m11={m11} 
room12Months={room12Months} room12MonthsNY={room12MonthsNY} m12={m12} 
room13Months={room13Months} room13MonthsNY={room13MonthsNY} m13={m13} 
room14Months={room14Months} room14MonthsNY={room14MonthsNY} m14={m14} 
room15Months={room15Months} room15MonthsNY={room15MonthsNY} m15={m15} 

/>

              {to.newdate !== undefined ? <div className='selectionGuestscont'>

                <p className='textGuests'>HUÉSPEDES <span></span> </p>

                <p className='selectionTextGuests'> {guests} </p>

                <div className='buttonsGuestCont'>
                  <button className='buttonsGuest' onClick={addGuest}>▵</button>
                  <button className='buttonsGuest' onClick={removeGuest}>▿</button>
                </div>

              </div>
                : <div className='selectionGuestscont'>
                  <p className='textGuests'>HUÉSPEDES <span></span> </p>

                  <p className='selectionTextGuests'> - </p>

                  <div className='buttonsGuestCont'>
                    <button className='buttonsGuest' >▵</button>
                    <button className='buttonsGuest' >▿</button>
                  </div>

                </div>
              }
              <SearchButton forBook={forBook} removeSelectionButton={removeSelectionButton} /> </div>    </>
          }
        </div>
        :
        <>

          <BookBar from={from} to={to} showCalendar={showCalendar} removeSelectionButton={removeSelectionButton} changeCheckIn={changeCheckIn} forbook1={room1ForBook} forbook2={room2ForBook} forbook3={room3ForBook} searchIn={searchIn} step1={step1} step2={step2} step3={step3} line1={line1} line2={line2} />
        </>}


      {loader === true ? <div className="loader"> <Waveform
        size={40}
        lineWeight={3.5}
        speed={1}
        color="white"
      /> </div> :

        <>

          <RoomCardsListCont guests={guests} forbook1={room1ForBook} forbook2={room2ForBook} forbook3={room3ForBook} forbook4={room4ForBook} forbook5={room5ForBook} forbook6={room6ForBook} forbook7={room7ForBook} forbook8={room8ForBook} forbook9={room9ForBook} forbook10={room10ForBook} forbook11={room11ForBook} forbook12={room12ForBook} forbook13={room13ForBook} forbook14={room14ForBook} forbook15={room15ForBook}  searchIn={searchIn} step1={step1} step2={step2} step3={step3} line1={line1} line2={line2} daysBooked={daysBooked} admin={admin} customerId={customerId} />
        </>
      }

      {modal === false ? <></> : <BookedOrderModal modal={modal} from={from} to={to} />}


    </>
  )
}


import React, { useState, createContext, useContext, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { getStorage, ref, listAll, uploadBytes, getDownloadURL } from "firebase/storage";


const RoomsContext = createContext([])

export const useRoomsContext = () => useContext(RoomsContext)


const RoomsContextProvider = ({ children }) => {


    const db = getFirestore()
    const [rooms, setRooms] = useState([])
    const [render, setRender] = useState(false)
    const [contextRooms, setContextRooms] = useState([])


    const [room1Book, setRoom1Book] = useState(false)
    const [room2Book, setRoom2Book] = useState(false)
    const [room3Book, setRoom3Book] = useState(false)

    const [newguest1, setNewguest1] = useState([])
    const [newguest2, setNewguest2] = useState([])
    const [newguest3, setNewguest3] = useState([])
    const [newguest4, setNewguest4] = useState([])
    const [newguest5, setNewguest5] = useState([])
    const [newguest6, setNewguest6] = useState([])
    const [newguest7, setNewguest7] = useState([])
    const [newguest8, setNewguest8] = useState([])
    const [newguest9, setNewguest9] = useState([])
    const [newguest10, setNewguest10] = useState([])



    useEffect(() => {
        const QueryCollection = collection(db, `clients/${contextRooms[0]}/rooms`)
        getDocs(QueryCollection)
            .then(resp => setRooms(resp.docs.map(room => ({ id: room.id, ...room.data() }))))
            .catch((err) => console.log(err))

    }, [render])




    const rerender = () => {
        if (render === false) {
            setRender(true)
        } else {
            setRender(false)
        }
    }

    const rerenderF = () => {
        if (render === false) {
            setRender(true)
        } else {
            setRender(false)
        }
    }




    const [allImages, setAllImages] = useState([])
    const [allImagesLinks, setAllImagesLinks] = useState([])

    const storage = getStorage();



    // Create a reference under which you want to list
    const listRef = ref(storage, 'clients/holdear/rooms/room1');

    // Find all the prefixes and items.
    listAll(listRef)
        .then((res) => {
            res.prefixes.forEach((folderRef) => {

                // All the prefixes under listRef.
                // You may call listAll() recursively on them.
            });
            res.items.forEach((itemRef) => {
                allImages.push(itemRef)
                // All the items under listRef.
            });
        }).catch((error) => {
            // Uh-oh, an error occurred!
        });



    useEffect(() => {
        allImages.forEach(e => {
            const storageRef = ref(storage, `clients/${contextRooms[0]}/rooms/room1/${e.name}`)
            getDownloadURL(storageRef)
                .then(url => allImagesLinks.push(`${url}`))
                .catch((error) => { console.log(error) })
        } )

    }, [allImages, contextRooms])


    return (
        <RoomsContext.Provider value={{
            contextRooms,
            rooms,
            room1Book,
            room2Book,
            room3Book,
            newguest1,
            newguest2,
            newguest3,
            newguest4,
            newguest5,
            newguest6,
            newguest7,
            newguest8,
            newguest9,
            newguest10,
            rerender,
            rerenderF,
            allImagesLinks,


        }}>
            {children}

        </RoomsContext.Provider>
    )

}

export default RoomsContextProvider





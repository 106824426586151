import React, { useState } from 'react'
import { getFirestore, doc, writeBatch } from 'firebase/firestore';
import { useRoomsContext } from '../../context/RoomsContext'
import { LineWobble } from '@uiball/loaders'


export default function CheckInGuestDetail({ guest, checkout, idx, guestid, customer }) {
    const db = getFirestore()
    const batch = writeBatch(db)
    const { rooms, newguest1, newguest2, newguest3, newguest4, newguest5, newguest6, newguest7, newguest8, newguest9, newguest10, rerender } = useRoomsContext()

    const [guestdetailsopen, setGuestdetailsopen] = useState(false)
    const [loader, setLoader] = useState(true)
    const [deleting, setDeleting] = useState(false)
    const [deleted, setDeleted] = useState(false)

    const [newname, setNewname] = useState("")
    const [newid, setNewid] = useState(Number)
    const [newidType, setNewidType] = useState("")
    const [newemail, setNewemail] = useState("")
    const [newphone, setNewphone] = useState(Number)
    const [newnationality, setNewnationality] = useState("")
    const [newcheckinDate, setNewcheckinDate] = useState("")
    const [newcheckoutDate, setNewcheckoutDate] = useState("")
    const [newpayment, setNewpayment] = useState(Boolean)


    const openGuest = () => {
        if (guestdetailsopen === false) {
            setGuestdetailsopen(true)

        } else { setGuestdetailsopen(false) }
    }

    const cards = new Promise((resolve, reject) => {
        setTimeout(() => { resolve([]); }, 1000);
    });

    cards.then(() => {
        setLoader(false);
    });




    /// BATCH CHECK OUT
    const checkoutF = async () => {

        setDeleting(true)

        setTimeout(() => {
            setDeleting(false)
            setDeleted(true)
            rerender()
        }, 2000);

        let guestx = {}

        guestx.name = newname
        guestx.id = Number(newid)
        guestx.idType = newidType
        guestx.email = newemail
        guestx.phone = Number(newphone)
        guestx.nationality = newnationality
        guestx.checkinDate = newcheckinDate
        guestx.checkoutDate = newcheckoutDate
        guestx.payment = newpayment

        let guestxx = new Array(guestx)
        const queryCol = doc(db, `clients`, `${customer}`, `rooms`, `${idx}`)

        batch.update(queryCol, { [guestid]: guestxx })
        batch.commit()


    }

    return (
        <>

            {loader === true ? <></> :

                guest[0].id != 0 ?

                    <div className='guestDetailCont'>


                        <div className='guestDataTop'>
                            <div className='guestDataTopLeft'>
                                <p className='guestText'>
                                    {guest[0].name}
                                </p>
                            </div>

                            <div className='ngdateTextCont'>
                                <p className='guestdateText in'>
                                    IN: {guest[0].checkinDate}
                                </p>

                                <p className='guestdateText out'>
                                    OUT: {guest[0].checkoutDate}
                                </p>
                            </div>
                            <button className='guestdetailbutton' onClick={openGuest} ><p className={guestdetailsopen === false ? 'leftBar' : 'leftBarOpened'}>|</p> <p className={guestdetailsopen === false ? 'righBar' : 'righBarOpened'}>|</p> <p className={guestdetailsopen === false ? 'leftBar' : 'displayNone'}>|</p>
                            </button>
                        </div>
                        <div className={guestdetailsopen === true ? "guestdetailExtrainfo" : "displayNone"}>
                            <p className='guestDetailMiniText'> <b className='guestTopics'>Documento:</b> {guest[0].id}</p>
                            <p className='guestDetailMiniText'><b className='guestTopics'>Doc. tipo:</b> {guest[0].idType}</p>
                            <p className='guestDetailMiniText'><b className='guestTopics'>Teléfono:</b> {guest[0].phone}</p>
                            <p className='guestDetailMiniText'><b className='guestTopics'>Email:</b> {guest[0].email}</p>
                            <p className='guestDetailMiniText'><b className='guestTopics'>Nacionalidad:</b> {guest[0].nationality}</p>
                            <p className='guestDetailMiniText'> <b className='guestTopics'>Pago:</b> {guest[0].payment}</p>

                            {deleting === true ?
                                <div className='checkedout'>
                                    <LineWobble
                                        size={80}
                                        lineWeight={5}
                                        speed={1.75}
                                        color="white" />
                                </div> : deleted === true ? <p className='checkedout'> Checked-out! </p> : <button className='newcheckoutbutton' onClick={checkoutF} > <img className='checkoutTrashCan' src="https://img.icons8.com/material-sharp/96/000000/waste.png" /> </button>

                            }

                        </div>
                    </div>
                    : <></>
            }

        </>

    )
}

import React, { useState, useEffect } from 'react';
import { getFirestore } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { useRoomsContext } from '../../context/RoomsContext'
import NavbarPanel from '../NavbarPanel';
import './RoomsEditCont.css';
import RoomsEditDetail from './RoomsEditDetail';
import { useLoginContext } from '../../context/LoginContext'
import Login from '../login/Login';

export default function RoomsEditDetailCont() {

    const { customer, roomedit } = useParams()
    const { rooms, contextRooms, rerender } = useRoomsContext()
    const [exists, setExists] = useState()
    const [id, setId] = useState()
    const [roomname, setRoomname] = useState("")
    const [description, setDescription] = useState("")
    const [beds, setBeds] = useState(0)
    const [type, setType] = useState("")
    const [enabled, setEnabled] = useState(true)
    const [price, setPrice] = useState(0)
    const [size, setSize] = useState(0)
    const [img, setImg] = useState()
    const [img1, setImg1] = useState()
    const [img2, setImg2] = useState()
    const [img3, setImg3] = useState()
    const [img4, setImg4] = useState()
    const [img5, setImg5] = useState()
    const [img6, setImg6] = useState()
    const [img7, setImg7] = useState()
    const [img8, setImg8] = useState()
    const [img9, setImg9] = useState()
    const [monthAdded, setMonthAdded] = useState(false)
    const [render, setRender] = useState(false)
    const { loginCheck } = useLoginContext()

    const renderMonthAdded = () => {
        if (monthAdded === true) {
            setMonthAdded(false)
        } else {
            setMonthAdded(true)
        }
    }


    useEffect(() => {
        contextRooms.push(customer)
    }, [render])



    //TOMA EL ID DE LA ORDEN Y GUARDA UN ESTADO QUE CONTIENE SOLO LA ORDEN CORRESPONDIENTE AL ID y SUS ITEMS

    useEffect(() => {
        let roomfind = rooms.find(roo => roo.id == roomedit)
        let roommap = rooms.map((roo) => roo.id)


        if (roomfind === undefined) {
            return setExists("Doesn't exists")
        } else {
            return setExists("Exists"), setId(roomfind.id), setRoomname(roomfind.roomname), setDescription(roomfind.description), setBeds(roomfind.beds), setType(roomfind.type), setEnabled(roomfind.enabled), setPrice(roomfind.price), setSize(roomfind.size), setImg(roomfind.img), setImg1(roomfind.img1), setImg2(roomfind.img2), setImg3(roomfind.img3), setImg4(roomfind.img4), setImg5(roomfind.img5), setImg6(roomfind.img6), setImg7(roomfind.img7), setImg8(roomfind.img8), setImg9(roomfind.img9)
        }
    }, [rooms, render])


    const renderize = () => {
        if (render === true) {
            setRender(false)
        } else {
            setRender(true)

        }

    }

    const [loginAnimation, setLoginAnimation] = useState(false)

    useEffect(() => {
        setLoginAnimation(true)

        setTimeout(() => {
            setLoginAnimation(false)

        }, 2000);
    }, [])


    return (
        <>
            {loginCheck != true ?

                <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>
                    <p className='logoMicrositeLoading' >HOLDE <span className='logobackMicrosite'>.ar</span></p>

                    <Login customer={customer} />
                </div>
                :
                <>
                    <div className='dashboardCont'>
                        <NavbarPanel customer={customer} />
                        <p className='sectionTittles'>| EDITING ROOM </p>

                        <RoomsEditDetail render={renderize} renderized={render} customer={customer} rooms={rooms} id={id} roomname={roomname} description={description} beds={beds} type={type} enabled={enabled} price={price} size={size} img={img} img1={img1} img2={img2} img3={img3} img4={img4} img5={img5} img6={img6} img7={img7} img8={img8} img9={img9} rerender={rerender} />
                    </div>
                </>}</>
    )
}

import React, { useEffect, useState } from 'react';
import Book from './Book';

export default function BookList({ customerId, renderBook, room1Months, room2Months, room3Months, year, month, from, to, firstDay, monthDays, daysBooked, m1, m2, m3, room1MonthsNY, room2MonthsNY, room3MonthsNY, allDaysBooked, searchIn, admin, weekCalendar, daysInCurrentMonth, room4Months, m4, room4MonthsNY, room5Months, m5, room5MonthsNY, room6Months, m6, room6MonthsNY, room7Months, m7, room7MonthsNY, room8Months, m8, room8MonthsNY, room9Months, m9, room9MonthsNY, room10Months, m10, room10MonthsNY, room11Months, m11, room11MonthsNY, room12Months, m12, room12MonthsNY, room13Months, m13, room13MonthsNY, room14Months, m14, room14MonthsNY, room15Months, m15, room15MonthsNY}) {
  

  const [daysLoop, setDaysLoop] = useState([])



  useEffect(() => {
    const days = []

    for (var i = 1; i <= monthDays; i++) {
      days.push(i);
    }
    setDaysLoop(days)

  }, [monthDays])



  return (
    <>
      <div className={weekCalendar === true ? 'BookListContWeek' : 'BookListCont'}>

        <div className={weekCalendar === true ? 'displayNone' : 'BookListMonth'}>
          <p className='monthbox'>LUN</p>
          <p className='monthbox'>MAR</p>
          <p className='monthbox'>MIE</p>
          <p className='monthbox'>JUE</p>
          <p className='monthbox'>VIE</p>
          <p className='monthbox'>SAB</p>
          <p className='monthbox'>DOM</p>
        </div>

        <div className={weekCalendar === true ? 'BookListWeek' : 'BookListMonth'}>  


          {firstDay == "Tue" ? <>   <p className='pointbox'>•</p> </> : <></>}

          {firstDay == "Wed" ? <>   <p className='pointbox'>•</p> <p className='pointbox'>•</p> </> : <></>}

          {firstDay == "Thu" ? <>   <p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p>  </> : <></>}

          {firstDay == "Fri" ? <>  <p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p> </> : <></>}

          {firstDay == "Sat" ? <>   <p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p> </> : <></>}

          {firstDay == "Sun" ? <>   <p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p> </> : <></>}

 
          {daysLoop.map((dayloop) =>

         
            <Book renderBook={renderBook} key={dayloop} day={dayloop} customerId={customerId} year={year} month={month} room1Months={room1Months} room2Months={room2Months} room3Months={room3Months} from={from} to={to} m1={m1} m2={m2} m3={m3} room1MonthsNY={room1MonthsNY} room2MonthsNY={room2MonthsNY} room3MonthsNY={room3MonthsNY} allDaysBooked={allDaysBooked} daysLoop={daysLoop} searchIn={searchIn} admin={admin} weekCalendar={weekCalendar} daysInCurrentMonth={daysInCurrentMonth} room4Months={room4Months} room4MonthsNY={room4MonthsNY} m4={m4}  
            room5Months={room5Months} room5MonthsNY={room5MonthsNY} m5={m5}  
            room6Months={room6Months} room6MonthsNY={room6MonthsNY} m6={m6}  
            room7Months={room7Months} room7MonthsNY={room7MonthsNY} m7={m7}  
            room8Months={room8Months} room8MonthsNY={room8MonthsNY} m8={m8}  
            room9Months={room9Months} room9MonthsNY={room9MonthsNY} m9={m9} 
            room10Months={room10Months} room10MonthsNY={room10MonthsNY} m10={m10} 
            room11Months={room11Months} room11MonthsNY={room11MonthsNY} m11={m11} 
            room12Months={room12Months} room12MonthsNY={room12MonthsNY} m12={m12} 
            room13Months={room13Months} room13MonthsNY={room13MonthsNY} m13={m13} 
            room14Months={room14Months} room14MonthsNY={room14MonthsNY} m14={m14} 
            room15Months={room15Months} room15MonthsNY={room15MonthsNY} m15={m15} />
          )}



        </div>
      </div>

 
    </>
  )
}

import React, { useState, useEffect } from 'react'

export default function PricingButton({ text, pushSelection, paymentData, type }) {

   const [selected, setSelected] = useState()
   const [textf, setTextf] = useState()


   useEffect(() => {
      let extraDayData = paymentData.find(e => e.id === "extraDays")
      let extraMonthData = paymentData.find(e => e.id === "extraMonths")

      if (text == "lunes") {
         setTextf("mon")
         setSelected(extraDayData.mon)
      } else if (text == "martes") {
         setTextf("tue")
         setSelected(extraDayData.tue)
      } else if (text == "miércoles") {
         setTextf("wed")
         setSelected(extraDayData.wed)
      } else if (text == "jueves") {
         setTextf("thu")
         setSelected(extraDayData.thu)
      } else if (text == "viernes") {
         setTextf("fri")
         setSelected(extraDayData.fri)
      } else if (text == "sábado") {
         setTextf("sat")
         setSelected(extraDayData.sat)
      } else if (text == "domingo") {
         setTextf("sun")
         setSelected(extraDayData.sun)
      } else if (text == "enero") {
         setTextf("january")
         setSelected(extraMonthData.january)
      } else if (text == "febrero") {
         setTextf("february")
         setSelected(extraMonthData.february)
      } else if (text == "marzo") {
         setTextf("march")
         setSelected(extraMonthData.march)
      } else if (text == "abril") {
         setTextf("april")
         setSelected(extraMonthData.april)
      } else if (text == "mayo") {
         setTextf("may")
         setSelected(extraMonthData.may)
      } else if (text == "junio") {
         setTextf("june")
         setSelected(extraMonthData.june)
      } else if (text == "julio") {
         setTextf("july")
         setSelected(extraMonthData.july)
      } else if (text == "agosto") {
         setTextf("august")
         setSelected(extraMonthData.august)
      } else if (text == "septiembre") {
         setTextf("september")
         setSelected(extraMonthData.september)
      } else if (text == "octubre") {
         setTextf("october")
         setSelected(extraMonthData.october)
      } else if (text == "noviembre") {
         setTextf("november")
         setSelected(extraMonthData.novermber)
      } else if (text == "diciembre") {
         setTextf("december")
         setSelected(extraMonthData.december)
      }
   }, [paymentData, text])


   return (
      <div className='pricingButtonCont'>
         <button className='pricingButtonTextCont amenitiesText'> {text} </button>

         <div className="UpAndDownCont">
            <button className={selected == true ? 'pricingUp pricingGreen' : 'pricingUp'} onClick={() => {
               pushSelection(type, textf, true)
            }} >⇧</button>
            <button className={selected == null ? 'pricingNormal pricingBlue' : 'pricingNormal'} onClick={() => {
               pushSelection(type, textf, null)
            }} >=</button>
            <button className={selected == false ? 'pricingDown pricingRed' : 'pricingDown'} onClick={() => {
               pushSelection(type, textf, false)
            }} >⇩</button>
         </div>

      </div>
   )
}

import React, { useState, useEffect } from 'react'


export default function BookBar({ from, to, showCalendar, removeSelectionButton }) {

    const [admin, setAdmin] = useState(false)

    useEffect(() => {
        setAdmin(JSON.parse(sessionStorage.getItem('adminToken')))
    }, [])


    const showCalendarF = () => {
        showCalendar()
    }

    const removeSelectionButtonF = () => {
        removeSelectionButton()
    }


    return (
        <>
            <button className='backtext' onClick={() => {
                showCalendarF(); removeSelectionButtonF()

            }} >REGRESAR AL CALENDARIO</button>

            <div className='bookbarcont'>

                <div className='bookbarDatecont'>
                    <p>CHECK-IN</p>
                    <div className='bookbarDetailCont'>
                        <img className='bookbarimg' src="https://img.icons8.com/ios-filled/100/000000/calendar--v1.png" />
                        <p>{from.day} / {from.month} / {from.year} </p>
                    </div>
                </div>



                <div className='bookbarDatecont'>

                    <p>CHECK-OUT</p>
                    <div className='bookbarDetailCont'>
                        <img className='bookbarimg' src="https://img.icons8.com/ios-filled/100/000000/calendar--v1.png" />
                        <p>{to.day} / {to.month} / {to.year} </p>

                    </div>

                </div>


            </div>

            {admin === true ? <></> : <p className='bookbarTittle'> ELEGÍ TU HABITACIÓN </p>}




        </>
    )
}

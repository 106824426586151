import React, { useState, useEffect } from 'react'
import { addDoc, collection, getFirestore, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { LineWobble } from '@uiball/loaders'
import NavbarHomepage from './NavbarHomepage';

function Demo({ }) {

  const db = getFirestore()
  const { customer } = useParams()

  const [message, setMessage] = useState()
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [phone, setPhone] = useState()
  const [loader, setLoader] = useState(true)
  const [images, setImages] = useState([])

  useEffect(() => {
    setLoader(true)

    setTimeout(() => {
      setLoader(false)
    }, 1500);
  }, [images])

  const handleSubmit = (event) => {
    event.preventDefault();
  }

  useEffect(() => {

    const QueryCollection = collection(db, `holdear/homepage/app`)

    getDocs(QueryCollection)
      .then(resp => setImages(resp.docs.map(site => ({ id: site.id, ...site.data() }))))
      .catch((err) => console.log(err))


  }, [])


  //ESTA FUNCIÓN ENVÍA EL MENSAJE A FIREBASE, EN "CORREOS"
  //LOS MENSAJES SE VISUALIZAN EN EL PANEL DE ADMINISTRACIÓN
  const sendmessage = () => {
    const date = new Date();

    let mail = {}


    mail.id = parseInt(((Math.random() * 10000000) + (Math.random() * 10000000)) / (Math.random(1, 50)));
    mail.name = name;
    mail.text = message;
    mail.fulldate = { day: date.getDate(), month: (date.getMonth() + 1), year: date.getFullYear() };
    mail.hour = date.getHours() + ":" + date.getMinutes();
    mail.email = email;
    mail.timestamp = Date.now();
    mail.date = Date().substring(0, 24);
    mail.phone = phone;
    mail.status = false;

    const queryCollection = collection(db, `holdear/homepage/messages`)
    addDoc(queryCollection, mail)
      .then(res => console.log(res))
      .catch(err => console.log(err))

  }

  return (

    <>
      {loader === true ?
        <section>
          <div className='headerHome headergradient'>
            <NavbarHomepage />
            <LineWobble size={80} lineWeight={5} speed={1.75} color="white" />
          </div>

        </section>


        :


        <>

          <div className='headerHome headergradient'>
            <NavbarHomepage />
            <p className='sectionTittle'>Así funciona</p>
            <p className='minitextnavbarHome '>Y así se ve.</p>
          </div>

          <div>

            <div >
              <section className='appDiv direction rev '>
                <div className='imgDiv'>
                  <Link className='linkHome' to="/holdear">
                    <p className='headerDetailText demoButton'> Usuario DEMO </p>
                  </Link>

                  <Link className='linkHome' to="/holdear/dashboard">
                    <p className='headerDetailText demoButton'> Administrador DEMO </p>
                  </Link>

                </div>

                <div className='textDiv'>
                  <p className='textTittle'> PROBÁ LA DEMO </p>
                  <p className='textParagraph'> Ingresando en estos links vas a poder visualizar e interactuar con una cuenta HOLDE demo. Además, podés probar cómo se ve nuestro panel de administración.</p>
      
                    <p className='headerDetailText demoButton greenback white'>Credenciales del administrador: holdear (usuario y contraseña).</p>
   
                </div>
              </section>
            </div>


          </div>

        </>
      }
    </>

  );
}

export default Demo;

import React, { useEffect } from 'react';
import OurRoomsList from './OurRoomsList'
import { useParams } from 'react-router-dom';
import { useRoomsContext } from '../context/RoomsContext'

export default function OurRooms() {

    const { customer } = useParams()
    const { rooms, contextRooms } = useRoomsContext()

    useEffect(() => {
        contextRooms.push(customer)
    }, [])

  return (
    <>

    <OurRoomsList  rooms={rooms} customer={customer} />

    </>
  )
}

import React from 'react'

import './Buttons.css';


export default function Backwardsbutton({text, onclick}) {
  return (
    <> 
    <button className='backwardsbutton' onClick={onclick}> {text} </button> 
    </>
  )
}

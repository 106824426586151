import React, { useState, useEffect } from 'react';

export default function RoomsStatusItem({ id, beds, type, roomname, guest1, guest2, guest3, guest4, guest5, guest6, guest7, guest8, guest9, guest10, enabled }) {

  const [guestsNames, setGuestsName] = useState([])
  const guests = [guest1, guest2, guest3, guest4, guest5, guest6, guest7, guest8, guest9, guest10]


  useEffect(() => {
    if (guests.every(e => e === undefined)) {
      return
    } else {
      const guestsNamesArr = guests[0][0].name != undefined ? guests.map(e => e[0].name) : false
      setGuestsName(guestsNamesArr)
    }
  }, [])


  return (
    <div className='ArrivalsItem borderbottompurple roomswidth'>
      <p className='ArrivalsName'>{id.toUpperCase()} | {roomname}</p>
      <p className='minitext'> Camas: {beds} - {type}  </p>
      <p className='minitext'> </p>
      {/* <p className='minitext'>{enabled === true ? "Enabled" : "Disabled" }</p> */}

      {guestsNames != false ?
        <>
          {guestsNames.every(e => e.length === 0) === true ? <p className='nonewdata'>Habitación vacía </p> : <>
            <div className='margintop'>
              <p className='ArrivalsName '> HUÉSPEDES: </p>
              {guestsNames.map((guest) => <>

                {guest.length != 0 ? <p className='minitext'> {guest} </p> : <></>} </>

              )}    </div></>}
        </>
        :        <></>
      }
    </div>

  )
}

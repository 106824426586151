import React, { useState } from 'react';
import { setDoc, doc, getFirestore} from 'firebase/firestore';


export default function PersonalData({ username, nextstep }) {
    const db = getFirestore()
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [address, setAddress] = useState()
    const [cuit, setCuit] = useState()
    const [checkEmailS, setCheckEmailS] = useState()


    const handleSubmit = (event) => {
        event.preventDefault();
    }

    const setPersonalData = () => {

        const emailSplit = email.split("")
        const checkEmail = emailSplit.some(e => e === "@")

        setCheckEmailS(checkEmail)
    

        if(name.length > 2 && email.length > 8 &&  address.length > 6 && cuit.length >= 7 && checkEmail === true) {

        setDoc(doc(db, `clients/${username}/profile`, "data"), {
            name: name,
            email: email,
            address: address,
            cuit: Number(cuit)
        })

        setDoc(doc(db, `clients/${username}/profile`, "login"), {
            username: username,
            password: cuit,
        })

        setDoc(doc(db, `clients/${username}/profile`, "plan"), {
            free: true,
            basic: false,
            pro: false,
            proplus: false
        })

        setTimeout(() => {
            nextstep()
        }, 500);
    }
    }


    return (
        <div className='greenBack3 formSectionCont'>


            <p className='welcometittle'> Hola, {username}! </p>
            <form className='formPersonalDataCont' action="" onSubmit={handleSubmit}>
                <div className='center'>
                    <p className='detailtext'>Nombre completo</p>
                    <input className='personalDataInput' onChange={(e) => setName(e.target.value)} />
                </div>

                <div className='center'>
                    <p className='detailtext'>Email {checkEmailS === false ?  <span className='errorText'> Ingrese un email válido</span> : "" } </p>
                    <input className='personalDataInput' onChange={(e) => setEmail(e.target.value)} />

                </div>

                <div className='center'>
                    <p className='detailtext'>Dirección personal</p>
                    <input className='personalDataInput' onChange={(e) => setAddress(e.target.value)} />
                </div>

                <div className='center'>
                    <p className='detailtext'>DNI / CUIT / CUIL (solo números)</p>
                    <input className='personalDataInput' onChange={(e) => setCuit(e.target.value)} />
                </div>
            </form>


            <button onClick={() => {
              setPersonalData()
            }} className='nextButton'> Siguiente ⤑ </button>

        </div>
    )
}

import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { LineWobble } from '@uiball/loaders'
import './CalendarPanel.css';
import NavbarPanel from '../NavbarPanel';
import WeekCalendarList from './WeekCalendarList';
import { useRoomsContext } from '../../context/RoomsContext'
import { useBookingContext } from '../../context/BookingContext'
import { useLoginContext } from '../../context/LoginContext'


import MicroCalendar from './MicroCalendar';
import Login from '../login/Login';
export default function WeekCalendar() {
  const db = getFirestore()

  const { contextBookings, bookings } = useBookingContext()
  const { contextRooms, rooms, rerenderF } = useRoomsContext()

  const { customer } = useParams()
  useEffect(() => {
    contextBookings.push(customer)
    contextRooms.push(customer)
  }, [])

  useEffect(() => {

    if (rooms.length === 0) {
      rerenderF()
    }
  }, [rooms])

  const location = useLocation()
  const [loader, setLoader] = useState(true)
  const [firstDay, setFirstDay] = useState("")
  const [weekCalendar, setWeekCalendar] = useState(false)
  const [daysInCurrentMonth, setDaysInCurrentMonth] = useState()
  const [month, setMonth] = useState()
  const [year, setYear] = useState()
  const [today, setToday] = useState()
  const [todaysMonth, setTodaysMonth] = useState()
  const [todaysYear, setTodaysYear] = useState()
  const [nextmonth, setNextmonth] = useState()
  const [monthDays, setMonthDays] = useState()
  const [daysLoop, setDaysLoop] = useState([])


  //THESE STATES SAVES ALL THE MONTHS OF THE CURRENTLY YEAR OF EACH ROOM
  const [room1Months, setRoom1Months] = useState([])
  const [room1MonthsNY, setRoom1MonthsNY] = useState([])
  const [m1, setM1] = useState([])


  const [room2Months, setRoom2Months] = useState([])
  const [room2MonthsNY, setRoom2MonthsNY] = useState([])
  const [m2, setM2] = useState([])


  const [room3Months, setRoom3Months] = useState([])
  const [room3MonthsNY, setRoom3MonthsNY] = useState([])
  const [m3, setM3] = useState([])


  const [room4Months, setRoom4Months] = useState([])
  const [room4MonthsNY, setRoom4MonthsNY] = useState([])
  const [m4, setM4] = useState([])


  const [room5Months, setRoom5Months] = useState([])
  const [room5MonthsNY, setRoom5MonthsNY] = useState([])
  const [m5, setM5] = useState([])


  const [room6Months, setRoom6Months] = useState([])
  const [room6MonthsNY, setRoom6MonthsNY] = useState([])
  const [m6, setM6] = useState([])


  const [room7Months, setRoom7Months] = useState([])
  const [room7MonthsNY, setRoom7MonthsNY] = useState([])
  const [m7, setM7] = useState([])


  const [room8Months, setRoom8Months] = useState([])
  const [room8MonthsNY, setRoom8MonthsNY] = useState([])
  const [m8, setM8] = useState([])


  const [room9Months, setRoom9Months] = useState([])
  const [room9MonthsNY, setRoom9MonthsNY] = useState([])
  const [m9, setM9] = useState([])


  const [room10Months, setRoom10Months] = useState([])
  const [room10MonthsNY, setRoom10MonthsNY] = useState([])
  const [m10, setM10] = useState([])


  const [room11Months, setRoom11Months] = useState([])
  const [room11MonthsNY, setRoom11MonthsNY] = useState([])
  const [m11, setM11] = useState([])

  const [room12Months, setRoom12Months] = useState([])
  const [room12MonthsNY, setRoom12MonthsNY] = useState([])
  const [m12, setM12] = useState([])


  const [room13Months, setRoom13Months] = useState([])
  const [room13MonthsNY, setRoom13MonthsNY] = useState([])
  const [m13, setM13] = useState([])


  const [room14Months, setRoom14Months] = useState([])
  const [room14MonthsNY, setRoom14MonthsNY] = useState([])
  const [m14, setM14] = useState([])


  const [room15Months, setRoom15Months] = useState([])
  const [room15MonthsNY, setRoom15MonthsNY] = useState([])
  const [m15, setM15] = useState([])


  // SELECT ROOM TO SEE MONTH DETAIL
  const [roomSelected, setRoomSelected] = useState()
  const [roomSelectedNY, setRoomSelectedNY] = useState()
  const [openDetail, setOpenDetail] = useState(false)


  //LOGIN

  const { loginCheck } = useLoginContext()
  const [loginAnimation, setLoginAnimation] = useState(false)


  useEffect(() => {
    setLoginAnimation(true)

    setTimeout(() => {
      setLoginAnimation(false)


    }, 2000);
  }, [])



  const selectRoom = (a) => {
    setOpenDetail(false)

    setTimeout(() => {
      if (a == "room1") {
        setRoomSelected(room1Months)
        setRoomSelectedNY(room1MonthsNY)
        setOpenDetail(true)

      } if (a == "room2") {
        setRoomSelected(room2Months)
        setRoomSelectedNY(room2MonthsNY)
        setOpenDetail(true)
      } if (a == "room3") {
        setRoomSelected(room3Months)
        setRoomSelectedNY(room3MonthsNY)
        setOpenDetail(true)
      }
    }, 300);


  }

  useEffect(() => {

    const yearf = year
    const monthf = month
    const firstDayF = new Date(yearf, monthf - 1, 1)

    setFirstDay(firstDayF.toString()[0] + firstDayF.toString()[1] + firstDayF.toString()[2])
  }, [month])


  useEffect(() => {

    //These variables contains the days of the current month and the following

    setM1(room1Months.find(e => e.id == month))
    setM2(room2Months.find(e => e.id == month))
    setM3(room3Months.find(e => e.id == month))
    setM4(room4Months.find(e => e.id == month))
    setM5(room5Months.find(e => e.id == month))
    setM6(room6Months.find(e => e.id == month))
    setM7(room7Months.find(e => e.id == month))
    setM8(room8Months.find(e => e.id == month))
    setM9(room9Months.find(e => e.id == month))
    setM10(room10Months.find(e => e.id == month))
    setM11(room11Months.find(e => e.id == month))
    setM12(room12Months.find(e => e.id == month))
    setM13(room13Months.find(e => e.id == month))
    setM14(room14Months.find(e => e.id == month))
    setM15(room15Months.find(e => e.id == month))

  }, [year])

  useEffect(() => {    //THESE FUNCTIONS SAVES ALL THE MONTHS OF THE CURRENTLY YEAR OF EACH ROOM

    function saveMonths(room, set, ny) {
      const queryCol = collection(db, `clients/${customer}/rooms/room${room}/${year}`)

      getDocs(queryCol)
        .then(resp => set(resp.docs.map(room => ({ id: room.id, ...room.data() }))))
        .catch((err) => console.log(err))
    }

    saveMonths(1, setRoom1Months)
    saveMonths(2, setRoom2Months)
    saveMonths(3, setRoom3Months)
    saveMonths(4, setRoom4Months)
    saveMonths(5, setRoom5Months)
    saveMonths(6, setRoom6Months)
    saveMonths(7, setRoom7Months)
    saveMonths(8, setRoom8Months)
    saveMonths(9, setRoom9Months)
    saveMonths(10, setRoom10Months)
    saveMonths(11, setRoom11Months)
    saveMonths(12, setRoom12Months)
    saveMonths(13, setRoom13Months)
    saveMonths(14, setRoom14Months)
    saveMonths(15, setRoom15Months)

    saveMonths(1, setRoom1MonthsNY)
    saveMonths(2, setRoom2MonthsNY)
    saveMonths(3, setRoom3MonthsNY)
    saveMonths(4, setRoom4MonthsNY)
    saveMonths(5, setRoom5MonthsNY)
    saveMonths(6, setRoom6MonthsNY)
    saveMonths(7, setRoom7MonthsNY)
    saveMonths(8, setRoom8MonthsNY)
    saveMonths(9, setRoom9MonthsNY)
    saveMonths(10, setRoom10MonthsNY)
    saveMonths(11, setRoom11MonthsNY)
    saveMonths(12, setRoom12MonthsNY)
    saveMonths(13, setRoom13MonthsNY)
    saveMonths(14, setRoom14MonthsNY)
    saveMonths(15, setRoom15MonthsNY)


  }, [month])


  useEffect(() => {

    if (month <= 11) {
      const nextmonthF = month + 1
      setNextmonth(nextmonthF)
    } if (month === 12) {
      setNextmonth(1)
    }
  }, [month])

  useEffect(() => {

    if (month <= 11) {
      const nextmonthF = month + 1
      setNextmonth(nextmonthF)
    } if (month === 12) {
      setNextmonth(1)
    }
  }, [month])


  useEffect(() => {
    function getDaysInMonth(y, m) {
      return new Date(y, m, 0).getDate();
    }
    setMonthDays(getDaysInMonth(year, month))
  }, [month])



  useEffect(() => {
    function getDaysInMonth(y, m) {
      return new Date(y, m, 0).getDate();
    }

    const date = new Date();
    const yearF = date.getFullYear()
    const monthF = date.getMonth() + 1
    const todayF = date.getDate()
    setYear(yearF)
    setMonth(monthF)
    setToday(todayF)
    setTodaysMonth(monthF)
    setTodaysYear(yearF)
  }, [])
  useEffect(() => {

    function getDaysInMonth(y, m) {
      return new Date(y, m, 0).getDate();
    }

    const daysInCurrentMonthF = setDaysInCurrentMonth(getDaysInMonth(year, month));

  }, [month])




  useEffect(() => {
    if (location.pathname === "/dashboard/bookcalendar") {
      setWeekCalendar(true)
    }
  }, [])

  ////////////////// MONTH BUTTONS

  const monthForward = () => {

    setMonth(month + 1)

    if (month === 12) {
      setMonth(1)
      setYear(year + 1)
    }
  }
  const monthBackwards = () => {

    const newDay = new Date();

    setMonth(month - 1)
    if (month === 1) {
      setMonth(12)
      setYear(year - 1)
    }
  }


  useEffect(() => {
    const days = []

    for (var i = 1; i <= monthDays; i++) {
      days.push(i);
    }
    setDaysLoop(days)

  }, [monthDays])

  const [scroll, setScroll] = useState(1)
  const [changeWeek, setChangeWeek] = useState(false)


  useEffect(() => {
    if (today < 8) {
      return setScroll(1)
    } if (today < 15 && today >= 7) {
      return setScroll(1)
    } if (today < 22 && today >= 14) {
      return setScroll(2)
    } if (today < 29 && today >= 21) {
      return setScroll(2)
    } if (today > 29) {
      return setScroll(2)
    }
  }, [daysLoop])

  useEffect(() => {
    if (month !== todaysMonth) {
      return setScroll(1)
    }
  }, [daysLoop])

  const scrollRight = () => {
    if (scroll === 2) {
      setScroll(scroll)
    } else {
      setScroll(scroll + 1)
      setChangeWeek(true)

      setTimeout(() => {
        setChangeWeek(false)
      }, 500);
    }

  }

  const scrollLeft = () => {
    if (scroll === 1) {
      setScroll(scroll)
    } else {
      setScroll(scroll - 1)
      setChangeWeek(true)

      setTimeout(() => {
        setChangeWeek(false)
      }, 2000);
    }

  }

  const loaderPromise = new Promise((resolve, reject) => {
    setTimeout(() => { resolve([]); }, 2000);
  });

  loaderPromise.then(() => {
    setLoader(false);
  });



  return (
    <>

      {loginCheck != true ?

        <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>
          <p className='logoMicrositeLoading' >HOLDE <span className='logobackMicrosite'>.ar</span></p>

          <Login customer={customer} />
        </div>
        :

        <>
          <div className='dashboardCont'>
            <p className='sectionTittles'>| CALENDARIO</p>

            <NavbarPanel customer={customer} />


            {loader === true ? <div className='weekcalendarloader'>
              <LineWobble
                size={80}
                lineWeight={5}
                speed={1.75}
                color="#576F72" />
            </div> : <>
              <div className='BookListContWeek '>
                <div className='yearAndMonthCont'>
                  <p className='year'> {year} </p>

                  <div className='MonthButtonsCont'>  <button className='MonthButton' onClick={
                    monthBackwards}>〈</button> <p>{month === 1 ? "ENERO" : month === 2 ? "FEBRERO" : month === 3 ? "MARZO" : month === 4 ? "ABRIL" : month === 5 ? "MAYO" : month === 6 ? "JUNIO" : month === 7 ? "JULIO" : month === 8 ? "AGOSTO" : month === 9 ? "SEPTIEMBRE" : month === 10 ? "OCTUBRE" : month === 11 ? "NOVIEMBRE" : month === 12 ? "DICIEMBRE" : <></>}</p>  <button className='MonthButton' onClick={monthForward}>〉</button></div>

                  <div className='MonthButtonsCont'>  <button className='MonthButton' onClick={
                    scrollLeft}>〈</button> <p>{scroll === 1 ? "QUINCENA 1" : scroll === 2 ? "QUINCENA 2" : <></>}</p>  <button className='MonthButton' onClick={scrollRight}>〉</button></div>

                </div>


                <div className='calendarCont'>


                  <div className='weekDayCont'>
                    <p className='dayboxWeek'>ROOMS</p>
                    {rooms.map((e) =>

                      <>
                        <div key={e.id} className='completeMonthCont'>
                          <p className='weekRoomCont firstColumn'>{e.roomname} <br /> {e.type}  </p>
                          <button className='viewDetailButton' onClick={() => {
                            selectRoom(e.id)
                          }}> Mes completo </button>
                        </div>
                      </>

                    )}

                  </div>

                  <WeekCalendarList rooms={rooms} daysInCurrentMonth={daysInCurrentMonth} monthDays={monthDays} daysLoop={daysLoop} room1Months={room1Months} room2Months={room2Months} room3Months={room3Months} m1={m1} m2={m2} m3={m3} year={year} month={month} today={today} todaysMonth={todaysMonth} todaysYear={todaysYear} room1MonthsNY={room1MonthsNY} room2MonthsNY={room2MonthsNY} room3MonthsNY={room3MonthsNY} bookings={bookings} scroll={scroll} changeWeek={changeWeek} room4Months={room4Months} room4MonthsNY={room4MonthsNY} m4={m4}
                    room5Months={room5Months} room5MonthsNY={room5MonthsNY} m5={m5}
                    room6Months={room6Months} room6MonthsNY={room6MonthsNY} m6={m6}
                    room7Months={room7Months} room7MonthsNY={room7MonthsNY} m7={m7}
                    room8Months={room8Months} room8MonthsNY={room8MonthsNY} m8={m8}
                    room9Months={room9Months} room9MonthsNY={room9MonthsNY} m9={m9}
                    room10Months={room10Months} room10MonthsNY={room10MonthsNY} m10={m10}
                    room11Months={room11Months} room11MonthsNY={room11MonthsNY} m11={m11}
                    room12Months={room12Months} room12MonthsNY={room12MonthsNY} m12={m12}
                    room13Months={room13Months} room13MonthsNY={room13MonthsNY} m13={m13}
                    room14Months={room14Months} room14MonthsNY={room14MonthsNY} m14={m14}
                    room15Months={room15Months} room15MonthsNY={room15MonthsNY} m15={m15} />
                </div>
                
                {openDetail === true ?
                  <>
                    <MicroCalendar rooms={rooms} daysInCurrentMonth={daysInCurrentMonth} monthDays={monthDays} daysLoop={daysLoop} room1Months={room1Months} room2Months={room2Months} room3Months={room3Months} m1={m1} m2={m2} m3={m3} year={year} month={month} today={today} todaysMonth={todaysMonth} todaysYear={todaysYear} room1MonthsNY={room1MonthsNY} room2MonthsNY={room2MonthsNY} room3MonthsNY={room3MonthsNY} bookings={bookings} scroll={scroll} changeWeek={changeWeek} roomSelected={roomSelected} roomSelectedNY={roomSelectedNY} openDetail={openDetail} firstDay={firstDay} room4Months={room4Months} room4MonthsNY={room4MonthsNY} m4={m4}
                      room5Months={room5Months} room5MonthsNY={room5MonthsNY} m5={m5}
                      room6Months={room6Months} room6MonthsNY={room6MonthsNY} m6={m6}
                      room7Months={room7Months} room7MonthsNY={room7MonthsNY} m7={m7}
                      room8Months={room8Months} room8MonthsNY={room8MonthsNY} m8={m8}
                      room9Months={room9Months} room9MonthsNY={room9MonthsNY} m9={m9}
                      room10Months={room10Months} room10MonthsNY={room10MonthsNY} m10={m10}
                      room11Months={room11Months} room11MonthsNY={room11MonthsNY} m11={m11}
                      room12Months={room12Months} room12MonthsNY={room12MonthsNY} m12={m12}
                      room13Months={room13Months} room13MonthsNY={room13MonthsNY} m13={m13}
                      room14Months={room14Months} room14MonthsNY={room14MonthsNY} m14={m14}
                      room15Months={room15Months} room15MonthsNY={room15MonthsNY} m15={m15} />
                  </>
                  :
                  <></>
                }

              </div>

            </>}

          </div>
        </>

      }


    </>
  )
}

import React, { useState, useEffect } from 'react';
import { setDoc, doc, collection, getFirestore, getDocs } from 'firebase/firestore';



export default function NewCode() {
    const db = getFirestore()

    const generate = () => {

        setDoc(doc(db, `holdear`, `newaccountCodes`), {
            code : parseInt(Math.random() * 1000000)
            
        })

    }

    const [data, setData] = useState([])
    const [codeList, setCodeList] = useState([])

    useEffect(() => {
        const QueryCollection = collection(db, `holdear`)

        getDocs(QueryCollection)
            .then(resp => setData(resp.docs.map(book => ({ id: book.id, ...book.data() }))))
            .catch((err) => console.log(err))

    }, [])

    useEffect(() => {
        let codes = data.find(e => e.id == "newaccountCodes")

        setCodeList(codes)
    }, [data])


  return (
    <>
    <div className='column center'>
    <button className='nextButton' onClick={generate}>new</button>
    {codeList != undefined ?     <p className='nextButton'> {codeList.code} </p> : <></> }
    </div>
    </>
  )
}

import React, { useState, useEffect } from 'react'
import { getFirestore, collection, getDocs} from 'firebase/firestore';
import { LineWobble } from '@uiball/loaders'
import { Link } from 'react-router-dom';
import NavbarHomepage from './NavbarHomepage';


export default function Plans() {

  const [loader, setLoader] = useState(true)
  const [images, setImages] = useState([])
  const db = getFirestore()

  useEffect(() => {
    setLoader(true)

  setTimeout(() => {
    setLoader(false)
  }, 1500);
  }, [])

  useEffect(() => {

    const QueryCollection = collection(db, `holdear/homepage/plans`)

    getDocs(QueryCollection)
      .then(resp => setImages(resp.docs.map(site => ({ id: site.id, ...site.data() }))))
      .catch((err) => console.log(err))
  
  }, [])



  return (
    <>

      {loader === true ?
        <section>
          <div className='headerHome headergradient'>
            <NavbarHomepage />
            <LineWobble size={80} lineWeight={5} speed={1.75} color="white" />
          </div>

    
        </section>


        :

        <>

          <div className='headerHome headergradient'>
            <NavbarHomepage />
            <p className='sectionTittle'>Nuestros planes</p>
            <p className='minitextnavbarHome '> Cuenta gratis y suscripciones <b>a tu alcance.</b> </p>
          </div>


          <section className='direction plansDiv'>
            <div className=''>
              <img className='appImg' src={images[0].plans1} alt="$????" />
            </div>

            <div className=''>
              <p className="tittlePlans"> $???? </p>
              <p className='textParagraph highlight'> El precio lo elegís vos*</p>
              <p className='textParagraph'> Suscripción mensual BASIC.</p>
              <p  className='textParagraph minitext5'>*Desde $499.</p>
            </div>
     

           <div className=''>
              <p className="tittlePlans"> PRÓXIMAMENTE </p>
              <p className='textParagraph '> Suscripción mensual PRO. <br />
              Estadísticas, email, agenda y más.
              </p>
            </div> 
          </section>

          <section className='direction plansDiv'>

<p className="tittlePlans"> Plan BASIC </p>
            <div className='plansDetailCont'>
              <img className='plansDetailimg' src={images[0].plans2} alt="" />
              <div className='column margin-top'>
                <p className='plansDetaitextParagraph'> <img className='microImg' src="https://img.icons8.com/ios-glyphs/90/FFFFFF/change-theme.png" alt="" />  Integración Mercadopago. </p>
                <p className='plansDetaitextParagraph'><img className='microImg' src="https://img.icons8.com/fluency-systems-filled/96/FFFFFF/dashboard-layout.png" alt="" /> Administrador avanzado. </p>
                <p className='plansDetaitextParagraph'><img className='microImg' src="https://img.icons8.com/external-vitaliy-gorbachev-fill-vitaly-gorbachev/60/FFFFFF/external-free-sales-vitaliy-gorbachev-fill-vitaly-gorbachev.png" alt="" /> El precio lo ponés vos. </p>
              </div>
            </div>
            
            <div className='plansDetailCont'>
              <img className='plansDetailimg' src={images[0].plans3} alt="" />
              <div className='column margin-top'>
                <p className='plansDetaitextParagraph'><img className='microImg' src="https://img.icons8.com/ios-glyphs/90/FFFFFF/technical-support-wearing-glasses.png" alt="" /> Asistencia personalizada.  </p>
                <p className='plansDetaitextParagraph'><img className='microImg' src="https://img.icons8.com/ios-glyphs/90/FFFFFF/upload-2.png" alt="" /> + Cloud storage. </p>
                {/* <p className='plansDetaitextParagraph'><img className='microImg' src="https://img.icons8.com/ios-filled/100/FFFFFF/settings.png" alt="" /> Hosting.  </p> */}

                <p className='plansDetaitextParagraph'><img className='microImg' src="https://img.icons8.com/material-rounded/96/FFFFFF/paycheque.png" alt="" /> Agenda de clientes. </p>
        
                {/* <p className='plansDetaitextParagraph'><img className='microImg' src="https://img.icons8.com/external-yogi-aprelliyanto-glyph-yogi-aprelliyanto/64/FFFFFF/external-email-digital-marketing-yogi-aprelliyanto-glyph-yogi-aprelliyanto.png" alt="" /> Cuenta de email (PRO). </p> */}
              </div>
            </div>

          </section>

          <div className='appDiv greenBack column'>

            <p className='headerTittle'>¡Quiero ser Holder!</p>

            <Link className='linkHome' to="/contactus">
              <p className='headerDetailText appButton circleButton'>+</p>
            </Link>


          </div>

        </>
      }

    </>
  )
}

import React, { useState, useEffect } from 'react';
import ArrivalsItem from './ArrivalsItem';
import './Dashboard.css';
import { Link } from 'react-router-dom';
import { LineWobble } from '@uiball/loaders'

export default function Departures({ bookings, today, todaysMonth, todaysYear, day, month, year, customer }) {

  const [booksFromThisDay, setBooksFromThisDay] = useState([])
  const [loader, setLoader] = useState(true)


  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 1000);
  }, [])


  useEffect(() => {
    let departuresToday = bookings.filter((e) => e.fullto.day == today && e.fullto.month == todaysMonth && e.fullto.year == todaysYear)

    setBooksFromThisDay(departuresToday)
  }, [bookings])



  return (
    <div className='DashboardSectionCont'>
      <div className='row'>
        <p className='DashboardTitles'>Partidas de hoy</p>
        <Link className='linkArrivals' to={`/${customer}/dashboard/bookcalendar`}>
          <p className='minitextLink' > Detalles</p>
        </Link>
      </div>


      {loader === true ? <div className='dashboardloader'>
        <LineWobble
          size={80}
          lineWeight={5}
          speed={1.75}
          color="#576F72" />
      </div> :

        <div className='ArrivalsItemCont'>

          {booksFromThisDay.length === 0 ?

            <p className='nonewdata'> Nadie se retirará hoy </p> :

            <>       {booksFromThisDay.map((book) =>
              <ArrivalsItem key={book.id} {...book} />
            )}</>}
        </div>}
    </div>
  )
}
import React, { useState, useEffect } from 'react'
import NavbarPanel from '../NavbarPanel'
import { useRoomsContext } from '../../context/RoomsContext'
import { doc, setDoc, getFirestore } from 'firebase/firestore';
import CheckinRoomCard from './CheckinRoomCard';
import CheckInput from './CheckInput';
import { LineWobble } from '@uiball/loaders'
import Backwardsbutton from '../../buttons/Backwardsbutton';
import { useParams } from 'react-router-dom';
import { useLoginContext } from '../../context/LoginContext'
import Login from '../login/Login';
import GetNewPlan from '../plans/GetNewPlan';
export default function Checkin() {
    const { customer } = useParams()

    const { rooms, newguest1, newguest2, newguest3, newguest4, newguest5, newguest6, newguest7, newguest8, newguest9, newguest10, rerender, contextRooms } = useRoomsContext()

    const [roomselected, setRoomselected] = useState()
    const [loader, setLoader] = useState(false)
    const [showAdd, setShowAdd] = useState(1)
    const [guestsinroom, setGuestsinroom] = useState([])
    const [count, setCount] = useState({})
    const [step, setStep] = useState(1)
    const { loginCheck, planCheck } = useLoginContext()

    useEffect(() => {
        contextRooms.push(customer)
    }, [])


    const db = getFirestore()

    const handleSubmit = (event) => {
        event.preventDefault();
    }

    function roomSelection(e) {
        setRoomselected(e)

        setLoader(true)
        setStep(2)
        setTimeout(() => {
            setLoader(false)

        }, 1500);
    }


    function backwardStep() {
        setStep(1)
    }

    useEffect(() => {

        if (roomselected != undefined) {
            return setGuestsinroom([rooms.find(room => room.id === roomselected).guest1[0].id, rooms.find(room => room.id === roomselected).guest2[0].id, rooms.find(room => room.id === roomselected).guest3[0].id, rooms.find(room => room.id === roomselected).guest4[0].id, rooms.find(room => room.id === roomselected).guest5[0].id, rooms.find(room => room.id === roomselected).guest6[0].id, rooms.find(room => room.id === roomselected).guest7[0].id, rooms.find(room => room.id === roomselected).guest8[0].id, rooms.find(room => room.id === roomselected).guest9[0].id, rooms.find(room => room.id === roomselected).guest10[0].id])
        }

    }, [roomselected, newguest1, newguest2, newguest3, newguest4, newguest5, newguest6, newguest7, newguest8, newguest9, newguest10])


    useEffect(() => {
        let counts = {}
        guestsinroom.forEach((e) => {
            counts[e] = (counts[e] || 0) + 1
        })

        setCount(counts)

    }, [guestsinroom])

    useEffect(() => {
        setShowAdd(11 - count[0])
    }, [count])

    const showall = () => {
        if (showAdd != 10) {
            setShowAdd(10)
        } else {
            setShowAdd(11 - count[0])
        }

    }

    const editRoom = () => {
        rerender()
        setDoc(doc(db, `clients`, `${customer}`, "rooms", roomselected), { //id is for label each room formaly, like: room1, room2, room25.
            roomname: rooms.find(room => room.id === roomselected).roomname,
            description: rooms.find(room => room.id === roomselected).description,
            beds: rooms.find(room => room.id === roomselected).beds,
            type: rooms.find(room => room.id === roomselected).type,
            enabled: rooms.find(room => room.id === roomselected).enabled,
            price: rooms.find(room => room.id === roomselected).price,
            size: rooms.find(room => room.id === roomselected).size,
            img: rooms.find(room => room.id === roomselected).img,
            guest1: newguest1.length === 0 ? rooms.find(room => room.id === roomselected).guest1 : newguest1,
            guest2: newguest2.length === 0 ? rooms.find(room => room.id === roomselected).guest2 : newguest2,
            guest3: newguest3.length === 0 ? rooms.find(room => room.id === roomselected).guest3 : newguest3,
            guest4: newguest4.length === 0 ? rooms.find(room => room.id === roomselected).guest4 : newguest4,
            guest5: newguest5.length === 0 ? rooms.find(room => room.id === roomselected).guest5 : newguest5,
            guest6: newguest6.length === 0 ? rooms.find(room => room.id === roomselected).guest6 : newguest6,
            guest7: newguest7.length === 0 ? rooms.find(room => room.id === roomselected).guest7 : newguest7,
            guest8: newguest8.length === 0 ? rooms.find(room => room.id === roomselected).guest8 : newguest8,
            guest9: newguest9.length === 0 ? rooms.find(room => room.id === roomselected).guest9 : newguest9,
            guest10: newguest10.length === 0 ? rooms.find(room => room.id === roomselected).guest10 : newguest10,
        })

    }

    const addanotherguest = () => {
        setShowAdd(showAdd + 1)
    }


    const [loginAnimation, setLoginAnimation] = useState(false)

    useEffect(() => {
        setLoginAnimation(true)

        setTimeout(() => {
            setLoginAnimation(false)


        }, 2000);
    }, [])

    const [pageLoad, setPageLoad] = useState(true)

    const loaderPromise = new Promise((resolve, reject) => {
        setTimeout(() => { resolve([]); }, 2000);
    });

    loaderPromise.then(() => {
        setPageLoad(false);
    });



    return (
        <>
            {loginCheck != true ?

                <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>
                    <p className='logoMicrositeLoading' >HOLDE <span className='logobackMicrosite'>.ar</span></p>

                    <Login customer={customer} />
                </div>
                :
                <>
                    <div className='dashboardCont'>
                        <NavbarPanel customer={customer} />
                        <p className='sectionTittles'>| CHECK-IN/OUT ROOM</p>



                        {loader === true ? <div className='weekcalendarloader'>
                            <LineWobble
                                size={80}
                                lineWeight={5}
                                speed={1.75}
                                color="#576F72" />
                        </div> :

                            <>
                                {planCheck.basic === false ?
                                    <GetNewPlan customer={customer} video="https://www.youtube.com/embed/2y1Cg_ahF7Y" />

                                    :
                                    <>
                                        {step != 1 ? <></> :
                                            <div className='CheckInRoomsCont'>
                                                {rooms.map(room => <CheckinRoomCard key={room.id} {...room} roomSelection={roomSelection} rerender={rerender} rooms={rooms} customer={customer} />
                                                )}
                                            </div>}


                                        {step != 2 ? <></> : roomselected === undefined ? <></> : loader === true ? <div className='newguestscont'>

                                            <LineWobble
                                                className="marginauto"
                                                size={80}
                                                lineWeight={5}
                                                speed={1.75}
                                                color="#7D9D9C"
                                            />
                                        </div> :
                                            <>

                                                <div className="backwardsButtonNG">
                                                    <Backwardsbutton onclick={backwardStep} text="⬸ Regresar" />
                                                </div>

                                                <div className='newguestscont'>


                                                    {showAdd >= 1 ? rooms.find(room => room.id === roomselected).guest1[0].name.length != 0 ? <></> : <CheckInput number={1} roomselected={roomselected} newguest1={newguest1} /> : <></>}

                                                    {showAdd >= 2 ? rooms.find(room => room.id === roomselected).guest2[0].name.length != 0 ? <></> : <CheckInput number={2} roomselected={roomselected} newguest2={newguest2} /> : <></>}

                                                    {showAdd >= 3 ? rooms.find(room => room.id === roomselected).guest3[0].name.length != 0 ? <></> : <CheckInput number={3} roomselected={roomselected} newguest3={newguest3} /> : <></>}

                                                    {showAdd >= 4 ? rooms.find(room => room.id === roomselected).guest4[0].name.length != 0 ? <></> : <CheckInput number={4} roomselected={roomselected} newguest4={newguest4} /> : <></>}

                                                    {showAdd >= 5 ? rooms.find(room => room.id === roomselected).guest5[0].name.length != 0 ? <></> : <CheckInput number={5} roomselected={roomselected} newguest5={newguest5} /> : <></>}

                                                    {showAdd >= 6 ? rooms.find(room => room.id === roomselected).guest6[0].name.length != 0 ? <></> : <CheckInput number={6} roomselected={roomselected} newguest6={newguest6} /> : <></>}

                                                    {showAdd >= 7 ? rooms.find(room => room.id === roomselected).guest7[0].name.length != 0 ? <></> : <CheckInput number={7} roomselected={roomselected} newguest7={newguest7} /> : <></>}

                                                    {showAdd >= 8 ? rooms.find(room => room.id === roomselected).guest8[0].name.length != 0 ? <></> : <CheckInput number={8} roomselected={roomselected} newguest8={newguest8} /> : <></>}

                                                    {showAdd >= 9 ? rooms.find(room => room.id === roomselected).guest9[0].name.length != 0 ? <></> : <CheckInput number={9} roomselected={roomselected} newguest9={newguest9} /> : <></>}

                                                    {showAdd >= 10 ? rooms.find(room => room.id === roomselected).guest10[0].name.length != 0 ? <></> : <CheckInput number={10} roomselected={roomselected} newguest10={newguest10} />
                                                        : <></>}

                                                    <div className='buttonsCont'>
                                                        <button className='plusbutton' onClick={addanotherguest}> + </button>

                                                        <button className='confbutton' onClick={showall}>Ver más</button>
                                                        {loader === true ? <></> : <button className='confbutton opacity100' onClick={editRoom}> Agregar huésped </button>}
                                                    </div>
                                                </div>
                                            </>}
                                    </>
                                }
                            </>

                        }
                    </div>
                </>
            }

        </>
    )
}

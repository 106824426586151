import React, { useState, useEffect } from 'react'
import { getFirestore } from 'firebase/firestore';
import BookOrderDashboardList from './BookOrderDashboardList'
import { useBookingContext } from '../../context/BookingContext'
import NavbarPanel from '../NavbarPanel';
import { useParams } from 'react-router-dom';
import { useLoginContext } from '../../context/LoginContext'
import Login from '../login/Login';
export default function BookOrderDashboardListCont() {
  const db = getFirestore()
  const { customer } = useParams()
  const { contextBookings, bookings, rerender } = useBookingContext()
  const { loginCheck } = useLoginContext()

  useEffect(() => {
    contextBookings.push(customer)
  }, [])

  const [search, setSearch] = useState("")
  const [active, setActive] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault();
  }

  useEffect(() => {
    setActive(true)
  }, [])


  const [loginAnimation, setLoginAnimation] = useState(false)

  useEffect(() => {
    setLoginAnimation(true)

    setTimeout(() => {
      setLoginAnimation(false)


    }, 2000);
  }, [])


  return (
    <>
      {loginCheck != true ?

        <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>
          <p className='logoMicrositeLoading' >HOLDE <span className='logobackMicrosite'>.ar</span></p>

          <Login customer={customer} />
        </div>
        :
        <>
          <div className='dashboardCont'>
            <NavbarPanel bookings={bookings} active={active} customer={customer} />
            <div className='bookOrderDashboardlistCol margintop'>
              <p className='sectionTittles'>| BOOKINGS ROOM</p>

              <div className='searchOrderCont'>
                <form className='addRoomFormCont' action="" onSubmit={handleSubmit}>
                  <input className='searchOrderInput' type="search" onChange={(e) => setSearch(e.target.value)} placeholder='Buscar por nombre...' />
                </form>
              </div>
              <div>
              </div>

              <BookOrderDashboardList bookings={bookings} search={search} rerender={rerender} customer={customer} />
            </div>
          </div>
        </>
      }
    </>
  )
}

import React, { useState, useEffect } from 'react'
import { addDoc, collection, getFirestore, getDocs} from 'firebase/firestore';
import { LineWobble } from '@uiball/loaders'
import NavbarHomepage from './NavbarHomepage';

function ContactUs({ }) {

    const db = getFirestore()

    const [message, setMessage] = useState()
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()
    const [sent, setSent] = useState(false)
    const [loader, setLoader] = useState(true)
    const [images, setImages] = useState([])

    useEffect(() => {
        setLoader(true)

        setTimeout(() => {
            setLoader(false)
        }, 1500);
    }, [images])

    const handleSubmit = (event) => {
        event.preventDefault();
    }

    useEffect(() => {

        const QueryCollection = collection(db, `holdear/homepage/app`)

        getDocs(QueryCollection)
            .then(resp => setImages(resp.docs.map(site => ({ id: site.id, ...site.data() }))))
            .catch((err) => console.log(err))


    }, [])


    //ESTA FUNCIÓN ENVÍA EL MENSAJE A FIREBASE, EN "CORREOS"
    //LOS MENSAJES SE VISUALIZAN EN EL PANEL DE ADMINISTRACIÓN
    const sendmessage = () => {
        const date = new Date();

        let mail = {}


        mail.id = parseInt(((Math.random() * 10000000) + (Math.random() * 10000000)) / (Math.random(1, 50)));
        mail.name = name;
        mail.text = message;
        mail.fulldate = { day: date.getDate(), month: (date.getMonth() + 1), year: date.getFullYear() };
        mail.hour = date.getHours() + ":" + date.getMinutes();
        mail.email = email;
        mail.timestamp = Date.now();
        mail.date = Date().substring(0, 24);
        mail.phone = phone;
        mail.status = false;

        const queryCollection = collection(db, `holdear/homepage/messages`)
        addDoc(queryCollection, mail)
            .then(res => console.log(res))
            .catch(err => console.log(err))

    }

    return (

        <>
            {loader === true ?
                <section>
                    <div className='headerHome headergradient'>
                        <NavbarHomepage />
                        <LineWobble size={80} lineWeight={5} speed={1.75} color="white" />
                    </div>

                </section>
                :
                <>
                    <div className='headerHome headergradient'>
                        <NavbarHomepage />
                        <p className='sectionTittle'>Contactanos</p>

                        <div className='row'>
                            <a target="_blank" href={`https://wa.me/5491132683423`}><img className='socialMediaIcon' src="https://img.icons8.com/ios-glyphs/120/FFFFFF/whatsapp.png" alt="" /></a>

                            <a target="_blank" href="mailto: contacto@holde.ar"><img className='socialMediaIcon' src="https://img.icons8.com/material-rounded/96/FFFFFF/new-post.png" alt="" /></a>
                        </div>
                    </div>
                </>
            }
        </>

    );
}

export default ContactUs;

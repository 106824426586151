import React from 'react';
import { Link } from 'react-router-dom';

export default function NavbarHomepage() {
  return (
<>

<nav className='navbarHomepageCont'>
          <Link className='linkHome' to="/">
            <p className='minitextnavbarHome' > Home </p>
          </Link>

          <Link className='linkHome' to="/demo">
            <p className='minitextnavbarHome' > Demo </p>
          </Link>

          {/* <Link className='linkHome' to="/clients">
            <p className='minitextnavbarHome' > Clientes </p>
          </Link> */}

          <Link className='linkHome' to="/plans">
            <p className='minitextnavbarHome' > Planes </p>
          </Link>

          
          {/* <Link className='linkHome' to="/aboutus">
            <p className='minitextnavbarHome' > Nosotros </p>
          </Link> */}

          <Link className='linkHome' to="/contactus">
            <p className='minitextnavbarHome' > Contactanos </p>
          </Link>

          
          <div className='loginNavBut row center none-mobile'>
          <Link className='linkHome marginless' to="/welcome">
            <p className='loginNavButText ' > Registrate </p>
          </Link>

          
          <Link className='linkHome marginless' to="/login">
            <p className='loginNavButText greenbackCle' > Ingresar </p>
          </Link>
          </div>


    </nav>

              


</>
  )
}
